/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Box,
} from "@mui/material";
/*Standard Library End*/

/*Local Library Start*/
import { fetchAppointments } from "../../features/appointments/appointmentsSlice";
import { useAppDispatch } from "../../app/store";
import SearchBar from "../../components/SearchBar";
import theme from "../../theme";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const columns = [
  { id: "mobile", label: "MOBILE", minWidth: 170 },
  { id: "name", label: "NAME", minWidth: 170 },
  {
    id: "created_on",
    label: "CREATION DATE",
    minWidth: 170,
  },
  { id: "status_text", label: "STATUS", minWidth: 170 },
];
/*Constant Variables End*/

const Appointments = () => {
  /*Standard Variables Start*/
  const {
    currentUser,
    appointments: { appointments },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (appointments) {
      setRows(appointments);
    }
  }, [appointments]);

  useEffect(() => {
    if (currentUser?.currentUser?.access?.appointments) {
      dispatch(fetchAppointments({}));
    }
  }, [currentUser?.currentUser?.access?.appointments]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getStatusColor = (sId, sValue) => {
    let sColor = "";
    if ("Scheduled" === sValue) {
      sColor = "red";
    } else {
      sColor = "#2a982a";
    }
    return (
      <Typography
        sx={{
          color: sColor,
          fontSize: "18px",
        }}
      >
        {sValue}
      </Typography>
    );
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = appointments.filter((row) => {
      return (
        row.mobile.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.status_text.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.created_on.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };
  /*Functions End*/

  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <Button
        sx={{ marginBottom: "20px", ...stepperButtonStyles }}
        onClick={() => {
          navigate("/");
        }}
        startIcon={<ArrowBackIcon />}
      >
        BACK
      </Button>
      <SearchBar
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        setSearched={setSearched}
      />
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const sSearch = `?id=${row.id}`;
                    return (
                      <TableRow
                        onClick={() => {
                          navigate({
                            pathname: "/appointment-detail",
                            search: sSearch,
                          });
                        }}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.id === "status_text") {
                            return (
                              <TableCell
                                style={{
                                  fontSize: "18px",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {getStatusColor(column.id, value)}
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell
                              style={{
                                fontSize: "18px",
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={appointments ? appointments.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default Appointments;
