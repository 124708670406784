/*Standard Library Start*/
import { Typography } from "@mui/material";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import suiting from "../../constants/suiting.json";
/*Local Library End*/

const OrderProductDetail = () => {
  /*Functions Start*/
  const getShirtDetail = (product, oOrder) => {
    if (oOrder?.order_version > 1) {
      return (
        <>
          <Typography
            sx={{
              xs: "16px",
              md: "18px",
            }}
            textAlign="left"
            fontWeight={theme.fontWeight.regular}
          >
            {product.product_detail.sleeve}
          </Typography>
          <Typography
            sx={{
              xs: "16px",
              md: "18px",
            }}
            textAlign="left"
            fontWeight={theme.fontWeight.regular}
          >
            {product.product_detail.collor}
          </Typography>
          <Typography
            sx={{
              xs: "16px",
              md: "18px",
            }}
            textAlign="left"
            fontWeight={theme.fontWeight.regular}
          >
            {product.product_detail.pocket}
          </Typography>
        </>
      );
    }

    let sCollor = "";

    product.collors.some((Collor) => {
      if (Collor.product_id === product.product_detail.collor) {
        sCollor = Collor.product_name;
        return true;
      }
      return false;
    });

    let sPocket = "";
    product.pockets.some((Pocket) => {
      if (Pocket.product_id === product.product_detail.pocket) {
        sPocket = Pocket.product_name;
        return true;
      }

      return false;
    });

    let sSleeve = "";
    product.sleeves.some((Sleeve) => {
      if (Sleeve.product_id === product.product_detail.sleeve) {
        sSleeve = Sleeve.product_name;
        return true;
      }

      return false;
    });

    return (
      <>
        <Typography
          sx={{
            xs: "16px",
            md: "18px",
          }}
          fontWeight={theme.fontWeight.regular}
          textAlign="left"
        >
          {sCollor}
        </Typography>
        <Typography
          sx={{
            xs: "16px",
            md: "18px",
          }}
          fontWeight={theme.fontWeight.regular}
          textAlign="left"
        >
          {sPocket}
        </Typography>
        <Typography
          sx={{
            xs: "16px",
            md: "18px",
          }}
          fontWeight={theme.fontWeight.regular}
          textAlign="left"
        >
          {sSleeve}
        </Typography>
      </>
    );
  };

  const getPantDetail = (product, bOverritedCSS = false) => {
    let oFoundSuiting = null;

    suiting.some((oSuiting) => {
      if (oSuiting.category === product.product_detail.suiting_type) {
        oFoundSuiting = oSuiting;
        return true;
      }
      return false;
    });

    if (bOverritedCSS) {
      return <>{oFoundSuiting && oFoundSuiting.label}</>;
    }
    return (
      <>
        {oFoundSuiting && (
          <Typography
            sx={{
              xs: "16px",
              md: "18px",
            }}
            textAlign="left"
            fontWeight={theme.fontWeight.regular}
          >
            {oFoundSuiting.label}
          </Typography>
        )}
      </>
    );
  };

  /*Functions End*/

  return {
    getShirtDetail,
    getPantDetail,
  };
};

export default OrderProductDetail;
