/* eslint-disable array-callback-return */
/* eslint-disable no-empty-pattern */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";

const initialState = {
  users: null,
  loading: false,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      });
  },
});

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async ({}, thunkAPI) => {
    const ordersRef = collection(db, "orders");
    const q = query(ordersRef);
    const querySnapshot = await getDocs(q);
    let oOrders = [];
    querySnapshot.forEach((doc) => {
      oOrders.push(doc.data());
    });

    const userRef = collection(db, "users");
    const userQuery = query(userRef);
    const userQuerySnapshot = await getDocs(userQuery);

    let userOrders = [];
    userQuerySnapshot.forEach((doc) => {
      let oData = doc.data();
      oData.mobile = oData.mobile.substring(3);
      oData.created_on = moment(oData.created_on).format("LLL");
      oData.name = "";
      if (oData?.address?.first_name) {
        oData.name = oData.address.first_name;
      }
      if (oData?.address?.last_name) {
        oData.name = oData.name + " " + oData.address.last_name;
      }

      let aUerSpecificOrder = oOrders.filter((oOrderData) => {
        if (oOrderData.mobile.substring(3) === oData.mobile) {
          return oOrderData;
        }
      });
      oData.orderCount = aUerSpecificOrder.length;
      oData.orderValue = 0;

      aUerSpecificOrder.map((oOrder) => {
        oData.orderValue = oData.orderValue + parseFloat(oOrder.total_amount);
      });

      userOrders.push(oData);
    });

    return userOrders.sort(function (a, b) {
      return new Date(b.created_on) - new Date(a.created_on);
    });
  }
);

export default usersSlice.reducer;
export const { setOrderLoading } = usersSlice.actions;
