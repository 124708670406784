/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Button,
  FormControl,
  IconButton,
  TextField as MUITextField,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ArrowBack as ArrowBackIcon,
  Paid as PaidIcon,
  CloudUpload as CloudUploadIcon,
  EditCalendar as EditCalendarIcon,
} from "@mui/icons-material";
import { doc, updateDoc, writeBatch } from "firebase/firestore";
import { db } from "../../firebase";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import { useAppDispatch } from "../../app/store";
import {
  UserMeasurement,
  ConfirmationDialog,
  OrderDeliveryDate,
  OrderPrint,
  Invoice,
  OrderPayment,
  OrderCancel,
  OrderStatus,
} from "../../components";
import { fetchOrder } from "../../features/order/orderSlice";
import { fetchOrders } from "../../features/orders/ordersSlice";
import { setOrderLoading } from "../../features/order/orderSlice";
import { fetchUser } from "../../features/user/userSlice";
import { fetchAllProducts } from "../../features/products/productsSlice";
/*Local Library End*/

/*Constant Variables Start*/
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

/*Constant Variables End*/

const OrderStitchDetail = () => {
  /*Standard Variables Start*/
  const [searchParams] = useSearchParams();
  const {
    user: { user },
    currentUser,
    order: { order },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    MeasurementControl,
    setMeasurementValues,
    setShowMeasurementDialog,
    setMeasurementEditMode,
  } = UserMeasurement();
  const {
    ConfirmationDialogControl,
    setShowConfirmationDialog,
    setConfirmationDialogMessage,
    setAcceptButtonText,
    setRejectButtonText,
  } = ConfirmationDialog();
  const [confirmationType, setConfirmationType] = useState("");
  // DI - Delete Invoice
  // DC - Delivery Date Change
  // UP - Update Payment
  // SOS- Save Order Status
  // SOM - Save Order Meaurments
  const { onPrintButtonPressed } = OrderPrint();
  const { onUploadInvoice, onDeleteInvoice, onDownloadOrderInvoice } =
    Invoice();
  const {
    UpdatePaymentControl,
    setShowUpdatePaymentDialog,
    onPaymentNotReceivedStatusUpdate,
    setShowPaymentHistoryDialog,
    PaymentHistoryControl,
  } = OrderPayment();

  const { OrderCancelDialog, setShowOrderCancelDialog } = OrderCancel();
  const { OrderStatusControl } = OrderStatus();

  /*Standard Variables End*/

  /*Local Variables Start*/
  const [remainingAmount, setRemainingAmount] = useState("0");
  const [activeStep, setActiveStep] = useState(0);

  const [selectedOrder, setSelectedOrder] = useState("");

  const [selectedOrderProduct, setSelectedOrderProduct] = useState("");
  const [openEditProductQuantityDialog, setOpenEditProductQuantityDialog] =
    useState(false);
  const [productQuantity, setProductQuantity] = useState("");
  const [productQuantityError, setProductQuantityError] = useState("");
  const [showDeliveryDateChangeDialog, setShowDeliveryDateChangeDialog] =
    useState(false);
  const [newDeliveryDate, setNewDeliveryDate] = useState(false);
  const [alterationButtonVisible, setAlterationButtonVisible] = useState(false);
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (currentUser?.currentUser?.access?.orders) {
      dispatch(fetchOrders({}));
    }
  }, [currentUser]);

  useEffect(() => {
    if (order) {
      const oOrder1 = cloneDeep(order);

      if (!oOrder1.amount_paid) {
        oOrder1.amount_paid = "0";
      }
      if (parseFloat(oOrder1?.amount_paid) < oOrder1.total_amount) {
        let sRemainingAmount =
          parseFloat(oOrder1.total_amount) - parseFloat(oOrder1.amount_paid);
        setRemainingAmount(sRemainingAmount.toFixed(2));
      } else {
        setRemainingAmount(0);
      }

      let bError = false;
      oOrder1.products.some((oProduct) => {
        if (!oProduct?.measurements) {
          bError = true;
          return true;
        }
        return false;
      });

      setAlterationButtonVisible(!bError);

      setSelectedOrder(oOrder1);
    }
  }, [order]);

  useEffect(() => {
    if (currentUser && selectedOrder?.mobile) {
      dispatch(
        fetchUser({
          mobile: selectedOrder.mobile,
        })
      );
    }
  }, [currentUser, selectedOrder]);

  useEffect(() => {
    const id = searchParams.get("id");
    if (currentUser?.currentUser?.access?.orders && id) {
      dispatch(
        fetchOrder({
          orderId: id,
        })
      );
    }
  }, [currentUser?.currentUser?.access?.orders, searchParams.get("id")]);

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchAllProducts());
    }
  }, [currentUser]);

  useEffect(() => {
    if (selectedOrder) {
      const aStatus = selectedOrder.order_status;

      const aFilters = aStatus.filter((oStatus) => oStatus.created_on !== "");
      aFilters.sort((a, b) => new Date(a.created_on) - new Date(b.created_on));

      const sStatus = aFilters[aFilters.length - 1].status;

      switch (sStatus) {
        case "Order Placed":
          setActiveStep(0);
          break;
        case "Order Shipped":
          setActiveStep(1);
          break;
        case "Order Delivered":
          setActiveStep(2);
          break;
        case "Order Cancelled":
          setActiveStep(3);
          break;

        default:
          break;
      }
    }
  }, [selectedOrder]);
  /*OnLoad Functions End*/

  const closeEditProductQuantityDialog = () => {
    setSelectedOrderProduct("");
    setProductQuantity("");
    setProductQuantityError("");
    setOpenEditProductQuantityDialog(false);
  };

  const updateProduct = async () => {
    if (currentUser.currentUser) {
      const batch = writeBatch(db);

      dispatch(setOrderLoading(true));
      const docRef = doc(db, "orders", selectedOrder.order_id);
      const updatedProducts = selectedOrder.products.map((oProduct, iIndex) => {
        if (iIndex === selectedOrderProduct.product_index) {
          oProduct.quantity = parseFloat(productQuantity).toString();
        }
        return oProduct;
      });

      batch.update(docRef, {
        products: updatedProducts,
        last_modified: new Date().toString(),
      });

      await batch.commit();

      dispatch(setOrderLoading(false));
      dispatch(fetchAllProducts());
    }

    closeEditProductQuantityDialog();
  };

  const updateOrder = async () => {
    if (currentUser.currentUser) {
      const docRef = doc(db, "orders", selectedOrder.order_id);
      const updatedProducts = selectedOrder.products.map((oProduct, iIndex) => {
        if (iIndex === selectedOrderProduct.product_index) {
          oProduct.measurements =
            user.measurements[selectedOrderProduct.measurmentIndex];
          delete oProduct.initialValues;
        }
        delete oProduct.initialValues;
        delete oProduct.measurmentIndex;
        return oProduct;
      });
      await updateDoc(docRef, {
        products: updatedProducts,
        last_modified: new Date().toString(),
      });

      dispatch(
        fetchOrder({
          orderId: searchParams.get("id"),
        })
      );
    }
  };

  const getLabelDate = (sLabel, aStatus) => {
    let sCreated = "";

    aStatus.some((oStatus) => {
      if (oStatus.status === sLabel) {
        sCreated = oStatus.created_on;
        return true;
      }
      return false;
    });
    if (sCreated) {
      sCreated = moment(sCreated).format("LLL");
    }
    return sCreated;
  };

  const showUpdateButton = (sStatus) => {
    if (activeStep === 3) {
      return <></>;
    }
    if (sStatus === "Payment not received") {
      return (
        <Button
          sx={{
            marginTop: "10px",
            marginBottom: "20px",
            ...stepperButtonStyles,
          }}
          onClick={(event) => {
            setShowUpdatePaymentDialog(true);
          }}
        >
          ADD PAYMENT
        </Button>
      );
    } else if (sStatus !== "Payment received") {
      return (
        <Button
          sx={{
            marginTop: "10px",
            marginBottom: "20px",
            ...stepperButtonStyles,
          }}
          onClick={(event) => {
            setShowUpdatePaymentDialog(true);
          }}
        >
          ADD PAYMENT
        </Button>
      );
    }
  };

  const onUpdateDeliveryDate = () => {
    dispatch(setOrderLoading(true));
    const docRef = doc(db, "orders", selectedOrder.order_id);
    updateDoc(docRef, {
      delivery_date: newDeliveryDate.toString(),
      last_modified: new Date().toString(),
    }).then(() => {
      dispatch(setOrderLoading(false));
      dispatch(
        fetchOrder({
          orderId: searchParams.get("id"),
        })
      );

      dispatch(fetchOrders({}));
      setShowDeliveryDateChangeDialog(false);
      setNewDeliveryDate(null);
    });
  };

  const getAlterationLabel = (oProduct) => {
    let bHasAlteration = false;

    oProduct?.alteration_history?.some((oAlteration) => {
      if (oAlteration.status === "open") {
        bHasAlteration = true;
        return true;
      }
      return false;
    });
    return (
      <>
        {bHasAlteration && (
          <Typography
            sx={{
              color: "red",
            }}
            textAlign="center"
            fontWeight={theme.fontWeight.regular}
          >
            ALTERATION
          </Typography>
        )}
      </>
    );
  };

  const showEditButton = (oOrder) => {
    //After 1 day the order cannot be cancelled

    return (
      <Button
        sx={{
          marginBottom: "20px",
          marginRight: "20px",
          ...stepperButtonStyles,
        }}
        onClick={() => {
          navigate({
            pathname: "/edit-stitch-order",
            search: `?id=${order.order_id}`,
          });
        }}
      >
        EDIT ORDER
      </Button>
    );
  };

  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          sx={{ marginBottom: "20px", ...stepperButtonStyles }}
          onClick={() => {
            navigate("/orders");
          }}
          startIcon={<ArrowBackIcon />}
        >
          BACK
        </Button>
        <Box>
          {!selectedOrder?.invoice_url && (
            <Button
              component="label"
              sx={{
                marginBottom: "20px",
                marginRight: "20px",
                ...stepperButtonStyles,
              }}
              startIcon={<CloudUploadIcon />}
            >
              UPLOAD INVOICE
              <VisuallyHiddenInput onChange={onUploadInvoice} type="file" />
            </Button>
          )}
          {selectedOrder?.invoice_url && (
            <>
              {activeStep < 1 && (
                <Button
                  sx={{
                    marginBottom: "20px",
                    marginRight: "20px",
                    ...stepperButtonStyles,
                  }}
                  onClick={() => {
                    setShowConfirmationDialog(true);
                    setConfirmationDialogMessage(
                      "Are you sure you want to delete the invoice?"
                    );
                    setAcceptButtonText("DELETE");
                    setRejectButtonText("CANCEL");
                    setConfirmationType("DI");
                  }}
                >
                  DELETE INVOICE
                </Button>
              )}
              <Button
                sx={{
                  marginBottom: "20px",
                  marginRight: "20px",
                  ...stepperButtonStyles,
                }}
                onClick={() => {
                  onDownloadOrderInvoice(selectedOrder);
                }}
              >
                DOWNLOAD INVOICE
              </Button>
            </>
          )}
          {currentUser?.currentUser?.access?.order_edit_btn &&
            activeStep < 2 &&
            showEditButton(selectedOrder)}
          <Button
            sx={{ marginBottom: "20px", ...stepperButtonStyles }}
            onClick={() => {
              onPrintButtonPressed(selectedOrder, getLabelDate);
            }}
          >
            PRINT ORDER
          </Button>
        </Box>
      </Box>
      {selectedOrder && (
        <Box>
          <Box
            sx={{
              border: "1px solid grey",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <Grid
              style={{ paddingTop: "10px" }}
              container
              spacing={{ xs: 1, md: 3 }}
              columns={{ xs: 1, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={4} key={1000}>
                <Typography
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: { xs: "center", sm768: "initial" },
                    textAlign: { xs: "center", sm768: "initial" },
                  }}
                >
                  ORDER #:&nbsp;
                </Typography>
                <Typography
                  sx={{
                    display: "inline-flex",
                    fontWeight: theme.fontWeight.semiBold,
                  }}
                >
                  {selectedOrder.order_id}
                </Typography>
                <Stack rowGap="5px">
                  <Typography
                    sx={{
                      display: "inline-flex",
                    }}
                  >
                    TOTAL AMOUNT:&nbsp;
                    <Typography
                      component={"span"}
                      sx={{
                        display: "inline-flex",
                        fontWeight: theme.fontWeight.semiBold,
                        textAlign: { xs: "center", sm768: "initial" },
                      }}
                    >
                      &#8377;{parseFloat(selectedOrder.total_amount).toFixed(2)}{" "}
                      {selectedOrder?.discount > 0 &&
                        `(${selectedOrder.discount}% discount)`}
                    </Typography>
                  </Typography>
                </Stack>
                {selectedOrder.payment_status !== "Payment received" && (
                  <>
                    <Stack rowGap="5px">
                      <Typography
                        sx={{
                          display: "inline-flex",
                        }}
                      >
                        AMOUNT PAID:&nbsp;
                        <Typography
                          component={"span"}
                          sx={{
                            display: "inline-flex",
                            fontWeight: theme.fontWeight.semiBold,
                            textAlign: { xs: "center", sm768: "initial" },
                          }}
                        >
                          &#8377;
                          {parseFloat(selectedOrder.amount_paid).toFixed(
                            2
                          )}{" "}
                        </Typography>
                      </Typography>
                    </Stack>
                    <Stack rowGap="5px">
                      <Typography
                        sx={{
                          display: "inline-flex",
                        }}
                      >
                        REMAINING BALANCE:&nbsp;
                        <Typography
                          component={"span"}
                          sx={{
                            display: "inline-flex",
                            fontWeight: theme.fontWeight.semiBold,
                            textAlign: { xs: "center", sm768: "initial" },
                          }}
                        >
                          &#8377;
                          {parseFloat(
                            parseFloat(selectedOrder.total_amount) -
                              parseFloat(selectedOrder.amount_paid)
                          ).toFixed(2)}{" "}
                        </Typography>
                      </Typography>
                    </Stack>
                  </>
                )}
                {selectedOrder.coupon_code && (
                  <Stack rowGap="5px">
                    <Typography
                      sx={{
                        display: "inline-flex",
                      }}
                    >
                      COUPON:&nbsp;
                      <Typography
                        component={"span"}
                        sx={{
                          display: "inline-flex",
                          fontWeight: theme.fontWeight.semiBold,
                        }}
                      >
                        {selectedOrder.coupon_code}
                      </Typography>
                    </Typography>
                  </Stack>
                )}
                <Stack rowGap="5px">
                  <Typography
                    sx={{
                      display: "inline-flex",
                      textAlign: { xs: "center", sm768: "initial" },
                    }}
                  >
                    PAYMENT:&nbsp;
                    <Typography
                      sx={{
                        fontWeight: theme.fontWeight.semiBold,
                      }}
                    >
                      {selectedOrder.payment_status}{" "}
                      {selectedOrder?.payment_mode &&
                        `(${selectedOrder?.payment_mode})`}
                    </Typography>
                    <IconButton
                      sx={{
                        marginLeft: "10px",
                        marginTop: "-10px",
                      }}
                      onClick={() => {
                        setShowPaymentHistoryDialog(true);
                      }}
                    >
                      <PaidIcon />
                    </IconButton>
                  </Typography>
                  {currentUser?.currentUser?.access?.payment_btn && (
                    <Box>
                      {showUpdateButton(selectedOrder.payment_status)}

                      {selectedOrder.payment_status === "Pending" && (
                        <Button
                          sx={{
                            marginBottom: "20px",
                            ...stepperButtonStyles,
                            width: "250px",
                          }}
                          onClick={(event) => {
                            setShowConfirmationDialog(true);
                            setConfirmationDialogMessage(
                              "Are you sure you want to update the payment status to 'Payment not received'?"
                            );
                            setAcceptButtonText("SAVE");
                            setRejectButtonText("CANCEL");
                            setConfirmationType("UP");
                          }}
                        >
                          PAYMENT NOT RECEIVED
                        </Button>
                      )}
                    </Box>
                  )}
                </Stack>
              </Grid>
              {selectedOrder?.delivery_type === "in-store" ? (
                <Grid item xs={2} sm={4} md={4} key={1001}>
                  <Typography
                    sx={{
                      display: "inline-flex",
                      textAlign: { xs: "center", sm768: "initial" },
                    }}
                  >
                    DELIVERY OPTION: &nbsp;{" "}
                    <Typography
                      component={"span"}
                      sx={{
                        display: "inline-flex",
                        fontWeight: theme.fontWeight.semiBold,
                        textAlign: { xs: "center", sm768: "initial" },
                      }}
                    >
                      IN STORE PICKUP
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      display: "inline-flex",
                      textAlign: { xs: "center", sm768: "initial" },
                    }}
                  >
                    {" "}
                    CUSTOMER NAME: &nbsp;
                    <span style={{ fontWeight: "bold" }}>
                      {`${order?.account_name}`}
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      display: "inline-flex",
                      textAlign: { xs: "center", sm768: "initial" },
                    }}
                  >
                    {" "}
                    CUSTOMER MOBILE: &nbsp;
                    <Link to={`/user-detail?id=${order.mobile}`}>
                      <u>{`${order.mobile.substring(3)}`}</u>
                    </Link>
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={2} sm={4} md={4} key={1003}>
                    <Stack rowGap="5px">
                      <Typography
                        sx={{
                          display: "inline-flex",
                          textAlign: { xs: "center", sm768: "initial" },
                        }}
                      >
                        DELIVERY OPTION: &nbsp;{" "}
                        <Typography
                          component={"span"}
                          sx={{
                            display: "inline-flex",
                            fontWeight: theme.fontWeight.semiBold,
                            textAlign: { xs: "center", sm768: "initial" },
                          }}
                        >
                          GET DELIVERED
                        </Typography>
                      </Typography>
                    </Stack>

                    {selectedOrder?.user_address && (
                      <Stack rowGap="5px">
                        <Typography
                          sx={{
                            display: "inline-flex",
                            textAlign: { xs: "center", sm768: "initial" },
                          }}
                        >
                          CUSTOMER MOBILE: &nbsp;{" "}
                          <Typography
                            component={"span"}
                            sx={{
                              display: "inline-flex",
                              fontWeight: theme.fontWeight.semiBold,
                              textAlign: { xs: "center", sm768: "initial" },
                            }}
                          >
                            {selectedOrder?.user_address?.mobile
                              ? selectedOrder?.user_address?.mobile?.substring(
                                  3
                                )
                              : selectedOrder.mobile.substring(3)}
                          </Typography>
                        </Typography>
                        <Typography
                          sx={{
                            display: "inline-flex",
                            textAlign: { xs: "center", sm768: "initial" },
                          }}
                        >
                          ADDRESS: &nbsp;
                        </Typography>
                        <Typography
                          sx={{
                            display: "inline-flex",
                            fontWeight: theme.fontWeight.semiBold,
                            textAlign: { xs: "center", sm768: "initial" },
                          }}
                        >{`${selectedOrder.user_address.first_name} ${selectedOrder.user_address.last_name}`}</Typography>
                        <Typography
                          sx={{
                            fontWeight: theme.fontWeight.semiBold,
                          }}
                        >{`${selectedOrder.user_address.address}, ${selectedOrder.user_address.city}, ${selectedOrder.user_address.pin_code}`}</Typography>

                        <Typography
                          sx={{
                            display: "inline-flex",
                            textAlign: { xs: "center", sm768: "initial" },
                          }}
                        >
                          TIME SLOT: &nbsp;{" "}
                          <Typography
                            component={"span"}
                            sx={{
                              display: "inline-flex",
                              fontWeight: theme.fontWeight.semiBold,
                              textAlign: { xs: "center", sm768: "initial" },
                            }}
                          >
                            {selectedOrder?.user_address?.time_slot}
                          </Typography>
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                </>
              )}
              <Grid item xs={2} sm={4} md={4} key={1004}>
                {selectedOrder?.order_status[2].created_on === "" && (
                  <Typography
                    sx={{
                      display: "inline-flex",
                      textAlign: { xs: "center", sm768: "initial" },
                    }}
                  >
                    DELIVERY DATE: &nbsp;{" "}
                    <Typography
                      component={"span"}
                      sx={{
                        display: "inline-flex",
                        fontWeight: theme.fontWeight.semiBold,
                        textAlign: { xs: "center", sm768: "initial" },
                      }}
                    >
                      {selectedOrder?.delivery_date &&
                        moment(selectedOrder?.delivery_date).format(
                          "MMMM Do, YYYY"
                        )}
                    </Typography>
                    {currentUser?.currentUser?.access
                      ?.order_delivery_date_btn && (
                      <IconButton
                        sx={{
                          marginLeft: "10px",
                          marginTop: "-10px",
                        }}
                        onClick={() => {
                          setShowDeliveryDateChangeDialog(true);
                        }}
                      >
                        <EditCalendarIcon />
                      </IconButton>
                    )}
                  </Typography>
                )}
                <Stack alignItems="end">
                  {alterationButtonVisible && (
                    <Button
                      onClick={() => {
                        navigate({
                          pathname: "/order-measurment",
                          search: `?order_id=${selectedOrder.order_id}`,
                        });
                      }}
                      sx={{
                        marginTop: "20px",
                        ...stepperButtonStyles,
                      }}
                    >
                      ALTERATION
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>

            {activeStep === 0 &&
              !selectedOrder?.invoice_url &&
              currentUser?.currentUser?.access?.order_cancel_btn && (
                <Button
                  style={{
                    marginBottom: "20px",
                    marginLeft: "0px",
                    ...stepperButtonStyles,
                  }}
                  onClick={(event) => {
                    setShowOrderCancelDialog(true);
                  }}
                >
                  CANCEL ORDER
                </Button>
              )}
            <Box sx={{ minHeight: "200px", marginBottom: "20px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          width: "200px",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        ITEM
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        DETAILS
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "150px",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        QUANTITY
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "150px",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        PRICE
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "150px",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        SUB-TOTAL
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        MEASUREMENTS FOR
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedOrder.products?.map((product, productIndex) => {
                      return (
                        <>
                          <TableRow
                            key={product.product_id}
                            sx={{ "& td": { borderBottom: 0 } }}
                          >
                            <TableCell
                              style={{
                                borderBottom: "2px solid lightgray",
                              }}
                            >
                              <Typography
                                fontSize="18px"
                                fontWeight={theme.fontWeight.bold}
                              >
                                {product.category.toUpperCase()}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: "2px solid lightgray",
                              }}
                            >
                              <MUITextField
                                disabled
                                sx={{ width: "100%" }}
                                multiline
                                rows={3}
                                value={product.product_detail}
                                fontSize="18px"
                                fontWeight={theme.fontWeight.regular}
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: "2px solid lightgray",
                              }}
                            >
                              <Typography
                                fontSize="18px"
                                fontWeight={theme.fontWeight.regular}
                              >
                                {product.quantity}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: "2px solid lightgray",
                              }}
                            >
                              <Typography
                                fontSize="18px"
                                fontWeight={theme.fontWeight.bold}
                              >
                                <>
                                  &#8377;
                                  {parseFloat(product.price).toFixed(2)}
                                </>
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: "2px solid lightgray",
                              }}
                            >
                              <Typography
                                fontSize="18px"
                                fontWeight={theme.fontWeight.bold}
                              >
                                <>
                                  &#8377;
                                  {(
                                    parseInt(product.quantity) *
                                    parseFloat(product.price).toFixed(2)
                                  ).toFixed(2)}
                                </>
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: "2px solid lightgray",
                              }}
                            >
                              {!product?.measurements ? (
                                <>
                                  {user?.measurements?.length > 0 && (
                                    <>
                                      {currentUser?.currentUser?.access
                                        ?.order_measurement_btn && (
                                        <>
                                          <Stack rowGap="5px">
                                            <FormControl fullWidth>
                                              <Select
                                                value={product?.measurmentIndex}
                                                onChange={(event) => {
                                                  setSelectedOrder(
                                                    (prevState) => {
                                                      const oClonedOrder =
                                                        cloneDeep(prevState);
                                                      oClonedOrder.products.map(
                                                        (oProduct, iPIndex) => {
                                                          if (
                                                            productIndex ===
                                                            iPIndex
                                                          ) {
                                                            oProduct.measurmentIndex =
                                                              event.target.value;

                                                            oProduct.initialValues =
                                                              user.measurements[
                                                                event.target.value
                                                              ];
                                                          }
                                                          return oProduct;
                                                        }
                                                      );
                                                      return oClonedOrder;
                                                    }
                                                  );
                                                }}
                                              >
                                                {user.measurements.map(
                                                  (oMeasurements, iIndex) => (
                                                    <MenuItem
                                                      key={iIndex}
                                                      value={iIndex}
                                                    >
                                                      {oMeasurements.name}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </FormControl>
                                            {product?.measurmentIndex !==
                                              "" && (
                                              <>
                                                {" "}
                                                <Button
                                                  sx={{
                                                    backgroundColor: "black",
                                                    borderRadius: "10px",
                                                    color: "white",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "18px",
                                                    fontWeight:
                                                      theme.fontWeight.semiBold,
                                                    maxWidth: {
                                                      xs: "400px",
                                                      sm: "448px",
                                                    },
                                                    textAlign: "center",
                                                    textTransform: "none",
                                                    "&:hover": {
                                                      backgroundColor: "black",
                                                    },
                                                  }}
                                                  onClick={(event) => {
                                                    setShowMeasurementDialog(
                                                      true
                                                    );
                                                    setMeasurementValues(
                                                      user.measurements[
                                                        product.measurmentIndex
                                                      ]
                                                    );
                                                  }}
                                                >
                                                  VIEW
                                                </Button>
                                                <Button
                                                  sx={{
                                                    backgroundColor: "black",
                                                    borderRadius: "10px",
                                                    color: "white",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "18px",
                                                    fontWeight:
                                                      theme.fontWeight.semiBold,
                                                    maxWidth: {
                                                      xs: "400px",
                                                      sm: "448px",
                                                    },
                                                    textAlign: "center",
                                                    textTransform: "none",
                                                    "&:hover": {
                                                      backgroundColor: "black",
                                                    },
                                                  }}
                                                  onClick={(event) => {
                                                    setSelectedOrderProduct({
                                                      ...product,
                                                      product_index:
                                                        productIndex,
                                                    });

                                                    setShowConfirmationDialog(
                                                      true
                                                    );
                                                    setConfirmationDialogMessage(
                                                      `Are you sure you want to save ${product?.initialValues?.name} measurements for ${product.category}?`
                                                    );
                                                    setAcceptButtonText("SAVE");
                                                    setRejectButtonText(
                                                      "CANCEL"
                                                    );
                                                    setConfirmationType("SOM");
                                                  }}
                                                >
                                                  SAVE
                                                </Button>
                                              </>
                                            )}
                                          </Stack>
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <Stack>
                                  <Button
                                    variant="text"
                                    sx={{
                                      backgroundColor: "black",
                                      borderRadius: "10px",
                                      color: "white",
                                      display: "flex",
                                      alignItems: "center",
                                      fontSize: "18px",
                                      fontWeight: theme.fontWeight.semiBold,
                                      maxWidth: { xs: "400px", sm: "448px" },
                                      textAlign: "center",
                                      textTransform: "none",
                                      "&:hover": {
                                        backgroundColor: "black",
                                      },
                                    }}
                                    onClick={(event) => {
                                      setShowMeasurementDialog(true);
                                      setMeasurementValues(
                                        product.measurements
                                      );
                                      setMeasurementEditMode(false);
                                    }}
                                  >
                                    {product.measurements.name}
                                  </Button>
                                  {getAlterationLabel(product)}
                                </Stack>
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <OrderStatusControl
              oOrder={selectedOrder}
              activeStep={activeStep}
            />
          </Box>
        </Box>
      )}

      <Dialog
        open={openEditProductQuantityDialog}
        onClose={closeEditProductQuantityDialog}
      >
        <DialogContent>
          {selectedOrderProduct && (
            <>
              <Typography
                sx={{
                  fontSize: "24px",
                }}
                fontWeight={theme.fontWeight.bold}
              >
                UPDATE PRODUCT QUANTITY
              </Typography>

              <MUITextField
                sx={{
                  marginTop: "30px",
                }}
                label="Quantity (meters)"
                error={productQuantityError !== ""}
                helperText={productQuantityError}
                value={productQuantity}
                onChange={(event) => {
                  let sEnteredQuantity = event.target.value;
                  setProductQuantity(sEnteredQuantity);
                  if (
                    sEnteredQuantity === "" ||
                    parseFloat(sEnteredQuantity) == 0
                  ) {
                    setProductQuantityError("Invalid quantity");
                    return;
                  } else {
                    setProductQuantityError("");
                  }
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              ...stepperButtonStyles,
            }}
            onClick={updateProduct}
          >
            SAVE
          </Button>
          <Button
            sx={{
              backgroundColor: "white",
              borderRadius: "10px",
              color: "black",
              display: "flex",
              alignItems: "center",
              fontSize: "18px",
              fontWeight: theme.fontWeight.semiBold,
              maxWidth: { xs: "400px", sm: "448px" },
              textAlign: "center",
              textTransform: "none",
              width: "200px",
              height: "49px",
              border: "2px solid black",
            }}
            onClick={closeEditProductQuantityDialog}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullScreen open={showDeliveryDateChangeDialog}>
        <DialogContent>
          <Box
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "1100px",
            }}
          >
            <OrderDeliveryDate
              startDate
              showOnlyNextOneMonth
              dateClickable
              dateClickedCallback={(sNewDate) => {
                setNewDeliveryDate(sNewDate);

                setShowConfirmationDialog(true);
                setConfirmationDialogMessage(
                  `Are you sure you want to change the delivery date to ${moment(
                    sNewDate
                  ).format("MMMM Do, YYYY")}?`
                );
                setAcceptButtonText("SAVE");
                setRejectButtonText("CANCEL");
                setConfirmationType("DC");
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              borderRadius: "10px",
              backgroundColor: "white",
              border: "2px solid black",
              color: "black",
              fontSize: "18px",
              fontWeight: theme.fontWeight.semiBold,
              textAlign: "center",
              height: "49px",
              width: "203px",
              textTransform: "none",
            }}
            autoFocus
            onClick={() => {
              setShowDeliveryDateChangeDialog(false);
            }}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>

      {confirmationType === "DC" && (
        <ConfirmationDialogControl onAcceptButtonPress={onUpdateDeliveryDate} />
      )}
      {confirmationType === "DI" && (
        <ConfirmationDialogControl
          onAcceptButtonPress={() => {
            onDeleteInvoice(setConfirmationType);
          }}
        />
      )}
      {confirmationType === "UP" && (
        <ConfirmationDialogControl
          onAcceptButtonPress={() =>
            onPaymentNotReceivedStatusUpdate(selectedOrder)
          }
        />
      )}

      {confirmationType === "SOM" && (
        <ConfirmationDialogControl onAcceptButtonPress={updateOrder} />
      )}

      <MeasurementControl />
      <UpdatePaymentControl
        oOrder={selectedOrder}
        remainingAmount={remainingAmount}
      />
      <PaymentHistoryControl
        oOrder={selectedOrder}
        remainingAmount={remainingAmount}
      />
      <OrderCancelDialog oOrder={selectedOrder} />
    </Box>
  );
};

export default OrderStitchDetail;
