import React, { useState } from "react";
import { Snackbar, Alert as MuiAlert } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MessageAlert = () => {
  /*Variables Start*/
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  /*Variables End*/

  /*OnLoad Start*/
  /*OnLoad End*/

  /*Functions Start*/
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };
  /*Functions End*/

  /*UI Start*/

  const AlertControl = () => (
    <Snackbar open={showAlert} autoHideDuration={3000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={alertSeverity}
        sx={{ width: "100%" }}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
/*UI End*/

  return {
    AlertControl,
    setShowAlert,
    setAlertMessage,
    setAlertSeverity,
  };
};

export default MessageAlert;
