/* eslint-disable array-callback-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
import cloneDeep from "lodash/cloneDeep";

const initialState = {
  products: null,
  loading: false,
  productsByCategory: null,
  productsOutOfStockByCategory: null,
  shirts: null,
  pants: null,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setCartProductsAndTotalCost(state, actions) {
      const mappedProducts = [];

      if (state.products?.length) {
        state.products.forEach((product) => {
          const matchedProductInCart = actions.payload.find(
            (item) => item.id === product.id
          );

          if (!!matchedProductInCart) {
            mappedProducts.push({
              ...product,
              quantity: matchedProductInCart.quantity,
              price: +(matchedProductInCart.quantity * product.price).toFixed(
                2
              ),
            });
          }
        });

        state.cartProducts.products = mappedProducts;
      }

      if (mappedProducts.length) {
        let total = mappedProducts.reduce(
          (previousValue, currentValue) => previousValue + currentValue.price,
          0
        );
        total = +total.toFixed(2);
        state.cartProducts.totalCost = total;
      } else {
        state.cartProducts.totalCost = 0;
      }
    },
    setFavoriteProducts(state, action) {
      if (state.products && action.payload && action.payload.length > 0) {
        const copyProducts = cloneDeep(state.products);
        const matchedProducts = copyProducts.filter((product) => {
          const isFavorite = action.payload.some((id) => product.id === id);
          if (isFavorite) {
            product.isFavorite = isFavorite;
          }
          return isFavorite;
        });
        state.favoriteProducts = matchedProducts;
      } else {
        state.favoriteProducts = null;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(fetchSoonOutofStock.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSoonOutofStock.fulfilled, (state, action) => {
        state.loading = false;
        state.productsOutOfStockByCategory = action.payload;
      })
      .addCase(fetchProductsByCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductsByCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.productsByCategory = action.payload;
      })
      .addCase(fetchShirts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchShirts.fulfilled, (state, action) => {
        state.loading = false;
        state.shirts = action.payload;
      })
      .addCase(fetchPants.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPants.fulfilled, (state, action) => {
        state.loading = false;
        state.pants = action.payload;
      });
  },
});

export const fetchAllProducts = createAsyncThunk(
  "products/fetchAllProducts",
  async () => {
    let q = query(collection(db, "shirts"));
    const querySnapshot = await getDocs(q);
    let data = [];

    if (!data || !data.length) {
      querySnapshot.docs.map((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        docData.category = "shirt";
        data.push(docData);
      });

      let q1 = query(collection(db, "pants"));
      const querySnapshot1 = await getDocs(q1);

      querySnapshot1.docs.map((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        docData.category = "pant";
        data.push(docData);
      });
    }

    return data;
  }
);

export const fetchProductsByCategories = createAsyncThunk(
  "products/fetchProductsByCategories",
  async ({ category }, thunkAPI) => {
    let data = [];
    // if products didn't cached before get products from server and cache
    if (!data || data.length === 0) {
      data = [];
      let q = query(collection(db, category));
      let querySnapshot = await getDocs(q);

      querySnapshot.docs.forEach((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        data.push(docData);
      });
    }
    return data;
  }
);

export const fetchShirts = createAsyncThunk(
  "products/fetchShirts",
  async () => {
    let data = [];

    // if products didn't cached before get products from server and cache
    if (!data || data.length === 0) {
      data = [];
      let q = query(collection(db, "shirts"));
      let querySnapshot = await getDocs(q);

      querySnapshot.docs.forEach((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        if (parseInt(docData.stock_quantity) > 0) {
          data.push(docData);
        }
      });
    }
    return data;
  }
);

export const fetchPants = createAsyncThunk("products/fetchPants", async () => {
  let data = [];

  // if products didn't cached before get products from server and cache
  if (!data || data.length === 0) {
    data = [];
    let q = query(collection(db, "pants"));
    let querySnapshot = await getDocs(q);

    querySnapshot.docs.forEach((doc) => {
      let docData = doc.data();
      docData.id = doc.id;
      if (parseInt(docData.stock_quantity) > 0) {
        data.push(docData);
      }
    });
  }

  return data;
});

export const fetchSoonOutofStock = createAsyncThunk(
  "products/fetchSoonOutofStock",
  async () => {
    let q = query(collection(db, "shirts"));
    const querySnapshot = await getDocs(q);
    let data = [];

    if (!data || !data.length) {
      querySnapshot.docs.map((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        docData.category = "Shirt";
        if (parseFloat(docData.stock_quantity) < 2) {
          data.push(docData);
        }
      });

      let q1 = query(collection(db, "pants"));
      const querySnapshot1 = await getDocs(q1);

      querySnapshot1.docs.map((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        docData.category = "Suiting";
        if (parseFloat(docData.stock_quantity) < 2) {
          data.push(docData);
        }
      });
    }

    return data.sort(function (a, b) {
      return new Date(b.last_modified) - new Date(a.last_modified);
    });
  }
);

export default productsSlice.reducer;
export const { setCartProductsAndTotalCost, setFavoriteProducts } =
  productsSlice.actions;
