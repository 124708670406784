/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import theme from "../../theme";
import {
  collection,
  query,
  where,
  and,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  getDocFromServer,
} from "firebase/firestore";
/*Standard Library End*/

/*Local Library Start*/
import { db } from "../../firebase";
import { MessageDialog, NewOrderPayment } from "../../components";
import { setOrderLoading } from "../../features/order/orderSlice";
import { useAppDispatch } from "../../app/store";
/*Local Library End*/

const CartSummaryStitch = ({ user, order, selectedAddress, deliveryType }) => {
  /*Standard Variables Start*/
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    MessageDialogControl,
    setShowMessageDialog,
    setMessageForMessageDialog,
    setMessageDialogTitle,
  } = MessageDialog();

  const { UpdatePaymentControl, setShowUpdatePaymentDialog } =
    NewOrderPayment();
  /*Standard Variables End*/

  /*OnLoad Functions Start*/

  /*OnLoad Functions End*/

  /*Functions Start*/
  const checkIfPendingOrder = async () => {
    const ordersRef = collection(db, "orders");
    const q = query(
      ordersRef,
      and(
        where("mobile", "==", "+91" + user.mobile),
        where("payment_status", "==", "Pending")
      )
    );

    const querySnapshot = await getDocs(q);

    const aPendingOrders = [];
    querySnapshot.forEach((doc) => {
      const oCreatedDate = new Date(doc.data().created_on);
      const oToday = new Date();

      if (
        oCreatedDate.getDate() !== oToday.getDate() ||
        oCreatedDate.getMonth() !== oToday.getMonth() ||
        oCreatedDate.getFullYear() !== oToday.getFullYear()
      ) {
        aPendingOrders.push(doc.data());
      }
    });

    return aPendingOrders.length > 0;
  };

  const onConfirmOrder = async (
    aPaymentHistory = [],
    sPaymentStatus = "Pending",
    sAmountPaid = "0"
  ) => {
    try {
      if (user) {
        dispatch(setOrderLoading(true));

        if (!order.order_id) {
          const bHasPendingOrders = await checkIfPendingOrder();
          if (bHasPendingOrders) {
            setMessageForMessageDialog(
              "The next order can be processed after all pending payments are received"
            );
            setMessageDialogTitle("Error");
            setShowMessageDialog(true);
            dispatch(setOrderLoading(false));
            return;
          }
        }
        let aProducts = [];

        order.products.forEach((oProduct) => {
          let oProductData = {
            price: oProduct.price.toString(),
            category: oProduct.category,
            product_detail: oProduct.product_detail,
            quantity: 0,
          };

          if (!oProduct.quantity) {
            if (oProduct.category === "pant") {
              oProductData.quantity = 1.6;
            } else if (oProduct.category === "shirt") {
              oProductData.quantity = 1.2;
            }
          } else {
            oProductData.quantity = oProduct.quantity;
          }
          aProducts.push(oProductData);
        });

        let sOrderId = "";
        if (order.order_id) {
          sOrderId = order.order_id;
        } else {
          const orderCountRef = doc(db, "orderCount", "orderCount");
          let snapshot = (await getDocFromServer(orderCountRef)).data();
          sOrderId = snapshot.count + 1;

          await updateDoc(orderCountRef, {
            count: sOrderId,
          });
        }

        let oDeliveryDate = new Date();
        oDeliveryDate.setDate(oDeliveryDate.getDate() + 7);
        const oOrderData = {
          order_id: sOrderId.toString(),
          account_name: user?.account_name ? user?.account_name : "",
          mobile: "+91" + user.mobile,
          email: user.email,
          user_address:
            deliveryType === "get-delivered" ? selectedAddress : null,
          delivery_type: deliveryType,
          created_on:
            order?.order_id && order?.created_on
              ? order.created_on
              : new Date().toString(),
          last_modified: new Date().toString(),
          total_amount: order.totalCost.toString(),
          amount_paid: sAmountPaid,
          discount: order.discount,
          products: aProducts,
          payment_status: sPaymentStatus,
          coupon_code: "",
          order_version: 4,
          order_type: "offline",
          payment_history: aPaymentHistory,
          stitch_only: true,
          delivery_date: oDeliveryDate.toString(),
          order_status: [
            {
              status: "Order Placed",
              created_on: new Date().toString(),
            },
            {
              status: "Order Shipped",
              created_on: "",
            },
            {
              status: "Order Delivered",
              created_on: "",
            },
            {
              status: "Order Cancelled",
              created_on: "",
            },
          ],
        };

        if (order.order_id) {
          if (order?.old_payment_history?.length > 0) {
            if (order?.payment_history?.length > 0) {
              oOrderData.old_payment_history = [
                ...order.old_payment_history,
                ...order.payment_history,
              ];
            }
          } else {
            if (order?.payment_history?.length > 0) {
              oOrderData.old_payment_history = [...order.payment_history];
            }
          }
          const docRef = doc(db, "orders", order.order_id);
          await updateDoc(docRef, oOrderData);

          setMessageForMessageDialog(
            `Order updated successully.\nOrder #: ${sOrderId} `
          );
        } else {
          await setDoc(doc(db, "orders", sOrderId.toString()), oOrderData);

          setMessageForMessageDialog(
            `Order placed successully\nOrder #: ${sOrderId} `
          );
        }
        setShowMessageDialog(true);
        dispatch(setOrderLoading(false));
      }
    } catch (error) {
      dispatch(setOrderLoading(false));
      if (order.order_id) {
        setMessageForMessageDialog(
          "Error while updating order. Please try again later."
        );
      } else {
        setMessageForMessageDialog(
          "Error while placing order. Please try again later."
        );
      }
      setShowMessageDialog(true);
    }
  };

  const onAfterMessageDialogClose = () => {
    navigate({ pathname: "/orders" });
  };
  /*Functions End*/

  return (
    <>
      {order.products && (
        <>
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "200px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      ITEM
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      DETAILS
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "150px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      QUANTITY
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "150px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      PRICE
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "150px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      SUB-TOTAL
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order?.products?.map((product) => {
                    return (
                      <TableRow
                        key={product.product_id}
                        sx={{ "& td": { borderBottom: 0 } }}
                      >
                        <TableCell>
                          <Typography
                            fontSize="18px"
                            fontWeight={theme.fontWeight.semiBold}
                          >
                            {product.category.toUpperCase()}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            fontSize="18px"
                            fontWeight={theme.fontWeight.regular}
                          >
                            {product.product_detail}
                          </Typography>
                          <TextField
                            sx={{ width: "100%" }}
                            multiline
                            rows={3}
                            value={product.product_detail}
                            fontSize="18px"
                            fontWeight={theme.fontWeight.regular}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography
                            fontSize="18px"
                            fontWeight={theme.fontWeight.regular}
                          >
                            {parseInt(product.quantity)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            fontSize="18px"
                            fontWeight={theme.fontWeight.bold}
                          >
                            &#8377;{parseFloat(product.price).toFixed(2)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            fontSize="18px"
                            fontWeight={theme.fontWeight.semiBold}
                          >
                            &#8377;{parseFloat(product.sub_total).toFixed(2)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{
              marginTop: "20px",
              justifyContent: { xs: "center", sm: "space-between" },
            }}
          >
            <Stack
              direction="row"
              columnGap="10px"
              sx={{
                alignItems: { xs: "center", lg: "flex-start" },
                width: { xs: "max-content", lg: "350px" },
                maxWidth: "100%",
                "& p": {
                  textAlign: { xs: "center", lg: "start" },
                  fontSize: "18px",
                  fontWeight: theme.fontWeight.regular,
                },
              }}
            >
              {order?.order_id ? (
                <Button
                  onClick={() => {
                    setShowUpdatePaymentDialog(true);
                  }}
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "black",
                    color: "white",
                    fontSize: "16px",
                    fontWeight: theme.fontWeight.semiBold,
                    textAlign: "center",
                    height: "49px",
                    width: "203px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  }}
                >
                  UPDATE ORDER
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setShowUpdatePaymentDialog(true);
                  }}
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "black",
                    color: "white",
                    fontSize: "16px",
                    fontWeight: theme.fontWeight.semiBold,
                    textAlign: "center",
                    height: "49px",
                    width: "203px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  }}
                >
                  PAYMENT
                </Button>
              )}
              <Button
                onClick={() => {
                  navigate({ pathname: "/orders" });
                }}
                sx={{
                  borderRadius: "10px",
                  border: "2px solid black",
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "16px",
                  fontWeight: theme.fontWeight.semiBold,
                  textAlign: "center",
                  height: "49px",
                  width: "203px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              >
                CANCEL
              </Button>
            </Stack>

            <Stack alignItems="end">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                columnGap="33px"
                width="300px"
                height="49px"
                sx={{
                  backgroundColor: "#F1F1F1",
                  borderRadius: "10px",
                  padding: "10px",
                  marginTop: "20px",
                }}
              >
                <Typography
                  fontWeight={theme.fontWeight.regular}
                  fontSize="18px"
                >
                  TOTAL AMOUNT :
                </Typography>
                <Typography
                  fontWeight={theme.fontWeight.semiBold}
                  fontSize="18px"
                >
                  &#8377;
                  {Number(parseFloat(order.totalCost)).toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
      <MessageDialogControl onAfterClose={onAfterMessageDialogClose} />
      <UpdatePaymentControl
        oOrder={order}
        remainingAmount={order.totalCost}
        onConfirmOrder={onConfirmOrder}
      />
    </>
  );
};

export default CartSummaryStitch;
