/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import {
  Box,
  Stack,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField as TextMUI,
} from "@mui/material";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
/*Standard Library End*/

/*Local Library Start*/
import { useAppDispatch } from "../../app/store";
import { fetchAppointment } from "../../features/appointments/appointmentsSlice";
import theme from "../../theme";
import { db } from "../../firebase";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const stepperButtonStyles2 = {
  border: "2px solid black",
  borderRadius: "10px",
  backgroundColor: "white",
  color: "black",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
};
/*Constant Variables End*/

const AppointmentDetail = () => {
  /*Standard Variables Start*/
  const {
    currentUser,
    appointments: { appointment },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [openDialog, setOpenDialog] = useState(false);
  const [completionMessage, setCompletionMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    const id = searchParams.get("id");
    if (currentUser?.currentUser?.access?.appointments && id) {
      dispatch(
        fetchAppointment({
          id,
        })
      );
    }
  }, [currentUser?.currentUser?.access?.appointments, searchParams.get("id")]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const completeAppointment = async () => {
    const docRef = doc(db, "appointments", searchParams.get("id"));
    await updateDoc(docRef, {
      last_modified: new Date().toString(),
      status_text: "Completed",
      status: [
        {
          id: "Scheduled",
          created_on: appointment.status[0].created_on,
          message: appointment.status[0].message,
        },
        {
          id: "Completed",
          created_on: new Date().toString(),
          message: completionMessage,
        },
      ],
    });

    navigate({
      pathname: `/appointments`,
    });
  };
  /*Functions End*/

  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <Button
        sx={{ marginBottom: "20px", ...stepperButtonStyles }}
        onClick={() => {
          navigate("/appointments");
        }}
        startIcon={<ArrowBackIcon />}
      >
        BACK
      </Button>
      {appointment && (
        <Box
          sx={{
            border: "1px solid grey",
            paddingLeft: {
              xs: theme.padding?.pagePaddingXS + "px",
              lg: theme.padding?.pagePaddingLG + "px",
              xl: theme.padding?.pagePaddingXL + "px",
            },
            paddingRight: {
              xs: theme.padding?.pagePaddingXS + "px",
              lg: theme.padding?.pagePaddingLG + "px",
              xl: theme.padding?.pagePaddingXL + "px",
            },
          }}
        >
          <Grid
            style={{ paddingTop: "10px" }}
            container
            spacing={{ xs: 1, md: 3 }}
            columns={{ xs: 1, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4} key={1000}>
              <Typography
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", sm768: "initial" },
                  textAlign: { xs: "center", sm768: "initial" },
                  fontSize: "18px",
                }}
              >
                MOBILE: &nbsp;
              </Typography>
              <Typography
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", sm768: "initial" },
                  fontWeight: theme.fontWeight.semiBold,
                  textAlign: { xs: "center", sm768: "initial" },
                  fontSize: "18px",
                }}
              >
                {appointment.mobile.substring(3)} &nbsp; &nbsp;
              </Typography>
              <Stack rowGap="5px">
                {appointment.status_text === "Scheduled" && (
                  <Button
                    variant="contained"
                    sx={{ marginTop: "20px", ...stepperButtonStyles }}
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                  >
                    COMPLETE
                  </Button>
                )}
              </Stack>
            </Grid>
            <Grid item xs={2} sm={4} md={4} key={2000}>
              <Typography
                sx={{
                  display: "inline-flex",
                  textAlign: { xs: "center", sm768: "initial" },
                  fontSize: "18px",
                }}
              >
                ADDRESS: &nbsp;
              </Typography>
              {appointment?.user_address && (
                <Stack rowGap="5px">
                  <Typography
                    sx={{
                      display: "inline-flex",
                      fontWeight: theme.fontWeight.semiBold,
                      textAlign: { xs: "center", sm768: "initial" },
                      fontSize: "18px",
                    }}
                  >{`${appointment.user_address.first_name} ${appointment.user_address.last_name}`}</Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                    }}
                  >{`${appointment.user_address.address}`}</Typography>
                  {appointment?.user_address?.city && (
                    <Typography
                      sx={{
                        fontSize: "18px",
                      }}
                    >{`${appointment.user_address.city}`}</Typography>
                  )}
                  <Typography>{`PIN: ${appointment.user_address.pin_code}`}</Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={2} sm={4} md={4} key={2000}>
              <Typography
                sx={{
                  display: "inline-flex",
                  textAlign: { xs: "center", sm768: "initial" },
                  fontSize: "18px",
                }}
              >
                TIME SLOT: &nbsp;
              </Typography>
              {appointment?.user_address && (
                <Stack rowGap="5px">
                  <Typography
                    sx={{
                      display: "inline-flex",
                      fontWeight: theme.fontWeight.semiBold,
                      textAlign: { xs: "center", sm768: "initial" },
                      fontSize: "18px",
                    }}
                  >{`${appointment?.user_address?.time_slot}`}</Typography>
                </Stack>
              )}
            </Grid>
            <Grid
              item
              key={2000}
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                sx={{
                  display: "inline-flex",
                  textAlign: { xs: "center", sm768: "initial" },
                  fontSize: "18px",
                }}
              >
                MESSAGE: &nbsp;
              </Typography>
              {appointment.status.map((oStatus) => (
                <Stack
                  rowGap="5px"
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  {oStatus.created_on && (
                    <Typography
                      sx={{
                        display: "inline-flex",
                        fontSize: "18px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>{oStatus.id}</span>
                      &nbsp;on {moment(oStatus.created_on).format("LLL")}:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        &nbsp;
                        {oStatus.message}{" "}
                      </span>
                    </Typography>
                  )}
                </Stack>
              ))}
            </Grid>
          </Grid>
        </Box>
      )}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{`COMPLETE APPOITMENT?`}</DialogTitle>
        <DialogContent>
          <TextMUI
            id="message"
            onChange={(event) => {
              setCompletionMessage(event.target.value);
            }}
            label="MESSAGE"
            multiline
            rows={3}
            sx={{
              marginTop: "10px",
              width: "500px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${messageError && "#d32f2f"}`,
              },
              "& .MuiFormHelperText-root": {
                color: "#d32f2f",
              },
            }}
            helperText={messageError && messageError}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              ...stepperButtonStyles,
            }}
            onClick={() => {
              if (completionMessage) {
                completeAppointment();
              } else {
                setMessageError("Message is required");
              }
            }}
          >
            COMPLETE
          </Button>
          <Button sx={stepperButtonStyles2} onClick={handleDialogClose}>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AppointmentDetail;
