/*Standard Library Start*/
import { Box, Autocomplete, TextField, Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
/*Standard Library Start*/

export default function SearchProduct({
  title,
  products,
  setSelected,
  selected,
  showImage = false,
  disabled = false,
  readOnly = true,
  showQuantity = false,
}) {
  /*Functions Start*/
  const filterOptions = (options, state) => {
    let newOptions = [];
    options.forEach((element) => {
      if (
        element?.product_name
          ?.toLowerCase()
          .includes(state.inputValue.toLowerCase()) ||
        element?.product_code
          ?.toLowerCase()
          .includes(state.inputValue.toLowerCase()) ||
        element?.product_description
          ?.toLowerCase()
          .includes(state.inputValue.toLowerCase()) ||
        element?.color
          ?.toLowerCase()
          .includes(state.inputValue.toLowerCase()) ||
        element?.pattern
          ?.toLowerCase()
          .includes(state.inputValue.toLowerCase()) ||
        element?.collection
          ?.toLowerCase()
          .includes(state.inputValue.toLowerCase())
      )
        newOptions.push(element);
    });
    return newOptions;
  };
  /*Functions End*/

  return (
    <Autocomplete
      selectOnFocus={false}
      className="formLabel"
      disabled={disabled}
      disableClearable
      value={selected !== "" ? selected : null}
      filterOptions={filterOptions}
      disablePortal
      onChange={(event, newValue) => {
        setSelected(newValue);
      }}
      options={products ? products : []}
      getOptionLabel={(option) => {
        let sCode = option?.product_code ? option?.product_code : "";
        if (option?.product_id) {
          sCode = option.product_id;
        }
        return `${option.product_name}  (${sCode})`;
      }}
      sx={{ width: 300 }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {showImage && (
            <>
              {option?.card_image_url?.includes("http") ? (
                <LazyLoadImage
                  threshold={10}
                  width="100"
                  visibleByDefault={true}
                  alt={option.product_name}
                  src={option?.card_image_url}
                />
              ) : (
                <Box
                  sx={{
                    width: "100px",
                    border: "1px solid lightgray",
                    marginRight: "10px",
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Image not available
                  </Typography>
                </Box>
              )}
            </>
          )}
          {option.product_name} <br />
          {option.product_code}
          <br />
          {showQuantity && (
            <>{parseFloat(option.stock_quantity).toFixed(1)} meters</>
          )}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={title}
          inputProps={{
            ...params.inputProps,
            readOnly: readOnly,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
