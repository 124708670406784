/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Button, Grid } from "@mui/material";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import {
  ArrowBack as ArrowBackIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getStorage, ref, deleteObject, uploadBytes } from "firebase/storage";
/*Standard Library End*/

/*Local Library Start*/
import { setProductLoading } from "../../features/product/productSlice";
import { MessageDialog, ConfirmationDialog } from "../../components";
import theme from "../../theme";
import { useAppDispatch } from "../../app/store";
import { db } from "../../firebase";
import { fetchProduct } from "../../features/product/productSlice";
import shirtpatterns from "../../constants/shirtpatterns.json";
import pantpatterns from "../../constants/pantpatterns.json";
import shirtcolors from "../../constants/shirtcolors.json";
import pantcolors from "../../constants/pantcolors.json";
/*Local Library End*/

/*Constant Variables Start*/
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const Item = styled(Box)(({ theme }) => ({}));
/*Constant Variables End*/

const ProductDetail = () => {
  /*Standard Variables Start*/
  const {
    MessageDialogControl,
    setShowMessageDialog,
    setMessageForMessageDialog,
    setMessageDialogTitle,
  } = MessageDialog();
  const {
    ConfirmationDialogControl,
    setShowConfirmationDialog,
    setConfirmationDialogMessage,
    setAcceptButtonText,
    setRejectButtonText,
  } = ConfirmationDialog();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    currentUser,
    product,
    user: { user },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [imageDeleteConfirmation, setImageDeleteConfirmation] = useState(false);
  const [deleteType, setDeleteType] = useState(""); // I - Image, P - Product
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    const id = searchParams.get("id");
    if (id && pathname && currentUser?.currentUser?.access?.products) {
      dispatch(
        fetchProduct({
          category: pathname.substring(1) + "s",
          productId: id,
        })
      );
    }
  }, [
    searchParams.get("id"),
    user,
    pathname,
    currentUser?.currentUser?.access?.products,
  ]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  const onAfterMessageDialogClose = (sId) => {
    const id = searchParams.get("id");
    dispatch(
      fetchProduct({
        category: pathname.substring(1) + "s",
        productId: id,
      })
    );
    setDeleteType("");
  };

  const getColorLabel = (sId) => {
    let displayColor = [];
    let aColor = sId.split(",");
    if (pathname.includes("shirt")) {
      aColor.map((selectedColor) => {
        shirtcolors.some((oColor) => {
          if (selectedColor === oColor.id) {
            displayColor.push(oColor.label);
            return true;
          }
          return false;
        });
      });
    } else {
      aColor.map((selectedColor) => {
        pantcolors.some((oColor) => {
          if (selectedColor === oColor.id) {
            displayColor.push(oColor.label);
            return true;
          }
          return false;
        });
      });
    }

    return displayColor.join(", ");
  };

  const getPatternLabel = (sId) => {
    let sColor = "";
    if (pathname.includes("shirt")) {
      shirtpatterns.some((oColor) => {
        if (sId === oColor.id) {
          sColor = oColor.label;
          return true;
        }
        return false;
      });
    } else {
      pantpatterns.some((oColor) => {
        if (sId === oColor.id) {
          sColor = oColor.label;
          return true;
        }
        return false;
      });
    }
    return sColor;
  };

  const deleteProduct = () => {
    const storage = getStorage();

    let sCardUrl = product.product.card_image_url;
    let sFabricUrl = product.product.fabric_image_url;

    let aPromises = [];
    if (sCardUrl) {
      let aUrl = sCardUrl.split("/o/");
      let sNewUrl = "";
      if (aUrl.length > 0) {
        sNewUrl = aUrl[1];
        sNewUrl = decodeURIComponent(sNewUrl);
        sNewUrl = sNewUrl.split("?")[0];

        const desertRef = ref(storage, sNewUrl);

        aPromises.push(deleteObject(desertRef));
      }
    }

    if (sFabricUrl) {
      let aUrl = sFabricUrl.split("/o/");
      let sNewUrl = "";
      if (aUrl.length > 0) {
        sNewUrl = aUrl[1];
        sNewUrl = decodeURIComponent(sNewUrl);
        sNewUrl = sNewUrl.split("?")[0];

        const desertRef = ref(storage, sNewUrl);

        aPromises.push(deleteObject(desertRef));
      }
    }

    const sDeleteProduct = () => {
      deleteDoc(doc(db, pathname.substring(1) + "s", searchParams.get("id")))
        .then((result) => {
          dispatch(setProductLoading(false));
          setMessageForMessageDialog("Product deleted successfully!");
          setMessageDialogTitle("Success");
          setShowMessageDialog(true);
        })
        .catch((error) => {
          dispatch(setProductLoading(false));
          setMessageForMessageDialog(`Something bad happened: ${error}`);
          setMessageDialogTitle("Error");
          setShowMessageDialog(true);
        });
    };

    dispatch(setProductLoading(true));
    Promise.all(aPromises)
      .then(sDeleteProduct)
      .catch((error) => {
        if (error.code === "storage/object-not-found") {
          sDeleteProduct();
        } else {
          dispatch(setProductLoading(false));
          setMessageForMessageDialog(`Something bad happened: ${error}`);
          setMessageDialogTitle("Error");
          setShowMessageDialog(true);
        }
      });
  };

  const deleteFileFromStorage = async () => {
    dispatch(setProductLoading(true));
    const storage = getStorage();

    let sUrl = "";
    let sNewUrl = "";

    if (imageDeleteConfirmation === "card") {
      sUrl = product.product.card_image_url;
    } else {
      sUrl = product.product.fabric_image_url;
    }
    let aUrl = sUrl.split("/o/");

    if (aUrl.length > 0) {
      sNewUrl = aUrl[1];
      sNewUrl = decodeURIComponent(sNewUrl);
      sNewUrl = sNewUrl.split("?")[0];
    }

    // Create a reference to the file to delete
    const desertRef = ref(storage, sNewUrl);

    const updateProductAfterDelete = async () => {
      const productRef = doc(
        db,
        `${pathname.substring(1)}s`,
        product.product.product_code
      );

      let oNewProduct = {
        ...product.product,
      };

      if (imageDeleteConfirmation === "card") {
        oNewProduct.card_image_url = "";
      } else {
        oNewProduct.fabric_image_url = "";
      }

      updateDoc(productRef, oNewProduct)
        .then((result) => {
          dispatch(setProductLoading(false));
          setMessageForMessageDialog("Image deleted successfully!");
          setMessageDialogTitle("Success");
          setShowMessageDialog(true);
          setDeleteType("");
        })
        .catch((error) => {
          dispatch(setProductLoading(false));
          setMessageForMessageDialog(`Something bad happened: ${error}`);
          setMessageDialogTitle("Error");
          setShowMessageDialog(true);
        });
    };

    // Delete the file
    deleteObject(desertRef)
      .then(() => {
        // File deleted successfully
        updateProductAfterDelete();
      })
      .catch((error) => {
        if (error.code === "storage/object-not-found") {
          updateProductAfterDelete();
        } else {
          setMessageForMessageDialog(`Something bad happened: ${error}`);
          setMessageDialogTitle("Error");
          setShowMessageDialog(true);
          dispatch(setProductLoading(false));
        }
        // Uh-oh, an error occurred!
      });
  };

  const onDeleteDetailPageImage = () => {
    setImageDeleteConfirmation("detail");
    setShowConfirmationDialog(true);
    setConfirmationDialogMessage(
      "Are you sure you want to delete the detail page image?"
    );
    setAcceptButtonText("DELETE");
    setRejectButtonText("CANCEL");
    setDeleteType("I");
  };

  const onDeleteCardImage = () => {
    setImageDeleteConfirmation("card");
    setShowConfirmationDialog(true);
    setConfirmationDialogMessage(
      "Are you sure you want to delete the card image?"
    );
    setAcceptButtonText("DELETE");
    setRejectButtonText("CANCEL");
    setDeleteType("I");
  };

  const onCardFileUpload = (oEvent) => {
    if (oEvent.target.files.length === 1) {
      let oFile = oEvent.target.files[0];

      if (oFile.type === "image/webp") {
        let sFilezie = parseFloat(oFile.size / 1024).toFixed(2);

        if (sFilezie > 100) {
          setMessageForMessageDialog("Image size should be less than 100KB!");
          setMessageDialogTitle("Error");
          setShowMessageDialog(true);
          return;
        }
        const productRef = doc(
          db,
          `${pathname.substring(1)}s`,
          product.product.product_code
        );

        let oNewProduct = {
          ...product.product,
          card_image_url: `https://firebasestorage.googleapis.com/v0/b/${
            process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
          }/o/${pathname.substring(1)}s%2F${product.product.product_code}%2F${
            product.product.product_code
          }-0-Card.webp?alt=media`,
        };

        dispatch(setProductLoading(true));
        updateDoc(productRef, oNewProduct)
          .then((result) => {
            const storage = getStorage();
            const storageRef = ref(
              storage,
              `${pathname.substring(1)}s/${product.product.product_code}/${
                product.product.product_code
              }-0-Card.webp`
            );

            // 'file' comes from the Blob or File API
            uploadBytes(storageRef, oFile)
              .then((snapshot) => {
                dispatch(setProductLoading(false));
                setMessageForMessageDialog("Image uploaded successfully!");
                setMessageDialogTitle("Success");
                setShowMessageDialog(true);
              })
              .catch((error) => {
                dispatch(setProductLoading(false));
                setMessageForMessageDialog(`Something bad happened: ${error}`);
                setMessageDialogTitle("Error");
                setShowMessageDialog(true);
              });
          })
          .catch((error) => {
            dispatch(setProductLoading(false));
            setMessageForMessageDialog(`Something bad happened: ${error}`);
            setMessageDialogTitle("Error");
            setShowMessageDialog(true);
          });
      } else {
        setMessageForMessageDialog("Only webp file is supported!");
        setMessageDialogTitle("Error");
        setShowMessageDialog(true);
      }
    } else {
      setMessageForMessageDialog("Only 1 file is supported!");
      setMessageDialogTitle("Error");
      setShowMessageDialog(true);
    }
  };

  const onDetailPageFileUpload = (oEvent) => {
    if (oEvent.target.files.length === 1) {
      let oFile = oEvent.target.files[0];

      if (oFile.type === "image/webp") {
        const productRef = doc(
          db,
          `${pathname.substring(1)}s`,
          product.product.product_code
        );

        let filExtension = oFile.name.split(".").pop();

        let oNewProduct = {
          ...product.product,
          fabric_image_url: `https://firebasestorage.googleapis.com/v0/b/${
            process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
          }/o/${pathname.substring(1)}s%2F${product.product.product_code}%2F${
            product.product.product_code
          }-0.${filExtension}?alt=media`,
        };

        dispatch(setProductLoading(true));
        updateDoc(productRef, oNewProduct)
          .then((result) => {
            const storage = getStorage();
            const storageRef = ref(
              storage,
              `${pathname.substring(1)}s/${product.product.product_code}/${
                product.product.product_code
              }-0.${filExtension}`
            );

            // 'file' comes from the Blob or File API
            uploadBytes(storageRef, oFile)
              .then((snapshot) => {
                dispatch(setProductLoading(false));
                setMessageForMessageDialog("Image uploaded successfully!");
                setMessageDialogTitle("Success");
                setShowMessageDialog(true);
              })
              .catch((error) => {
                dispatch(setProductLoading(false));
                setMessageForMessageDialog(`Something bad happened: ${error}`);
                setMessageDialogTitle("Error");
                setShowMessageDialog(true);
              });
          })
          .catch((error) => {
            dispatch(setProductLoading(false));
            setMessageForMessageDialog(`Something bad happened: ${error}`);
            setMessageDialogTitle("Error");
            setShowMessageDialog(true);
          });
      } else {
        setMessageForMessageDialog("Only webp file is supported!");
        setMessageDialogTitle("Error");
        setShowMessageDialog(true);
      }
    } else {
      setMessageForMessageDialog("Only 1 file is supported!");
      setMessageDialogTitle("Error");
      setShowMessageDialog(true);
    }
  };
  /*Functions End*/

  return (
    <>
      <Stack
        sx={{
          minHeight: "500px",
          width: "100%",
          marginTop: { xs: "83px", md: "29px" },
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            sx={{ marginBottom: "20px", ...stepperButtonStyles }}
            onClick={() => {
              if (pathname.includes("shirt")) {
                navigate({ pathname: "/shirts" });
              } else {
                navigate({ pathname: "/pants" });
              }
            }}
            startIcon={<ArrowBackIcon />}
          >
            BACK
          </Button>
          {currentUser?.currentUser?.access?.products &&
            currentUser?.currentUser?.access?.product_edit_btn && (
              <Box>
                {pathname.includes("shirt") ? (
                  <>
                    <Button
                      onClick={() => {
                        navigate({
                          pathname: `/add-shirt`,
                          search: `?id=${searchParams.get("id")}`,
                        });
                      }}
                      sx={{ marginBottom: "20px", ...stepperButtonStyles }}
                    >
                      EDIT SHIRT
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        navigate({
                          pathname: `/add-pant`,
                          search: `?id=${searchParams.get("id")}`,
                        });
                      }}
                      sx={{ marginBottom: "20px", ...stepperButtonStyles }}
                    >
                      EDIT SUITING
                    </Button>
                  </>
                )}
                &nbsp;&nbsp;
                {pathname.includes("shirt") ? (
                  <Button
                    onClick={() => {
                      setShowConfirmationDialog(true);
                      setConfirmationDialogMessage(
                        "Are you sure you want to delete the product?"
                      );
                      setAcceptButtonText("DELETE");
                      setRejectButtonText("CANCEL");
                      setDeleteType("P");
                    }}
                    sx={{ marginBottom: "20px", ...stepperButtonStyles }}
                  >
                    DELETE SHIRT
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setShowConfirmationDialog(true);
                      setConfirmationDialogMessage(
                        "Are you sure you want to delete the product?"
                      );
                      setAcceptButtonText("DELETE");
                      setRejectButtonText("CANCEL");
                      setDeleteType("P");
                    }}
                    sx={{ marginBottom: "20px", ...stepperButtonStyles }}
                  >
                    DELETE SUITING
                  </Button>
                )}
              </Box>
            )}
        </div>
        <>
          {product.product && (
            <>
              <Stack
                sx={{
                  width: "100%",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                  },
                }}
                columnGap="10px"
              >
                <Box
                  sx={{
                    width: "max-content",
                    padding: { xs: "0px", md: "20px" },
                    position: "relative",
                    maxWidth: { xs: "100%", md: "60%" },
                    "& .product-detail-image": { maxWidth: "100%" },
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid black",
                      minWidth: "680px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: theme.fontWeight.semiBold,
                        }}
                      >
                        DETAIL PAGE IMAGE
                      </Typography>
                      {product?.product?.fabric_image_url !== "" ? (
                        <Button
                          sx={stepperButtonStyles}
                          onClick={onDeleteDetailPageImage}
                        >
                          DELETE IMAGE
                        </Button>
                      ) : (
                        <Button
                          component="label"
                          sx={stepperButtonStyles}
                          startIcon={<CloudUploadIcon />}
                        >
                          UPLOAD FILE
                          <VisuallyHiddenInput
                            onChange={onDetailPageFileUpload}
                            type="file"
                          />
                        </Button>
                      )}
                    </Box>
                    {product?.product?.fabric_image_url !== "" && (
                      <img
                        src={product.product.fabric_image_url}
                        className="product-detail-image"
                        alt={product.product.product_name}
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid black",
                      marginTop: "20px",
                      minWidth: "680px",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        "& .product-image": {
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                          height: "270px",
                          width: "350px",
                          objectFit: "cover",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: theme.fontWeight.semiBold,
                          }}
                        >
                          CARD IMAGE
                        </Typography>
                        {product?.product?.card_image_url !== "" ? (
                          <Button
                            sx={stepperButtonStyles}
                            onClick={onDeleteCardImage}
                          >
                            DELETE IMAGE
                          </Button>
                        ) : (
                          <Button
                            component="label"
                            sx={stepperButtonStyles}
                            startIcon={<CloudUploadIcon />}
                          >
                            UPLOAD FILE
                            <VisuallyHiddenInput
                              onChange={onCardFileUpload}
                              type="file"
                            />
                          </Button>
                        )}
                      </Box>
                      {product?.product?.card_image_url ? (
                        <LazyLoadImage
                          threshold={10}
                          visibleByDefault={true}
                          alt={product.product.card_image_url}
                          height="270px"
                          src={product?.product.card_image_url} // use normal <img> attributes as props
                          width="350px"
                        />
                      ) : (
                        <Box
                          sx={{
                            padding: "10px",
                          }}
                          height="270px"
                          width="350px"
                        >
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: theme.fontWeight.semiBold,
                            }}
                          >
                            Width: 350px
                            <br />
                            Height: 270px
                            <br />
                            Size less then 100kb
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>

                <Stack
                  sx={{
                    padding: { xs: "20px", md: "36px" },
                    rowGap: "20px",
                    width: { xs: "100%", sm: "100%", md: "50%" },
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: "1px solid grey",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: theme.fontWeight.semiBold,
                      }}
                    >
                      {product.product.product_name}
                    </Typography>
                    {pathname.substring(1) === "shirt" && (
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: theme.fontWeight.regular,
                          color: "#7b7b7b",
                        }}
                      >
                        &#8377;
                        {Number(
                          parseFloat(product.product.price)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    )}
                  </Box>
                  <Stack
                    sx={{
                      width: "100%",
                      flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                        lg: "row",
                      },
                    }}
                    columnGap="10px"
                  >
                    {pathname.substring(1) === "pant" && (
                      <Stack
                        sx={{
                          backgroundColor: "lightgray",
                          borderRadius: "5px",
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        <Stack
                          sx={{
                            flexDirection: { md: "row" },
                            alignItems: "flex-start",
                            height: "15px",
                          }}
                        >
                          <Typography
                            fontSize="18px"
                            fontWeight={theme.fontWeight.semiBold}
                            textAlign="center"
                            sx={{ marginBottom: "15px" }}
                          >
                            PRICE CHART{" "}
                          </Typography>
                        </Stack>
                        <Typography
                          fontSize="18px"
                          fontWeight={theme.fontWeight.regular}
                        >
                          <Grid
                            sx={{
                              marginTop: "10px",
                            }}
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                          >
                            {typeof product?.product?.price === "object" && (
                              <>
                                {product?.product?.price?.map((oClassic) => {
                                  if (product.product.collection !== "Linen") {
                                    return (
                                      <>
                                        <Grid item xs={6}>
                                          <Item>
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {oClassic.label}
                                            </span>
                                          </Item>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Item>
                                            &#8377;
                                            {parseFloat(oClassic.price).toFixed(
                                              2
                                            )}
                                          </Item>
                                        </Grid>
                                      </>
                                    );
                                  } else {
                                    if (oClassic.category === "trouser") {
                                      return (
                                        <>
                                          <Grid item xs={6}>
                                            <Item>
                                              <span
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {oClassic.label}
                                              </span>
                                            </Item>
                                          </Grid>
                                          <Grid item xs={6}>
                                            <Item>
                                              &#8377;
                                              {parseFloat(
                                                oClassic.price
                                              ).toFixed(2)}
                                            </Item>
                                          </Grid>
                                        </>
                                      );
                                    }
                                  }
                                })}
                              </>
                            )}
                          </Grid>
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                  <Stack
                    sx={{
                      width: "100%",
                      flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                        lg: "row",
                      },
                    }}
                    columnGap="10px"
                  >
                    <Stack
                      sx={{
                        backgroundColor: "lightgray",
                        borderRadius: "5px",
                        width: "100%",
                        padding: "10px",
                      }}
                    >
                      <Stack
                        sx={{
                          flexDirection: { md: "row" },
                          alignItems: "flex-start",
                          height: "15px",
                        }}
                      >
                        <Typography
                          fontSize="18px"
                          fontWeight={theme.fontWeight.semiBold}
                          textAlign="center"
                          sx={{ marginBottom: "15px" }}
                        >
                          PRODUCT HIGHLIGHTS{" "}
                        </Typography>
                      </Stack>
                      <Typography
                        fontSize="18px"
                        fontWeight={theme.fontWeight.regular}
                      >
                        <Grid
                          sx={{
                            marginTop: "10px",
                          }}
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                        >
                          <Grid item xs={6}>
                            <Item>
                              <span style={{ fontWeight: "bold" }}>
                                COLLECTION
                              </span>
                            </Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Item>{product.product.collection}</Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Item>
                              <span style={{ fontWeight: "bold" }}>COLOUR</span>
                            </Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Item>{getColorLabel(product.product.color)}</Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Item>
                              <span style={{ fontWeight: "bold" }}>
                                PATTERN
                              </span>
                            </Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Item>
                              {getPatternLabel(product.product.pattern)}
                            </Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Item>
                              <span style={{ fontWeight: "bold" }}>
                                PRODUCT CODE
                              </span>
                            </Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Item> {product.product.product_code}</Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Item>
                              <span style={{ fontWeight: "bold" }}>METERS</span>
                            </Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Item>
                              {" "}
                              {parseFloat(
                                product.product.stock_quantity
                              ).toFixed(1)}
                            </Item>
                          </Grid>
                        </Grid>
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      width: "100%",
                      flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                        lg: "row",
                      },
                    }}
                    columnGap="10px"
                  >
                    <Stack
                      sx={{
                        backgroundColor: "lightgray",
                        borderRadius: "5px",
                        width: "100%",
                        padding: "10px",
                      }}
                    >
                      <Stack
                        sx={{
                          flexDirection: { md: "row" },
                          alignItems: "flex-start",
                          height: "15px",
                        }}
                      >
                        <Typography
                          fontSize="18px"
                          fontWeight={theme.fontWeight.semiBold}
                          textAlign="center"
                          sx={{ marginBottom: "15px" }}
                        >
                          PRODUCT DESCRIPTION{" "}
                        </Typography>
                      </Stack>
                      <Typography
                        fontSize="18px"
                        fontWeight={theme.fontWeight.regular}
                        sx={{
                          marginTop: "10px",
                        }}
                      >
                        {product.product.product_description}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      width: "100%",
                      flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                        lg: "row",
                      },
                    }}
                    columnGap="10px"
                  >
                    <Stack
                      sx={{
                        backgroundColor: "lightgray",
                        borderRadius: "5px",
                        width: "100%",
                        padding: "10px",
                      }}
                    >
                      <Stack
                        sx={{
                          flexDirection: { md: "row" },
                          alignItems: "flex-start",
                          height: "15px",
                        }}
                      >
                        <Typography
                          fontSize="18px"
                          fontWeight={theme.fontWeight.semiBold}
                          textAlign="center"
                          sx={{ marginBottom: "15px" }}
                        >
                          MATERIAL & CARE{" "}
                        </Typography>
                      </Stack>
                      <Typography
                        fontSize="18px"
                        fontWeight={theme.fontWeight.regular}
                        sx={{
                          marginTop: "10px",
                        }}
                      >
                        {product.product.material_care}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </>
          )}
        </>
      </Stack>

      {deleteType === "I" && (
        <ConfirmationDialogControl
          onAcceptButtonPress={deleteFileFromStorage}
        />
      )}
      {deleteType === "P" && (
        <ConfirmationDialogControl onAcceptButtonPress={deleteProduct} />
      )}
      {deleteType === "I" && (
        <MessageDialogControl onAfterClose={onAfterMessageDialogClose} />
      )}
      {deleteType === "P" && (
        <MessageDialogControl
          onAfterClose={() => {
            if (pathname.includes("shirt")) {
              navigate({
                pathname: `/shirts`,
              });
            } else {
              navigate({
                pathname: `/pants`,
              });
            }
            setDeleteType("");
          }}
        />
      )}
    </>
  );
};

export default ProductDetail;
