import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const initialState = {
  user: null,
  loading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLoading(state, actions) {
      state.loading = actions.payload;
    },
    setUser(state, actions) {
      state.user = actions.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      });
  },
});

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async ({ mobile }, thunkAPI) => {
    const userRef = doc(db, "users", mobile);
    const user = await getDoc(userRef);
    return user.data();
  }
);

export default userSlice.reducer;
export const { setUser, setUserLoading } = userSlice.actions;
