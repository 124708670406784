/* eslint-disable array-callback-return */

/*Standard Library Start*/
import React, { useState } from "react";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Stack,
  Typography,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from "@mui/material";
import {
  Computer as ComputerIcon,
  Inventory as InventoryIcon,
  Man as ManIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { stepConnectorClasses } from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { doc, updateDoc } from "firebase/firestore";
/*Standard Library End*/

/*Local Library Start*/
import { db } from "../../firebase";
import steps from "../../constants/orderStatus.json";
import { MessageAlert, ConfirmationDialog } from "../../components";
import theme from "../../theme";
import { useAppDispatch } from "../../app/store";
import { fetchOrder } from "../../features/order/orderSlice";
import { setOrderLoading } from "../../features/order/orderSlice";
/*Local Library End*/

/*Constant Variables Start*/
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "black",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "black",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "black",
  }),
  ...(ownerState.completed && {
    backgroundColor: "black",
  }),
}));
/*Constant Variables End*/

const OrderStatus = () => {
  /*Standard Variables Start*/
  const {
    products: { products },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { AlertControl, setAlertMessage, setAlertSeverity, setShowAlert } =
    MessageAlert();
  const {
    ConfirmationDialogControl,
    setShowConfirmationDialog,
    setConfirmationDialogMessage,
    setAcceptButtonText,
    setRejectButtonText,
  } = ConfirmationDialog();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [labelSelected, setLabelSelected] = useState("");
  /*Local Variables End*/

  /*Functions Start*/
  const onOrderStatusClicked = (oOrder) => {
    dispatch(setOrderLoading(true));
    const docRef = doc(db, "orders", oOrder.order_id);
    const copyOrder = cloneDeep(oOrder);

    if (steps.includes(labelSelected)) {
      copyOrder.order_status.map((oStatus) => {
        if (oStatus.status === labelSelected) {
          oStatus.created_on = new Date().toString();
        }
      });

      const updatedProducts = oOrder.products.map(
        (oOriginalProduct, iProductIndex) => {
          const oProduct = cloneDeep(oOriginalProduct);

          oProduct?.alteration_history?.map((oAlteration) => {
            oAlteration.status = "close";
          });

          return oProduct;
        }
      );

      updateDoc(docRef, {
        order_status: copyOrder.order_status,
        products: updatedProducts,
        last_modified: new Date().toString(),
      })
        .then(() => {
          dispatch(setOrderLoading(false));
          dispatch(
            fetchOrder({
              orderId: searchParams.get("id"),
              products,
            })
          );
        })
        .catch((error) => {
          dispatch(setOrderLoading(false));
          setAlertMessage(`Something bad happened: ${error}`);
          setAlertSeverity("error");
          setShowAlert(true);
        });
    }
  };

  const getLabelDate = (sLabel, aStatus) => {
    let sCreated = "";

    aStatus.some((oStatus) => {
      if (oStatus.status === sLabel) {
        sCreated = oStatus.created_on;
        return true;
      }
      return false;
    });
    if (sCreated) {
      sCreated = moment(sCreated).format("LLL");
    }
    return sCreated;
  };

  const getLabelMessage = (sLabel, aStatus) => {
    let sCreated = "";
    aStatus.some((oStatus) => {
      if (oStatus.status === sLabel) {
        sCreated = oStatus.message;
        return true;
      }
      return false;
    });
    return sCreated ? `Reason: ${sCreated}` : "";
  };

  const ColorlibStepIcon = function (props) {
    const { active, completed, className } = props;

    const icons = {
      1: <ComputerIcon />,
      2: <InventoryIcon />,
      3: <ManIcon />,
      4: <CloseIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  };

  const OrderStatusControl = ({ oOrder, activeStep }) => {
    return (
      <Stack sx={{ width: "100%", padding: "10px" }} spacing={4}>
        <Stepper
          className="pointerClass"
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label, iIndex) => {
            let bOrderCancelled = false;
            oOrder.order_status.some((oStatus) => {
              if (
                oStatus.status === "Order Cancelled" &&
                oStatus.created_on !== ""
              ) {
                bOrderCancelled = true;
                return true;
              }
              return false;
            });
            if (bOrderCancelled && label === "Order Shipped") {
              return <></>;
            } else if (bOrderCancelled && label === "Order Delivered") {
              return <></>;
            } else if (!bOrderCancelled && label === "Order Cancelled") {
              return <></>;
            }
            return (
              <Step
                key={label}
                onClick={() => {
                  if (
                    (activeStep === 0 && label === "Order Shipped") ||
                    (activeStep === 1 && label === "Order Delivered")
                  ) {
                    if (label === "Order Shipped" && !oOrder?.invoice_url) {
                      setAlertMessage("Upload Invoice!");
                      setAlertSeverity("error");
                      setShowAlert(true);
                      return;
                    }

                    if (
                      label === "Order Shipped" ||
                      label === "Order Delivered"
                    ) {
                      let bError = false;
                      oOrder.products.some((oProduct) => {
                        if (!oProduct?.measurements) {
                          bError = true;
                          return true;
                        }
                        return false;
                      });
                      if (bError) {
                        setAlertMessage("Measurements missing for products!");
                        setAlertSeverity("error");
                        setShowAlert(true);
                        return;
                      }

                      let bAlterationError = false;
                      oOrder.products.some((oProduct) => {
                        if (oProduct?.alteration_history?.length > 0) {
                          oProduct.alteration_history.some((oAlteration) => {
                            if (oAlteration.status === "open") {
                              bAlterationError = true;
                              return true;
                            }
                          });
                          if (bAlterationError) {
                            return true;
                          }
                        }
                        return false;
                      });

                      if (bAlterationError) {
                        setAlertMessage(
                          "Close the all alteration request before proceeding!"
                        );
                        setAlertSeverity("error");
                        setShowAlert(true);
                        return;
                      }
                    }

                    if (
                      label === "Order Delivered" &&
                      oOrder.payment_status !== "Payment received"
                    ) {
                      setAlertMessage("Payment not received");
                      setAlertSeverity("error");
                      setShowAlert(true);
                      return;
                    }

                    setLabelSelected(label);

                    setShowConfirmationDialog(true);
                    setConfirmationDialogMessage(
                      `Are you sure you want to update the order status to '${
                        label === "Order Shipped" ? "Order Ready" : label
                      }'?`
                    );
                    setAcceptButtonText("UPDATE");
                    setRejectButtonText("CANCEL");
                  }
                }}
              >
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  <Grid item xs={2} sm={4} md={4} key={iIndex}>
                    <Stack rowGap="5px">
                      <Typography
                        sx={{
                          fontWeight: theme.fontWeight.semiBold,
                        }}
                      >
                        {label === "Order Shipped" ? "Order Ready" : label}
                      </Typography>
                      <Typography>
                        {getLabelDate(label, oOrder.order_status)}
                      </Typography>
                      <Typography>
                        {getLabelMessage(label, oOrder.order_status)}
                      </Typography>
                    </Stack>
                  </Grid>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <ConfirmationDialogControl
          onAcceptButtonPress={() => {
            onOrderStatusClicked(oOrder);
          }}
        />
        <AlertControl />
      </Stack>
    );
  };
  /*Functions End*/

  return {
    OrderStatusControl,
  };
};

export default OrderStatus;
