/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import { Box, Stack, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import { ProductList } from "../../components";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};
/*Constant Variables End*/

const Products = () => {
  /*Standard Variables Start*/
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentUser } = useSelector((state) => state);
  /*Standard Variables End*/

  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          sx={{ marginBottom: "20px", ...stepperButtonStyles }}
          onClick={() => {
            navigate("/products");
          }}
          startIcon={<ArrowBackIcon />}
        >
          BACK
        </Button>
        {currentUser?.currentUser?.access?.product_edit_btn && (
          <Button
            onClick={() => {
              navigate({
                pathname:
                  pathname.substring(1) === "shirts"
                    ? "/add-shirt"
                    : "/add-pant",
              });
            }}
            sx={{ marginBottom: "20px", ...stepperButtonStyles }}
          >
            ADD {pathname.substring(1) === "shirts" ? "SHIRT" : "SUITING"}
          </Button>
        )}
      </div>
      <Stack direction="row" sx={{ columnGap: { md: "14px", lg: "34px" } }}>
        <ProductList />
      </Stack>
    </Box>
  );
};

export default Products;
