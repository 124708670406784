/*Standard Library Start*/
import React, { useState } from "react";
import {
  Stack,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import { Formik, Form, Field, setNestedObjectValues } from "formik";
import { TextField, Select } from "formik-mui";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../../firebase";
import cloneDeep from "lodash/cloneDeep";
import { useSearchParams } from "react-router-dom";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import { useAppDispatch } from "../../app/store";
import { fetchUser } from "../../features/user/userSlice";
import { MessageAlert } from "../../components";
/*Local Library End*/

/*Constant Variables Start*/
const validationSchema = yup.object({
  name: yup.string().required("Mandatory"),
  shirt_length: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  shirt_chest: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  shirt_shoulders: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  shirt_sleeves: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  shirt_muscles: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  shirt_stomach: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  shirt_sit: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  shirt_collor: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  shirt_cuff: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  shirt_cuff_button: yup.string().required("Mandatory"),
  shirt_bottom: yup.string().required("Mandatory"),
  pant_length: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  pant_waist: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  pant_sit: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  pant_thighs: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  pant_knees: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  pant_bottom: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  pant_chain: yup
    .number()
    .positive()
    .required("Mandatory")
    .typeError("Invalid value"),
  pant_back_pocket: yup
    .string()
    .required("Mandatory")
    .typeError("Invalid value"),
  pant_plated_pant: yup
    .string()
    .required("Mandatory")
    .typeError("Invalid value"),
  pant_watch_pocket: yup
    .string()
    .required("Mandatory")
    .typeError("Invalid value"),
});

const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const stepperButtonStyles2 = {
  border: "2px solid black",
  borderRadius: "10px",
  backgroundColor: "white",
  color: "black",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
};

/*Constant Variables End*/

const UserMeasurement = () => {
  /*Standard Variables Start*/
  const {
    currentUser,
    user: { user },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { setAlertMessage, setAlertSeverity, setShowAlert } = MessageAlert();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [selectUserMeasurement, setSelectUserMeasurement] = useState("");
  const [showMeasurementDialog, setShowMeasurementDialog] = useState(false);
  const [measurementEditMode, setMeasurementEditMode] = useState(false);
  const [newMeasurement, setNewMeasurement] = useState(false);
  const [measurementValues, setMeasurementValues] = useState({
    name: "",
    shirt_length: "",
    shirt_chest: "",
    shirt_shoulders: "",
    shirt_sleeves: "",
    shirt_muscles: "",
    shirt_stomach: "",
    shirt_sit: "",
    shirt_collor: "",
    shirt_cuff: "",
    shirt_cuff_button: "",
    shirt_bottom: "",
    pant_length: "",
    pant_waist: "",
    pant_sit: "",
    pant_thighs: "",
    pant_knees: "",
    pant_bottom: "",
    pant_chain: "",
    pant_back_pocket: "",
    pant_plated_pant: "",
    pant_watch_pocket: "",
    message: "",
  });
  /*Local Variables End*/

  /*Functions Start*/
  const addMeasurements = async (values) => {
    if (currentUser.currentUser) {
      const docRef = doc(db, "users", user.mobile);

      if (!newMeasurement) {
        const aMeasurements = cloneDeep(user.measurements);

        aMeasurements.map((oMeas, iIndex) => {
          if (iIndex === selectUserMeasurement.index) {
            oMeas = Object.assign(oMeas, values);
          }
          return oMeas;
        });
        await updateDoc(docRef, {
          measurements: aMeasurements,
        });
        setMeasurementEditMode(false);
      } else {
        const iIndex = user.measurements.findIndex(
          (oMeas) => oMeas.name === values.name
        );

        if (iIndex > -1) {
          setAlertMessage("Name already exist!");
          setAlertSeverity("error");
          setShowAlert(true);
          return;
        }
        await updateDoc(docRef, {
          measurements: arrayUnion(values),
        });
      }

      const id = searchParams.get("id");
      dispatch(
        fetchUser({
          mobile: "+" + id.trim(),
        })
      );
      onCloseMeasurementDialog();
    }
  };

  const onCloseMeasurementDialog = () => {
    setShowMeasurementDialog(false);
    setMeasurementEditMode(false);
    setNewMeasurement(false);
    setMeasurementValues({
      name: "",
      shirt_length: "",
      shirt_chest: "",
      shirt_shoulders: "",
      shirt_sleeves: "",
      shirt_muscles: "",
      shirt_stomach: "",
      shirt_sit: "",
      shirt_collor: "",
      shirt_cuff: "",
      shirt_cuff_button: "",
      shirt_bottom: "",
      pant_length: "",
      pant_waist: "",
      pant_sit: "",
      pant_thighs: "",
      pant_knees: "",
      pant_bottom: "",
      pant_chain: "",
      pant_back_pocket: "",
      pant_plated_pant: "",
      pant_watch_pocket: "",
      message: "",
    });
  };
  /*Functions End*/

  /*UI Start*/
  const MeasurementControl = () => (
    <Dialog
      fullScreen
      open={showMeasurementDialog}
      onClose={onCloseMeasurementDialog}
    >
      <DialogTitle>{`${measurementValues.name} Measurements`} </DialogTitle>
      <DialogContent style={{ paddingTop: "10px" }}>
        <DialogContentText>
          <Formik
            initialValues={measurementValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {({ values, validateForm, setTouched }) => {
              return (
                <Form
                  id="measurementsForm"
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    validateForm(values).then((error) => {
                      if (Object.keys(error).length > 0) {
                        // show all errors
                        setTouched(setNestedObjectValues(error, true));
                      } else {
                        addMeasurements(values);
                      }
                    });
                  }}
                >
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="start"
                    sx={{ gap: { xs: "10px 25px", xl: "15px 87px" } }}
                  >
                    {newMeasurement && (
                      <Field
                        component={TextField}
                        name="name"
                        id="name"
                        label="NAME"
                      />
                    )}

                    <Field
                      sx={{
                        visibility: "hidden",
                        height: { xs: "0px" },
                      }}
                      component={TextField}
                      name="name"
                      id="name"
                      label="NAME"
                    />

                    <Typography
                      sx={{
                        minWidth: "5000px",
                        fontWeight: theme.fontWeight.semiBold,
                        fontSize: "18px",
                      }}
                    >
                      SHIRT DETAILS
                    </Typography>
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="shirt_length"
                      id="shirt_length"
                      label="LENGTH"
                    />
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="shirt_chest"
                      id="shirt_chest"
                      label="CHEST"
                    />
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="shirt_shoulders"
                      id="shirt_shoulders"
                      label="SHOULDERS"
                    />
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="shirt_sleeves"
                      id="shirt_sleeves"
                      label="SLEEVES"
                    />
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="shirt_muscles"
                      id="shirt_muscles"
                      label="MUSCLES"
                    />
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="shirt_stomach"
                      id="shirt_stomach"
                      label="STOMACH"
                    />
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="shirt_sit"
                      id="shirt_sit"
                      label="SIT"
                    />
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="shirt_collor"
                      id="shirt_collor"
                      label="COLLAR"
                    />
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="shirt_cuff"
                      id="shirt_cuff"
                      label="CUFF"
                    />
                    <Field
                      disabled={!measurementEditMode}
                      component={Select}
                      name="shirt_cuff_button"
                      id="shirt_cuff_button"
                      label="CUFF BUTTON"
                      sx={{
                        width: "215px",
                      }}
                    >
                      <MenuItem value="Single Button">Single Button</MenuItem>
                      <MenuItem value="Double Button">Double Button</MenuItem>
                    </Field>
                    <Field
                      disabled={!measurementEditMode}
                      component={Select}
                      name="shirt_bottom"
                      id="shirt_bottom"
                      label="BOTTOM"
                      sx={{
                        width: "215px",
                      }}
                    >
                      <MenuItem value="Apple Cut">Apple Cut</MenuItem>
                      <MenuItem value="Straight Cut">Straight Cut</MenuItem>
                      <MenuItem value="Side Cut">Side Cut</MenuItem>
                    </Field>

                    <Typography
                      sx={{
                        minWidth: "5000px",
                        fontWeight: theme.fontWeight.semiBold,
                        fontSize: "18px",
                      }}
                    >
                      PANT DETAILS
                    </Typography>

                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="pant_length"
                      id="pant_length"
                      label="LENGTH"
                    />
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="pant_waist"
                      id="pant_waist"
                      label="WAIST"
                    />
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="pant_sit"
                      id="pant_sit"
                      label="SIT"
                    />
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="pant_thighs"
                      id="pant_thighs"
                      label="THIGHS"
                    />
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="pant_knees"
                      id="pant_knees"
                      label="KNEES"
                    />
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="pant_bottom"
                      id="pant_bottom"
                      label="BOTTOM"
                    />
                    <Field
                      type="number"
                      disabled={!measurementEditMode}
                      component={TextField}
                      name="pant_chain"
                      id="pant_chain"
                      label="U CHAIN"
                    />
                    <Field
                      disabled={!measurementEditMode}
                      component={Select}
                      name="pant_back_pocket"
                      id="pant_back_pocket"
                      label="BACK POCKET"
                      sx={{
                        width: "215px",
                      }}
                    >
                      <MenuItem value="0">0</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                    </Field>

                    <Field
                      disabled={!measurementEditMode}
                      component={Select}
                      name="pant_plated_pant"
                      id="pant_plated_pant"
                      label="PLATED"
                      sx={{
                        width: "215px",
                      }}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Field>

                    <Field
                      disabled={!measurementEditMode}
                      component={Select}
                      name="pant_watch_pocket"
                      id="pant_watch_pocket"
                      label="WATCH POCKET"
                      sx={{
                        width: "215px",
                      }}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Field>

                    <Typography
                      sx={{
                        minWidth: "5000px",
                        fontWeight: theme.fontWeight.semiBold,
                        fontSize: "18px",
                      }}
                    >
                      ADDITIONAL DETAILS
                    </Typography>

                    <Field
                      disabled={!measurementEditMode}
                      sx={{
                        width: "100%",
                      }}
                      multiline
                      rows={4}
                      component={TextField}
                      name="message"
                      id="MESSAGE"
                    />
                  </Stack>

                  {!!measurementEditMode &&
                    currentUser?.currentUser?.access?.user_edit_btn && (
                      <Button
                        type="submit"
                        style={{
                          marginTop: "20px",
                          ...stepperButtonStyles,
                        }}
                      >
                        {newMeasurement ? "ADD" : "UPDATE"}
                      </Button>
                    )}
                  <Button
                    sx={{
                      marginTop: "20px",
                      marginLeft: measurementEditMode ? "20px" : "0px",
                      ...stepperButtonStyles2,
                    }}
                    onClick={onCloseMeasurementDialog}
                  >
                    CLOSE
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
  /*UI End*/
  return {
    MeasurementControl,
    setMeasurementValues,
    setShowMeasurementDialog,
    setSelectUserMeasurement,
    setMeasurementEditMode,
    setNewMeasurement,
    selectUserMeasurement
  };
};

export default UserMeasurement;
