/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box } from "@mui/material";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import theme from "../../theme";
import { useSelector } from "react-redux";

export default function OrderDeliveryDate({
  dateClickable = false,
  dateClickedCallback = null,
  eventClickable = false,
  disablePast = false,
  showOnlyNextOneMonth = false,
}) {
  const {
    orders: { orders },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (orders) {
      let aEvents = [];
      orders.map((oOrder) => {
        if (
          oOrder?.delivery_date &&
          oOrder.order_status[2].created_on === "" &&
          oOrder.order_status[3].created_on === ""
        ) {
          aEvents.push({
            title: oOrder.order_id,
            start: moment(oOrder.delivery_date).format("YYYY-MM-DD"),
            stitch_only: oOrder.stitch_only,
          });
        }
      });

      if (aEvents.length > 0) {
        let aSortedDates = aEvents.sort(function (a, b) {
          return Date.parse(new Date(a.start)) - Date.parse(new Date(b.start));
        });

        setEndDate(aSortedDates[aSortedDates.length - 1].start);
        if (showOnlyNextOneMonth) {
          setStartDate(new Date());
        } else {
          setStartDate(aSortedDates[0].start);
        }
      }
      setEvents(aEvents);
    }
  }, [orders]);

  return (
    <Box>
      <Typography
        textAlign="center"
        fontSize="30px"
        fontWeight={theme.fontWeight.semiBold}
      >
        ORDER DELIVERY DATE
      </Typography>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        dateClick={function (info) {
          if (dateClickable) {
            if (dateClickedCallback) {
              dateClickedCallback(new Date(info.dateStr));
            }
          }
        }}
        eventClick={function (info) {
          if (eventClickable) {
            if (info.event.extendedProps.stitch_only) {
              navigate({
                pathname: "/order-stitch-detail",
                search: `?id=${info.event.title}`,
              });
            } else {
              navigate({
                pathname: "/order-detail",
                search: `?id=${info.event.title}`,
              });
            }
          }
        }}
        initialView="dayGridMonth"
        validRange={function (nowDate) {
          if (events.length === 0) return;

          let oRange = {};
          if (showOnlyNextOneMonth) {
            let oEndDate = new Date();
            oEndDate.setMonth(nowDate.getMonth() + 1);
            oRange.end = oEndDate;
          } else {
            let oEndDate = new Date(endDate);
            // oEndDate.setDate(oEndDate.getDate() + 1);
            oRange.end = oEndDate;
          }

          if (!disablePast) {
            oRange.start = startDate;
          }
          return oRange;
        }}
        weekends={true}
        events={events}
        eventContent={(eventInfo) => {
          return (
            <Box
              sx={{
                backgroundColor: "black",
                alignItems: "center",
              }}
            >
              <Typography textAlign="center">
                {eventInfo.event.title}
              </Typography>
            </Box>
          );
        }}
      />
    </Box>
  );
}
