/* eslint-disable array-callback-return */
/*Standard Library Start*/
import ReactDOMServer from "react-dom/server";
import { Box, Grid } from "@mui/material";
/*Standard Library End*/

/*Local Library Start*/
import suiting from "../../constants/suiting.json";
/*Local Library End*/

const OrderPrint = () => {
  /*Function Start*/
  const getShirtDetails = (product) => {
    return (
      <>
        <p
          style={{
            marginLeft: "5px",
            fontSize: "10px",
          }}
        >
          {product.product_detail.collor}
        </p>

        <p
          style={{
            marginLeft: "5px",
            fontSize: "10px",
          }}
        >
          {product.product_detail.pocket}
        </p>
        <p
          style={{
            marginLeft: "5px",
            fontSize: "10px",
          }}
        >
          {product.product_detail.sleeve}
        </p>
        <p
          style={{
            marginLeft: "5px",
            fontSize: "10px",
          }}
        >
          {product.quantity} meters
        </p>
      </>
    );
  };

  const getSutingDetails = (product) => {
    let oFoundSuiting = null;

    suiting.some((oSuiting) => {
      if (oSuiting.category === product.product_detail.suiting_type) {
        oFoundSuiting = oSuiting;
        return true;
      }
      return false;
    });

    return (
      <>
        <p
          style={{
            marginLeft: "5px",
            fontSize: "10px",
          }}
        >
          {oFoundSuiting.label}
        </p>
        <p
          style={{
            marginLeft: "5px",
            fontSize: "10px",
          }}
        >
          {product.quantity} meters
        </p>
      </>
    );
  };

  const drawTable = (oUser) => {
    return (
      <table cellspacing="1" rules="all" border="1">
        <tr>
          <th
            style={{
              fontSize: "10px",
            }}
            width="200px"
          >
            Product Code
          </th>
          <th
            style={{
              fontSize: "10px",
            }}
            width="300px"
          >
            Measurements
          </th>
          <th
            style={{
              fontSize: "10px",
            }}
            width="200px"
          >
            Addtional Details
          </th>
        </tr>
        {["shirt", "pant"].map((sCategory) => (
          <tr>
            <td textAlign="center">
              {sCategory === "shirt" &&
                oUser?.shirt?.map((oProduct, iIndex) => (
                  <Box
                    sx={{
                      marginBottom: "40px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      {oProduct.product_code}
                    </p>
                    {getShirtDetails(oProduct)}

                    {iIndex + 1 !== oUser.shirt.length && <hr />}
                  </Box>
                ))}

              {sCategory === "pant" &&
                oUser?.pant?.map((oProduct, iIndex) => (
                  <>
                    <p
                      style={{
                        fontSize: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      {oProduct.product_code}
                    </p>
                    {getSutingDetails(oProduct)}

                    {iIndex + 1 !== oUser.pant.length && <hr />}
                  </>
                ))}
            </td>

            <td>
              {sCategory === "shirt" && (
                <Grid item xs={2} sm={4} md={4} key={2000}>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Length: &nbsp; {oUser.shirt_length}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Chest: &nbsp;{oUser.shirt_chest}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Shoulder: &nbsp;{oUser.shirt_shoulders}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Sleeves: &nbsp;{oUser.shirt_sleeves}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Muscles: &nbsp;{oUser.shirt_muscles}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Stomach: &nbsp;{oUser.shirt_stomach}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Sit: &nbsp;{oUser.shirt_sit}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Collor: &nbsp;{oUser.shirt_collor}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Cuff: &nbsp;{oUser.shirt_cuff}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Cuff Buton: &nbsp;{oUser.shirt_cuff_button}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Buttom: &nbsp;{oUser.shirt_bottom}
                  </p>
                </Grid>
              )}
              {sCategory === "pant" && (
                <Grid item xs={2} sm={4} md={4} key={2000}>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Length: &nbsp;{oUser.pant_length}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    Waist: &nbsp;{oUser.pant_waist}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Sit: &nbsp;{oUser.pant_sit}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Thighs: &nbsp;{oUser.pant_thighs}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Knees: &nbsp;{oUser.pant_knees}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Bttom: &nbsp;{oUser.pant_bottom}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    U Chain: &nbsp;{oUser.pant_chain}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Back Pocket: &nbsp;{oUser.pant_back_pocket}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Plated: &nbsp;{oUser.pant_plated_pant}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    Watch Pocket Buton: &nbsp;
                    {oUser.pant_watch_pocket}
                  </p>
                </Grid>
              )}
            </td>
            <td>
              {" "}
              <p
                style={{
                  fontSize: "10px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  marginLeft: "5px",
                }}
              >
                {oUser.message}
              </p>
            </td>
          </tr>
        ))}
      </table>
    );
  };

  const onPrintButtonPressed = (oOrder, getLabelDate) => {
    var mywindow = window.open("", "PRINT", "");

    let aUsers = [];
    oOrder.products.map((oProduct) => {
      if (oProduct?.measurements?.name) {
        let found = -1;
        if (aUsers.length > 0) {
          found = aUsers.findIndex(
            (oUser) => oUser.name === oProduct.measurements.name
          );
        }

        if (found === -1) {
          if (oProduct.category === "shirt") {
            aUsers.push({
              shirt: [oProduct],
              ...oProduct.measurements,
            });
          } else {
            aUsers.push({
              pant: [oProduct],
              ...oProduct.measurements,
            });
          }
        } else {
          if (oProduct.category === "shirt") {
            if (aUsers[found].shirt) {
              aUsers[found].shirt.push(oProduct);
            } else {
              aUsers[found] = {
                ...aUsers[found],
                ...oProduct.measurements,
              };
              aUsers[found].shirt = [oProduct];
            }
          } else {
            if (aUsers[found].pant) {
              aUsers[found].pant.push(oProduct);
            } else {
              aUsers[found] = {
                ...aUsers[found],
                ...oProduct.measurements,
              };
              aUsers[found].pant = [oProduct];
            }
          }
        }
      }
    });

    aUsers.map((oUser, iIndex) => {
      mywindow.document.write("<html><head><title>Keimple</title>");
      mywindow.document.write("</head><body >");
      mywindow.document.write(
        "<p style='margin-top: 5px;margin-bottom: 5px;font-size: 10px;'> Keimple Order # " +
          oOrder.order_id +
          "</p>"
      );
      mywindow.document.write(
        "<p style='margin-top: 5px;margin-bottom: 5px;font-size: 10px;'>Order Date: " +
          getLabelDate("Order Placed", oOrder.order_status) +
          "</p>"
      );
      mywindow.document.write(
        "<p style='margin-top: 0px;margin-bottom: 5px;font-size: 10px;'>Name: " +
          oUser.name +
          "</p>"
      );
      mywindow.document.write(ReactDOMServer.renderToString(drawTable(oUser)));

      if (iIndex !== aUsers.length) {
        mywindow.document.write("<div style='break-after:page'> </div>");
      }
    });
    mywindow.document.write("</body></html>");

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();
  };
  /*Function End*/

  return {
    onPrintButtonPressed,
  };
};

export default OrderPrint;
