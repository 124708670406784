/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
/*Standard Library End*/

/*Local Library Start*/
import { Login } from "../../components";
/*Local Library End*/

const Auth = () => {
  /*Standard Variables Start*/
  const navigate = useNavigate();
  const { currentUser, loading: currentUserLoading } = useSelector(
    (state) => state.currentUser
  );
  /*Standard Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (!currentUserLoading && !!currentUser) {
      navigate({ pathname: "/" });
    }
  }, [currentUser, currentUserLoading]);
  /*OnLoad Functions End*/

  return (
    <Box sx={{ marginTop: "15px" }}>
      <Login />
    </Box>
  );
};

export default Auth;
