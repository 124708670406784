/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  Stack,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { db } from "../../firebase";
import {
  writeBatch,
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { Formik, Form, Field, setNestedObjectValues } from "formik";
import { TextField } from "formik-mui";
import * as yup from "yup";
import cloneDeep from "lodash/cloneDeep";
import { CSVLink } from "react-csv";
/*Standard Library End*/

/*Local Library Start*/
import { fetchUsers } from "../../features/users/usersSlice";
import { useAppDispatch } from "../../app/store";
import { setUserLoading } from "../../features/user/userSlice";
import { SearchBar, MessageAlert } from "../../components";
import theme from "../../theme";
/*Local Library End*/

/*Constant Variables Start*/
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  first_name: yup
    .string()
    .trim()
    .matches(/^[\p{L}\p{M}-]+$/u, "First name cannot contain a number")
    .min(2, "First name should be of minimum 2 characters length")
    .required("First name is required"),
  phone: yup
    .string()
    .required("Mobile number is required")
    .matches(phoneRegExp, "Mobile number is not valid")
    .min(10, "Mobile number is too short")
    .max(10, "Mobile number is too long"),
});

const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const stepperButtonStyles2 = {
  border: "2px solid black",
  borderRadius: "10px",
  backgroundColor: "white",
  color: "black",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
};

const aExcelHeaders = [
  { label: "Name", key: "name" },
  { label: "Mobile", key: "mobile" },
];
/*Constant Variables End*/

const Users = ({ setActiveStep, setSelectedUser, selectedUser }) => {
  let columns = [];

  if (setSelectedUser) {
    columns = [
      {
        id: "action",
        label: "",
        minWidth: 50,
      },
      { id: "mobile", label: "MOBILE", minWidth: 100 },
      { id: "account_name", label: "NAME", minWidth: 170 },
      { id: "orderCount", label: "ORDER COUNT", minWidth: 100 },
      { id: "orderValue", label: "ORDER VALUE", minWidth: 120 },
    ];
  } else {
    columns = [
      { id: "mobile", label: "MOBILE", minWidth: 100 },
      { id: "account_name", label: "NAME", minWidth: 170 },
      { id: "email", label: "EMAIL", minWidth: 200 },
      { id: "orderCount", label: "ORDER COUNT", minWidth: 100 },
      { id: "orderValue", label: "ORDER VALUE", minWidth: 120 },
      {
        id: "created_on",
        label: "CREATION DATE",
        minWidth: 100,
      },
    ];
  }

  /*Standard Variables Start*/
  const {
    currentUser,
    users: { users },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { AlertControl, setAlertMessage, setAlertSeverity, setShowAlert } =
    MessageAlert();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [rows, setRows] = useState([]);
  const [excelRows, setExcelRows] = useState([]);
  const [searched, setSearched] = useState("");
  const [newUserDialog, setNewUserDialog] = useState(false);
  const [initialFormValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (currentUser?.currentUser?.access?.users) {
      dispatch(fetchUsers({}));
    }
  }, [currentUser?.currentUser?.access?.users]);

  useEffect(() => {
    if (users) {
      setRows(users);

      let aUsers = [];
      users.map((oUser) => {
        aUsers.push({
          name: oUser.account_name,
          mobile: oUser.mobile,
        });
      });
      setExcelRows(aUsers);
    }
  }, [users]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  const handleUserDialogClose = () => {
    setNewUserDialog(false);
  };

  const checkEmailExists = async (email) => {
    const ordersRef = collection(db, "users");
    const q = query(ordersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    let bFound = [];
    querySnapshot.forEach((doc) => {
      bFound.push(doc.data());
    });
    return bFound.length > 0;
  };

  const checkUserExistInDB = async (userId) => {
    const docRef = doc(db, "users", userId);
    const user = await getDoc(docRef);
    return user.exists();
  };

  const handleSubmitForm = async (values) => {
    if (currentUser.currentUser) {
      dispatch(setUserLoading(true));

      const mobile = "+91" + values.phone;
      const isUserExistInDB = await checkUserExistInDB(mobile);

      if (isUserExistInDB) {
        setAlertMessage(
          "Mobile number is already registered. Please choose a different mobile."
        );
        setAlertSeverity("error");
        setShowAlert(true);
        dispatch(setUserLoading(false));
        return;
      }

      if (values.email) {
        const emailExist = await checkEmailExists(values.email);

        if (emailExist) {
          setAlertMessage(
            "Email is already associated with different account. Please choose a different email."
          );
          setAlertSeverity("error");
          setShowAlert(true);
          dispatch(setUserLoading(false));
          return;
        }
      }

      let sAccountName = "";
      if (values?.first_name) {
        sAccountName = values.first_name.trim();
      }
      if (values?.last_name) {
        sAccountName = sAccountName + " " + values.last_name.trim();
      }

      const userData = {
        mobile: mobile,
        email: values.email,
        favoriteProductIds: [],
        user_products_in_cart: [],
        created_on: new Date().toString(),
        measurements: [],
        last_modified: new Date().toString(),
        addresses: [],
        account_name: sAccountName,
      };
      await setDoc(doc(db, "users", mobile), userData);

      if (setSelectedUser) {
        userData.mobile = values.phone + "";
        setSelectedUser(userData);

        if (setActiveStep) {
          setActiveStep((previousState) => previousState + 1);
        }
      }
      handleUserDialogClose();
      dispatch(fetchUsers({}));
      setAlertMessage("User added successfully");
      setAlertSeverity("success");
      setShowAlert(true);
      dispatch(setUserLoading(false));
    }
  };

  const updateUsers = async () => {
    let aUserData = users;
    if (aUserData.length > 0) {
      const batch = writeBatch(db);
      aUserData.map((oUser) => {
        const sfRef = doc(db, "users", "+91" + oUser.mobile);
        let aAddress = [];

        if (!oUser.addresses) {
          return;
        }
        oUser.addresses.map((oAddress) => {
          let oCloneAddress = cloneDeep(oAddress);
          if (oAddress.mobile.length === 10) {
            oCloneAddress.mobile = "+91" + oCloneAddress.mobile;
          }
          aAddress.push(oCloneAddress);
        });
        batch.update(sfRef, {
          favoriteProductIds: [],
          user_products_in_cart: [],
          addresses: aAddress,
        });
      });
      await batch.commit();
      alert("Update complete!");
    }
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = users.filter((row) => {
      return (
        row.mobile.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.created_on.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row?.account_name?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.email.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getUnit = (id, value) => {
    if (id === "orderValue") {
      return "₹" + parseFloat(value).toFixed(2);
    }
    return value;
  };
  /*Functions End*/

  /*UI Start*/
  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {!setActiveStep && (
          <Button
            sx={{ marginBottom: "20px", ...stepperButtonStyles }}
            onClick={() => {
              navigate("/");
            }}
            startIcon={<ArrowBackIcon />}
          >
            BACK
          </Button>
        )}

        <Box>
          {" "}
          {!setActiveStep && excelRows?.length > 0 && (
            <CSVLink
              filename={"Users.csv"}
              data={excelRows}
              headers={aExcelHeaders}
            >
              {" "}
              <Button sx={{ marginBottom: "20px", ...stepperButtonStyles }}>
                DOWNLOAD EXCEL
              </Button>
            </CSVLink>
          )}
          <Button
            sx={{
              marginBottom: "20px",
              marginLeft: setSelectedUser ? "0px" : "20px",
              ...stepperButtonStyles,
            }}
            onClick={() => {
              setNewUserDialog(true);
            }}
            startIcon={<AddIcon />}
          >
            ADD USER
          </Button>
        </Box>
      </Box>

      <Button
        sx={{
          display: "none",
          marginBottom: "20px",
          borderRadius: "10px",
          backgroundColor: "black",
          color: "white",
          fontSize: "18px",
          fontWeight: theme.fontWeight.semiBold,
          textAlign: "center",
          height: "49px",
          width: "200px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "black",
          },
        }}
        onClick={updateUsers}
      >
        UPDATE USER'S
      </Button>

      <SearchBar
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        setSearched={setSearched}
      />
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer
          sx={{
            height: setActiveStep ? "300px" : "100%",
            maxHeight: 500,
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const sSearch = `?id=+91${row.mobile}`;
                    return (
                      <TableRow
                        onClick={() => {
                          if (setActiveStep) {
                            return;
                          }
                          navigate({
                            pathname: "/user-detail",
                            search: sSearch,
                          });
                        }}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.mobile}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.id === "action" && setSelectedUser) {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {selectedUser?.mobile === row.mobile ? (
                                  <Button
                                    sx={{
                                      borderRadius: "10px",
                                      backgroundColor: "black",
                                      color: "white",
                                      fontSize: "18px",
                                      fontWeight: theme.fontWeight.semiBold,
                                      textAlign: "center",
                                      height: "49px",
                                      width: "100px",
                                      textTransform: "none",
                                      "&:hover": {
                                        backgroundColor: "black",
                                      },
                                    }}
                                    onClick={(event) => {
                                      if (selectedUser?.mobile === row.mobile) {
                                        setSelectedUser("");
                                      } else {
                                        setSelectedUser(row);
                                        setActiveStep(
                                          (previousState) => previousState + 1
                                        );
                                      }
                                    }}
                                  >
                                    SELECTED
                                  </Button>
                                ) : (
                                  <Button
                                    sx={{
                                      borderRadius: "10px",
                                      border: "2px solid black",
                                      backgroundColor: "white",
                                      color: "black",
                                      fontSize: "18px",
                                      fontWeight: theme.fontWeight.semiBold,
                                      textAlign: "center",
                                      height: "49px",
                                      width: "100px",
                                      textTransform: "none",
                                      "&:hover": {
                                        backgroundColor: "black",
                                        color: "white",
                                      },
                                    }}
                                    onClick={(event) => {
                                      setSelectedUser(row);
                                      setActiveStep(
                                        (previousState) => previousState + 1
                                      );
                                    }}
                                  >
                                    SELECT
                                  </Button>
                                )}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                style={{
                                  fontSize: "18px",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {getUnit(column.id, value)}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={users ? users.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {selectedUser && (
        <Stack
          rowGap="10px"
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Button
            sx={{
              borderRadius: "10px",
              backgroundColor: "black",
              color: "white",
              fontSize: "18px",
              fontWeight: theme.fontWeight.semiBold,
              textAlign: "center",
              height: "49px",
              width: "203px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "black",
              },
              marginTop: "20px",
            }}
            onClick={(event) => {
              setActiveStep((previousState) => previousState + 1);
            }}
          >
            NEXT
          </Button>
        </Stack>
      )}
      <Dialog fullScreen open={newUserDialog} onClose={handleUserDialogClose}>
        <DialogTitle>NEW USER</DialogTitle>
        <DialogContent style={{ paddingTop: "10px" }}>
          <DialogContentText>
            <Formik
              initialValues={initialFormValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {({ values, validateForm, setTouched }) => {
                return (
                  <Form
                    id="measurementsForm"
                    noValidate
                    onSubmit={(e) => {
                      e.preventDefault();
                      validateForm(values).then((error) => {
                        if (Object.keys(error).length > 0) {
                          // show all errors
                          setTouched(setNestedObjectValues(error, true));
                        } else {
                          handleSubmitForm(values);
                        }
                      });
                    }}
                  >
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      justifyContent="start"
                      sx={{ gap: { xs: "10px 25px", xl: "15px 87px" } }}
                    >
                      <Field
                        className="formLabel"
                        component={TextField}
                        name="first_name"
                        id="first_name"
                        label="FIRST NAME"
                      />

                      <Field
                        className="formLabel"
                        component={TextField}
                        name="last_name"
                        id="last_name"
                        label="LAST NAME"
                      />

                      <Field
                        type="number"
                        className="formLabel"
                        component={TextField}
                        name="phone"
                        id="phone"
                        label="MOBILE NUMBER (+91)"
                      />
                      <Field
                        type="text"
                        className="formLabel"
                        component={TextField}
                        name="email"
                        id="email"
                        label="EMAIL"
                      />
                    </Stack>

                    <Button
                      type="submit"
                      style={{
                        marginTop: "20px",
                        marginRight: "20px",
                        ...stepperButtonStyles,
                      }}
                    >
                      SAVE
                    </Button>
                    <Button
                      style={{ marginTop: "20px", ...stepperButtonStyles2 }}
                      onClick={handleUserDialogClose}
                    >
                      CANCEL
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <AlertControl />
    </Box>
  );
};
/*UI End*/

export default Users;
