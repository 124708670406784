/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Formik, Form, Field, setNestedObjectValues } from "formik";
import { TextField } from "formik-mui";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
/*Standard Library End*/

/*Local Library Start*/
import { db } from "../../firebase";
import { SearchBar, MessageAlert, ConfirmationDialog } from "../../components";
import { setUserLoading } from "../../features/user/userSlice";
import theme from "../../theme";
import { fetchCoupons } from "../../features/coupons/couponsSlice";
import { useAppDispatch } from "../../app/store";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const stepperButtonStyles2 = {
  border: "2px solid black",
  borderRadius: "10px",
  backgroundColor: "white",
  color: "black",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
};

const validationSchema = yup.object({
  code: yup.string().required("Mandatory"),
  min_order: yup.number().positive().required("Mandatory"),
  count: yup.number().required("Mandatory"),
  discount: yup.number().positive().required("Mandatory"),
  expiry_date: yup.string().required("Mandatory"),
});

const columns = [
  { id: "code", label: "CODE" },
  { id: "discount", label: "DISCOUNT (%)" },
  {
    id: "min_order",
    label: "MIN. ORDER VALUE",
  },
  { id: "count", label: "MAX. USERS" },
  { id: "expiry_date", label: "EXPIRY DATE" },
  { id: "last_modified", label: "LAST MODIFIIED" },
  { id: "action", label: "" },
];
/*Constant Variables End*/

const Coupons = () => {
  /*Standard Variables Start*/
  const {
    currentUser,
    coupons: { coupons },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { AlertControl, setAlertMessage, setAlertSeverity, setShowAlert } =
    MessageAlert();
  const {
    ConfirmationDialogControl,
    setShowConfirmationDialog,
    setConfirmationDialogMessage,
    setAcceptButtonText,
    setRejectButtonText,
  } = ConfirmationDialog();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [rows, setRows] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [couponDialog, setCouponDialog] = useState(false);
  const [searched, setSearched] = useState("");
  const [dateError, setDateError] = useState("");
  const [initialFormValues, setInitialFormValues] = useState({
    code: "",
    min_order: 0,
    discount: 0,
    count: 0,
    expiry_date: "",
  });
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (coupons) {
      setRows(coupons);
    }
  }, [coupons]);

  useEffect(() => {
    if (currentUser?.currentUser?.access?.coupons) {
      dispatch(fetchCoupons({}));
    }
  }, [currentUser?.currentUser?.access?.coupons]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  const onAfterConfirmationDialogClose = () => {
    setInitialFormValues({
      code: "",
      min_order: 0,
      discount: 0,
      count: 0,
      expiry_date: "",
    });
  };

  const handleCouponDialogClose = () => {
    setCouponDialog(false);
    setEditMode(false);
    setInitialFormValues({
      code: "",
      min_order: 0,
      discount: 0,
      count: 0,
      expiry_date: "",
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = coupons.filter((row) => {
      return (
        row.code.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.min_order
          .toString()
          .toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        row.discount
          .toString()
          .toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        row.last_modified
          .toString()
          .toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        row.count.toString().toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  const checkCouponExists = async (code) => {
    const ordersRef = collection(db, "coupons");
    const q = query(ordersRef, where("code", "==", code));
    const querySnapshot = await getDocs(q);

    let bFound = false;
    querySnapshot.forEach(() => {
      bFound = true;
    });
    return bFound;
  };

  const handleSubmitForm = async (values) => {
    if (currentUser.currentUser) {
      dispatch(setUserLoading(true));
      if (!editMode && values.code) {
        const codeExist = await checkCouponExists(values.code);

        if (codeExist) {
          setAlertMessage("Coupon code already exist");
          setAlertSeverity("error");
          setShowAlert(true);
          dispatch(setUserLoading(false));
          return;
        }
      }

      if (editMode) {
        const docRef = doc(db, "coupons", values.code);
        updateDoc(docRef, {
          last_modified: new Date().toString(),
          expiry_date: new Date(values.expiry_date).toString(),
          code: values.code,
          min_order: values.min_order,
          discount: values.discount,
          count: values.count,
        })
          .then((result) => {
            setAlertMessage("Coupon details updated successfully");
            setAlertSeverity("success");
            setShowAlert(true);
            handleCouponDialogClose();
            dispatch(fetchCoupons({}));
            dispatch(setUserLoading(false));
          })
          .catch((error) => {
            setAlertMessage(
              "Error while updaing coupon details. Please try again later."
            );
            setAlertSeverity("error");
            setShowAlert(true);
            dispatch(setUserLoading(false));
          });
      } else {
        setDoc(doc(db, "coupons", values.code), {
          last_modified: new Date().toString(),
          expiry_date: new Date(values.expiry_date).toString(),
          code: values.code,
          min_order: values.min_order,
          discount: values.discount,
          count: values.count,
        })
          .then((result) => {
            setAlertMessage("Coupon details added successfully");
            setAlertSeverity("success");
            setShowAlert(true);
            handleCouponDialogClose();
            dispatch(fetchCoupons({}));
            dispatch(setUserLoading(false));
          })
          .catch((error) => {
            setAlertMessage(
              "Error while adding coupon details. Please try again later."
            );
            setAlertSeverity("error");
            setShowAlert(true);
            dispatch(setUserLoading(false));
          });
      }
    }
  };

  const onDeleteCoupon = () => {
    if (currentUser.currentUser && initialFormValues.code) {
      deleteDoc(doc(db, "coupons", initialFormValues.code))
        .then(() => {
          setAlertMessage("Coupon deleted successfully");
          setAlertSeverity("success");
          setShowAlert(true);
          dispatch(fetchCoupons({}));
          dispatch(setUserLoading(false));
        })
        .catch((error) => {
          setAlertMessage(
            "Error while deleting coupon details. Please try again later."
          );
          setAlertSeverity("error");
          setShowAlert(true);
          dispatch(setUserLoading(false));
        });
    }
  };

  const getUnit = (id, value) => {
    if (id === "discount") {
      return value + "%";
    }
    if (id === "min_order") {
      return "₹" + value;
    }
    return value;
  };
  /*Functions End*/

  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <Button
        sx={{ marginBottom: "20px", ...stepperButtonStyles }}
        onClick={() => {
          navigate("/");
        }}
        startIcon={<ArrowBackIcon />}
      >
        BACK
      </Button>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          setSearched={setSearched}
        />
        {currentUser?.currentUser?.access?.coupons &&
          currentUser?.currentUser?.access?.coupon_edit_btn && (
            <Button
              sx={{
                width: "10%",
                marginBottom: "20px",
                marginLeft: "20px",
                ...stepperButtonStyles,
              }}
              onClick={() => {
                setCouponDialog(true);
                setDateError("");
              }}
            >
              ADD COUPON
            </Button>
          )}
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <>
                              {column.id === "action" &&
                              currentUser?.currentUser?.access?.coupons &&
                              currentUser?.currentUser?.access
                                ?.coupon_edit_btn ? (
                                <TableCell key={column.id} align={column.align}>
                                  <EditIcon
                                    sx={{ color: "#000000", cursor: "pointer" }}
                                    onClick={() => {
                                      setCouponDialog(true);
                                      setInitialFormValues(row);
                                      setEditMode(true);
                                    }}
                                  />
                                  <DeleteIcon
                                    sx={{
                                      marginLeft: "30px",
                                      color: "#000000",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setShowConfirmationDialog(true);
                                      setConfirmationDialogMessage(
                                        `DELETE COUPON CODE '${row.code}'?`
                                      );
                                      setAcceptButtonText("DELETE");
                                      setRejectButtonText("CANCEL");
                                      setInitialFormValues(row);
                                    }}
                                  />
                                </TableCell>
                              ) : (
                                <TableCell
                                  style={{
                                    fontSize: "18px",
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  {getUnit(column.id, value)}
                                </TableCell>
                              )}
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={coupons ? coupons.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog open={couponDialog} onClose={handleCouponDialogClose}>
        <DialogTitle>{editMode ? "EDIT COUPON" : "ADD COUPON"}</DialogTitle>
        <DialogContent style={{ paddingTop: "10px" }}>
          <DialogContentText>
            <Formik
              initialValues={initialFormValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue, validateForm, setTouched }) => {
                return (
                  <Form
                    id="userForm"
                    noValidate
                    onSubmit={(e) => {
                      e.preventDefault();
                      validateForm(values).then((error) => {
                        const aKeys = Object.keys(error);
                        if (aKeys.length > 0) {
                          // show all errors
                          const iIndex = aKeys.findIndex(
                            (sKey) => sKey === "expiry_date"
                          );

                          if (iIndex > -1) {
                            setDateError("Mandatory");
                          } else {
                            setDateError("");
                          }
                          setTouched(setNestedObjectValues(error, true));
                        } else {
                          handleSubmitForm(values);
                        }
                      });
                    }}
                  >
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      justifyContent="start"
                      sx={{ gap: { xs: "10px 25px", xl: "15px 87px" } }}
                    >
                      <Field
                        disabled={editMode}
                        className="formLabel"
                        component={TextField}
                        name="code"
                        id="code"
                        label="CODE"
                      />
                      <Field
                        type="number"
                        className="formLabel"
                        component={TextField}
                        name="discount"
                        id="discount"
                        label="DISCOUNT (%)"
                      />
                      <Field
                        type="number"
                        className="formLabel"
                        component={TextField}
                        name="min_order"
                        id="min_order"
                        label="MIN. ORDER VALUE (₹)"
                      />

                      <Field
                        type="number"
                        className="formLabel"
                        component={TextField}
                        name="count"
                        id="count"
                        label="MAX. USERS"
                      />

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="EXPIRY DATE"
                          disablePast
                          format="DD/MM/YYYY"
                          onChange={(value) =>
                            setFieldValue("expiry_date", value, true)
                          }
                          value={dayjs(values.expiry_date)}
                          slotProps={{
                            textField: {
                              helperText: dateError ? dateError : "",
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: `${dateError && "#d32f2f"}`,
                            },
                            "& .MuiFormHelperText-root": {
                              color: "#d32f2f",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="formLabel"
                              margin="normal"
                              name="expiry_date"
                              fullWidth
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>
                    <Button
                      type="submit"
                      style={{ marginTop: "20px", ...stepperButtonStyles }}
                    >
                      SAVE
                    </Button>
                    <Button
                      style={{ marginTop: "20px", ...stepperButtonStyles2 }}
                      onClick={handleCouponDialogClose}
                    >
                      CLOSE
                    </Button>{" "}
                  </Form>
                );
              }}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <ConfirmationDialogControl
        onAcceptButtonPress={onDeleteCoupon}
        onAfterClose={onAfterConfirmationDialogClose}
      />
      <AlertControl />
    </Box>
  );
};

export default Coupons;
