/* eslint-disable array-callback-return */

/*Standard Library Start*/
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Delete as DeleteIcon } from "@mui/icons-material";
import cloneDeep from "lodash/cloneDeep";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
} from "@mui/material";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import NewTrialRoom from "../NewTrialRoom";
import suiting from "../../constants/suiting.json";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: "bold",
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};
/*Constant Variables End*/

const ShoppingCart = ({ setActiveStep, order, setOrder }) => {
  /*Local Variables Start*/
  const [showTrialRoom, setShowTrialRoom] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  /*Local Variables End*/

  /*Functions Start*/
  const getTotal = (products) => {
    let total = 0;
    products.map((oProduct) => {
      total = parseFloat(total) + parseFloat(oProduct.price);
    });
    total = +total.toFixed(2);

    return {
      totalCost: total,
      discount: 0,
    };
  };

  const getShirtDetails = (product) => {
    if (!order?.order_id || order?.order_version > 1) {
      return (
        <>
          {product.quantity && (
            <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
              {product.quantity} meters
            </Typography>
          )}
          <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
            {product.product_detail.sleeve}
          </Typography>
          <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
            {product.product_detail.collor}
          </Typography>
          <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
            {product.product_detail.pocket}
          </Typography>
        </>
      );
    }

    let sCollor = "";

    product.collors.some((Collor) => {
      if (Collor.product_id === product.product_detail.collor) {
        sCollor = Collor.product_name;
        return true;
      }
      return false;
    });

    let sPocket = "";
    product.pockets.some((Pocket) => {
      if (Pocket.product_id === product.product_detail.pocket) {
        sPocket = Pocket.product_name;
        return true;
      }

      return false;
    });

    let sSleeve = "";
    product.sleeves.some((Sleeve) => {
      if (Sleeve.product_id === product.product_detail.sleeve) {
        sSleeve = Sleeve.product_name;
        return true;
      }

      return false;
    });

    let sCuff = "";
    product.cuffs.some((Cuff) => {
      if (Cuff.product_id === product.product_detail.cuff) {
        sCuff = Cuff.product_name;
        return true;
      }

      return false;
    });

    return (
      <>
        <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
          {sCollor}
        </Typography>
        <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
          {sPocket}
        </Typography>
        <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
          {sSleeve}
        </Typography>
        <Typography
          fontSize="16px"
          fontWeight={theme.fontWeight.regular}
          textAlign="center"
        >
          {sCuff}
        </Typography>
      </>
    );
  };

  const getPantDetails = (product) => {
    let oFoundSuiting = null;

    suiting.some((oSuiting) => {
      if (oSuiting.category === product.product_detail.suiting_type) {
        oFoundSuiting = oSuiting;
        return true;
      }
      return false;
    });

    return (
      <>
        <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
          {product.quantity} meters
        </Typography>
        {oFoundSuiting && (
          <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
            {oFoundSuiting.label}
          </Typography>
        )}
      </>
    );
  };

  /*Functions End*/

  return (
    <>
      {showTrialRoom ? (
        <NewTrialRoom
          oProduct={selectedProduct}
          getTotal={getTotal}
          setOrder={setOrder}
          closeTrial={() => {
            setShowTrialRoom(false);
          }}
        />
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box></Box>
            <Button
              sx={{ marginBottom: "20px", ...stepperButtonStyles }}
              onClick={() => {
                setSelectedProduct(null);
                setShowTrialRoom(true);
              }}
            >
              ADD PRODUCTS
            </Button>
          </Box>
          <Box sx={{ minHeight: "200px" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      PRODUCT
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      PRODUCT DETAILS
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      PRICE
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order?.products?.map((product, productIndex) => {
                    return (
                      <TableRow
                        key={product.product_id + productIndex}
                        sx={{ "& td": { borderBottom: 0 } }}
                      >
                        <TableCell>
                          <Box
                            alignItems="center"
                            sx={{
                              gap: "10px",
                              display: {
                                xs: "block",
                                sm: "flex",
                                md: "flex",
                                lg: "flex",
                              },
                            }}
                          >
                            {product?.card_image_url?.includes("http") ? (
                              <LazyLoadImage
                                onClick={() => {
                                  setSelectedProduct(product);
                                  setShowTrialRoom(true);
                                }}
                                threshold={10}
                                visibleByDefault={true}
                                alt={product.product_name}
                                src={product?.card_image_url}
                                width="200px"
                              />
                            ) : (
                              <Box
                                sx={{
                                  width: "200px",
                                  border: "1px solid lightgray",
                                }}
                              >
                                {" "}
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                  }}
                                >
                                  Image not available
                                </Typography>
                              </Box>
                            )}
                            <Typography
                              sx={{
                                width: { xs: "150px", md: "300px" },
                              }}
                              fontSize="18px"
                              fontWeight={theme.fontWeight.regular}
                            >
                              {product.product_name} ({product.product_code})
                              &nbsp;
                              {parseInt(product.stock_quantity) === 0 && (
                                <>(Out of stock)</>
                              )}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {product.category === "shirt" && (
                            <>{getShirtDetails(product)}</>
                          )}
                          {product.category === "pant" && (
                            <>{getPantDetails(product)}</>
                          )}
                        </TableCell>

                        <TableCell>
                          <Typography
                            fontSize="18px"
                            fontWeight={theme.fontWeight.bold}
                            textAlign="center"
                          >
                            &#8377;
                            {parseFloat(product.price).toFixed(2)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <DeleteIcon
                            sx={{ color: "#000000", cursor: "pointer" }}
                            onClick={() => {
                              let oOrder = {
                                products: [],
                                discount: 0,
                                totalCost: 0,
                              };
                              if (order?.products) {
                                oOrder.products = order.products.filter(
                                  (oProduct, oProductIndex) => {
                                    if (oProductIndex !== productIndex) {
                                      return oProduct;
                                    }
                                  }
                                );

                                let oReturn = getTotal(oOrder.products);
                                oOrder.totalCost = oReturn.totalCost;
                                oOrder.discount = oReturn.discount;
                              }
                              setOrder((prevState) => {
                                const oClonedOrder = cloneDeep(prevState);
                                oClonedOrder.products = oOrder.products;
                                oClonedOrder.totalCost = oOrder.totalCost;
                                oClonedOrder.discount = oOrder.discount;
                                return oClonedOrder;
                              });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Stack
            rowGap="20px"
            columnGap="20px"
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            sx={{
              marginTop: "20px",
              justifyContent: {
                xs: "space-between",
                md: "space-between",
                lg: "space-between",
              },
            }}
          >
            <Stack
              direction="row"
              width="max-content"
              maxWidth="100%"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              rowGap="20px"
              sx={{ columnGap: { xs: "10px", lg: "26px" } }}
            >
              {order?.products?.length > 0 && (
                <Button
                  sx={{
                    ...stepperButtonStyles,
                  }}
                  onClick={(event) => {
                    if (order.order_id) {
                      setActiveStep((previousState) => previousState + 2);
                    } else {
                      setActiveStep((previousState) => previousState + 1);
                    }
                  }}
                >
                  NEXT
                </Button>
              )}
            </Stack>

            <Stack
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              rowGap="10px"
              sx={{
                columnGap: { xs: "10px", lg: "20px" },
                flexDirection: { xs: "row", md: "column" },
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                columnGap="33px"
                width="300px"
                height="49px"
                sx={{ backgroundColor: "#F1F1F1", borderRadius: "10px" }}
              >
                <Typography
                  fontWeight={theme.fontWeight.regular}
                  fontSize="18px"
                >
                  CART VALUE:
                </Typography>
                <Typography fontWeight={"bold"} fontSize="18px">
                  &#8377;{order.totalCost.toFixed(2)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};

export default ShoppingCart;
