export const mapWithOrderProductIds = (products, Orders) => {
  Orders.products = Orders.products.map((oProduct) => {
    if (products) {
      const productFound = products.find(
        (item) => item.id === oProduct.product_id
      );
      oProduct = {
        ...oProduct,
        ...productFound,
        price: oProduct.price,
        measurmentIndex: "",
      };
    } else {
      oProduct = {
        ...oProduct,
        measurmentIndex: "",
      };
    }
    return oProduct;
  });
  return Orders;
};
