/* eslint-disable no-empty-pattern */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const initialState = {
  messages: null,
  loading: false,
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessagesLoading(state, actions) {
      state.loading = actions.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMessages.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.messages = action.payload;
        state.loading = false;
      });
  },
});

export const fetchMessages = createAsyncThunk(
  "messages/fetchMessages",
  async ({}, thunkAPI) => {
    const ordersRef = collection(db, "messages");
    const q = query(ordersRef);
    const querySnapshot = await getDocs(q);

    let userOrders = [];
    querySnapshot.forEach((doc) => {
      let oData = doc.data();
      oData.mobile = oData.mobile.substring(2);
      userOrders.push(oData);
    });

    return userOrders.sort(function (a, b) {
      return new Date(a.last_modified) - new Date(b.last_modified);
    });
  }
);

export default messagesSlice.reducer;
export const { setMessagesLoading } = messagesSlice.actions;
