/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { doc, updateDoc } from "firebase/firestore";
import {
  Box,
  Button,
  Typography,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  TextField as TextMUI,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import { fetchOrder } from "../../features/order/orderSlice";
import { useAppDispatch } from "../../app/store";
import { fetchAllProducts } from "../../features/products/productsSlice";
import { db } from "../../firebase";
import { UserMeasurement, ConfirmationDialog } from "../../components";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};
/*Constant Variables End*/

const OrderMeasurement = () => {
  /*Standard Variables Start*/
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    currentUser,
    order: { order },
    products: { products },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const {
    MeasurementControl,
    setMeasurementValues,
    setShowMeasurementDialog,
    setMeasurementEditMode,
  } = UserMeasurement();
  const {
    ConfirmationDialogControl,
    setShowConfirmationDialog,
    setConfirmationDialogMessage,
    setAcceptButtonText,
    setRejectButtonText,
  } = ConfirmationDialog();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [selectedOrder, setSelectedOrder] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedProductIndex, setSelectedProductIndex] = useState("");
  const [openAddAlterationDialog, setOpenAddAlterationDialog] = useState("");
  const [messageError, setMessageError] = useState("");
  const [alterationDetails, setAlterationDetails] = useState("");
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (currentUser) {
      dispatch(fetchAllProducts());
    }
  }, [currentUser]);

  useEffect(() => {
    const order_id = searchParams.get("order_id");

    if (order_id && products?.length > 0) {
      dispatch(
        fetchOrder({
          orderId: order_id,
          products,
        })
      );
    }
  }, [searchParams.get("order_id"), products]);

  useEffect(() => {
    if (order) {
      let oClonedOrder = cloneDeep(order);
      oClonedOrder?.products?.map((oProduct) => {
        if (oProduct?.alteration_history?.length > 1) {
          oProduct.alteration_history = oProduct.alteration_history.sort(
            function (a, b) {
              return new Date(b.last_modified) - new Date(a.last_modified);
            }
          );
        }
        return oProduct;
      });
      setSelectedOrder(oClonedOrder);
    }
  }, [order]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  const onAddAlteration = async () => {
    if (currentUser.currentUser) {
      const docRef = doc(db, "orders", selectedOrder.order_id);
      const updatedProducts = selectedOrder.products.map(
        (oOriginalProduct, iProductIndex) => {
          const oProduct = cloneDeep(oOriginalProduct);
          if (
            oProduct.product_code === selectedProduct.product_code &&
            iProductIndex === parseInt(selectedProductIndex)
          ) {
            if (!oProduct.alteration_history) {
              oProduct.alteration_history = [];
            }

            oProduct.alteration_history.map((oAlteration) => {
              oAlteration.status = "close";
            });
            oProduct.alteration_history.push({
              message: alterationDetails,
              last_modified: new Date().toString(),
              status: "open",
              index: oProduct.alteration_history.length,
            });
          }
          return oProduct;
        }
      );
      await updateDoc(docRef, {
        products: updatedProducts,
        last_modified: new Date().toString(),
      });

      let oOrderCopy = cloneDeep(selectedOrder);
      updatedProducts?.map((oProduct) => {
        if (oProduct?.alteration_history?.length > 1) {
          oProduct.alteration_history = oProduct.alteration_history.sort(
            function (a, b) {
              return new Date(b.last_modified) - new Date(a.last_modified);
            }
          );
        }
        return oProduct;
      });
      oOrderCopy.products = updatedProducts;
      setSelectedOrder(oOrderCopy);
    }
    setOpenAddAlterationDialog(false);
    setAlterationDetails(false);
    setSelectedProduct(null);
    setSelectedProductIndex(null);
  };

  const onCloseAlterationRequest = async () => {
    const docRef = doc(db, "orders", selectedOrder.order_id);

    const updatedProducts = selectedOrder.products.map(
      (oOriginalProduct, iProductIndex) => {
        const oProduct = cloneDeep(oOriginalProduct);
        if (
          oProduct.product_code === selectedProduct.product_code &&
          iProductIndex === parseInt(selectedProductIndex)
        ) {
          oProduct.alteration_history.map((oAlteration) => {
            oAlteration.status = "close";
          });
        }
        return oProduct;
      }
    );

    await updateDoc(docRef, {
      products: updatedProducts,
      last_modified: new Date().toString(),
    });

    let oOrderCopy = cloneDeep(selectedOrder);
    oOrderCopy.products = updatedProducts;
    setSelectedOrder(oOrderCopy);
  };
  /*Functions End*/

  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          sx={{ marginBottom: "20px", ...stepperButtonStyles }}
          onClick={() => {
            if (selectedOrder.stitch_only) {
              navigate({
                pathname: "/order-stitch-detail",
                search: `?id=${selectedOrder.order_id}`,
              });
            } else {
              navigate({
                pathname: "/order-detail",
                search: `?id=${selectedOrder.order_id}`,
              });
            }
          }}
          startIcon={<ArrowBackIcon />}
        >
          BACK
        </Button>
      </Box>
      {selectedOrder &&
        selectedOrder?.products?.map((oProduct, iProductIndex) => (
          <Box
            key={iProductIndex}
            sx={{
              border: "1px solid grey",
              paddingLeft: "20px",
              paddingRight: "20px",
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
              }}
            >
              <Grid item xs={2} sm={4} md={4} key={2000}>
                {selectedOrder?.stitch_only && (
                  <>
                    <Stack rowGap="5px">
                      <Typography
                        sx={{
                          display: "inline-flex",
                          fontWeight: theme.fontWeight.semiBold,
                          textAlign: { xs: "center", sm768: "initial" },
                          fontSize: "18px",
                        }}
                      >
                        CATGEGORY: {oProduct.category.toUpperCase()}
                      </Typography>
                    </Stack>
                  </>
                )}
                {!selectedOrder?.stitch_only && (
                  <>
                    <Stack rowGap="5px">
                      <Typography
                        sx={{
                          display: "inline-flex",
                          textAlign: { xs: "center", sm768: "initial" },
                          fontSize: "18px",
                        }}
                      >
                        <Typography
                          component={"span"}
                          sx={{
                            display: "inline-flex",
                            fontWeight: theme.fontWeight.semiBold,
                            textAlign: { xs: "center", sm768: "initial" },
                          }}
                        >
                          {oProduct.product_name} ({oProduct.product_code})
                        </Typography>
                      </Typography>
                    </Stack>
                    <Stack rowGap="5px">
                      <Typography
                        sx={{
                          display: "block",
                        }}
                      >
                        {oProduct?.card_image_url?.includes("http") ? (
                          <img
                            width="150px"
                            height="40%"
                            src={oProduct?.card_image_url}
                            alt={oProduct.product_name}
                          ></img>
                        ) : (
                          <Box
                            sx={{
                              width: "150px",
                              height: "40%",
                              border: "1px solid lightgray",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                fontSize: "14px",
                              }}
                            >
                              Image not available
                            </Typography>
                          </Box>
                        )}
                      </Typography>
                    </Stack>
                  </>
                )}
                <Button
                  sx={{
                    marginBottom: "20px",
                    marginTop: "20px",
                    ...stepperButtonStyles,
                  }}
                  onClick={() => {
                    setSelectedProduct(oProduct);
                    setSelectedProductIndex(iProductIndex);
                    setOpenAddAlterationDialog(true);
                  }}
                >
                  ADD ALTERATION
                </Button>
                <Button
                  sx={{
                    marginBottom: "20px",
                    marginTop: "20px",
                    marginLeft: "20px",
                    ...stepperButtonStyles,
                  }}
                  onClick={(event) => {
                    setMeasurementValues(oProduct.measurements);
                    setShowMeasurementDialog(true);
                    setMeasurementEditMode(false);
                  }}
                >
                  {oProduct.measurements.name}
                </Button>
              </Grid>
              <Grid item xs={2} sm={4} md={4} key={1000}>
                {iProductIndex === 0 && (
                  <>
                    <Typography
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: { xs: "center", sm768: "initial" },
                        textAlign: { xs: "center", sm768: "initial" },
                      }}
                    >
                      ORDER #:&nbsp;
                    </Typography>
                    <Typography
                      sx={{
                        display: "inline-flex",
                        fontWeight: theme.fontWeight.semiBold,
                      }}
                    >
                      {selectedOrder.order_id}
                    </Typography>
                    <Stack rowGap="5px">
                      <Typography
                        sx={{
                          display: "inline-flex",
                          textAlign: { xs: "center", sm768: "initial" },
                        }}
                      >
                        {" "}
                        CUSTOMER NAME: &nbsp;
                        <span style={{ fontWeight: "bold" }}>
                          {`${selectedOrder?.account_name}`}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          display: "inline-flex",
                          textAlign: { xs: "center", sm768: "initial" },
                        }}
                      >
                        CUSTOMER MOBILE: &nbsp;{" "}
                        <Typography
                          component={"span"}
                          sx={{
                            display: "inline-flex",
                            fontWeight: theme.fontWeight.semiBold,
                            textAlign: { xs: "center", sm768: "initial" },
                          }}
                        >
                          {selectedOrder?.user_address?.mobile
                            ? selectedOrder?.user_address?.mobile?.substring(3)
                            : selectedOrder.mobile.substring(3)}
                        </Typography>
                      </Typography>
                    </Stack>
                  </>
                )}
              </Grid>
            </Box>
            <Box sx={{ minHeight: "200px", marginBottom: "20px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "black",
                          fontSize: "16px",
                          fontWeight: theme.fontWeight.semiBold,
                          minWidth: "150px",
                        }}
                      >
                        REQUEST NO.
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "black",
                          fontSize: "16px",
                          fontWeight: theme.fontWeight.semiBold,
                          minWidth: "400px",
                        }}
                      >
                        ALTERATION DETAILS
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "black",
                          fontSize: "16px",
                          fontWeight: theme.fontWeight.semiBold,
                          minWidth: "100px",
                        }}
                      >
                        DATE
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontSize: "16px",
                          fontWeight: theme.fontWeight.semiBold,
                          minWidth: "100px",
                          alignItems: "end",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {oProduct?.alteration_history?.map(
                      (oAlteration, alterationIndex) => {
                        return (
                          <TableRow
                            key={alterationIndex}
                            sx={{ "& td": { borderBottom: 0 } }}
                          >
                            <TableCell
                              style={{
                                borderBottom: "2px solid lightgray",
                              }}
                              align="left"
                            >
                              <Typography
                                component={"span"}
                                sx={{
                                  display: "inline-flex",
                                  fontWeight: theme.fontWeight.semiBold,
                                  fontSize: "18px",
                                }}
                              >
                                {`ALTERATION #${oAlteration.index + 1}`}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: "2px solid lightgray",
                              }}
                              align="left"
                            >
                              <TextMUI
                                disabled
                                multiline
                                sx={{
                                  width: "400px",
                                  ".css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                                    {
                                      "-webkit-text-fill-color": "black",
                                    },
                                }}
                                value={oAlteration.message}
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: "2px solid lightgray",
                              }}
                              align="left"
                            >
                              <Typography
                                sx={{
                                  display: "inline-flex",
                                  fontWeight: theme.fontWeight.semiBold,
                                  fontSize: "18px",
                                }}
                              >
                                {moment(oAlteration.last_modified).format(
                                  "LLL"
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: "2px solid lightgray",
                              }}
                            >
                              {oAlteration.status === "open" && (
                                <Button
                                  sx={{
                                    ...stepperButtonStyles,
                                    width: "120px",
                                  }}
                                  onClick={() => {
                                    setSelectedProduct(oProduct);
                                    setSelectedProductIndex(iProductIndex);

                                    setShowConfirmationDialog(true);
                                    if (selectedOrder.stitch_only) {
                                      setConfirmationDialogMessage(
                                        `Are you sure you want to close the alteration request?`
                                      );
                                    } else {
                                      setConfirmationDialogMessage(
                                        `Are you sure you want to close the alteration request for ${oProduct.product_name} (${oProduct.product_code})?`
                                      );
                                    }
                                    setAcceptButtonText("SAVE");
                                    setRejectButtonText("CANCEL");
                                  }}
                                >
                                  CLOSE
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        ))}

      <Dialog
        open={openAddAlterationDialog}
        onClose={() => {
          setOpenAddAlterationDialog(false);
        }}
      >
        <DialogContent>
          <Typography fontSize="18px" fontWeight={theme.fontWeight.semiBold}>
            {selectedOrder?.stitch_only && selectedProduct
              ? `${selectedProduct?.category.toUpperCase()}`
              : `${selectedProduct?.product_name} (${selectedProduct?.product_code})`}
          </Typography>
          <TextMUI
            id="message"
            onChange={(event) => {
              setAlterationDetails(event.target.value);
            }}
            label="ALTERATION DETAILS"
            multiline
            rows={5}
            sx={{
              marginTop: "10px",
              width: "500px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${messageError && "#d32f2f"}`,
              },
              "& .MuiFormHelperText-root": {
                color: "#d32f2f",
              },
            }}
            helperText={messageError && messageError}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: "hite",
              borderRadius: "10px",
              color: "black",
              display: "flex",
              alignItems: "center",
              fontSize: "18px",
              fontWeight: theme.fontWeight.semiBold,
              maxWidth: { xs: "400px", sm: "448px" },
              textAlign: "center",
              textTransform: "none",
              width: "200px",
              height: "49px",
              border: "2px solid black",
            }}
            onClick={() => {
              setOpenAddAlterationDialog(false);
            }}
          >
            CLOSE
          </Button>
          <Button
            sx={stepperButtonStyles}
            onClick={() => {
              if (alterationDetails) {
                onAddAlteration();
              } else {
                setMessageError("Alteration details is required");
              }
            }}
          >
            ADD
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialogControl
        onAcceptButtonPress={onCloseAlterationRequest}
      />
      <MeasurementControl />
    </Box>
  );
};

export default OrderMeasurement;
