import { configureStore } from "@reduxjs/toolkit";

import userReducer from "../features/user/userSlice";
import currentUserReducer from "../features/currentUser/currentUserSlice";
import productsReducer from "../features/products/productsSlice";
import productReducer from "../features/product/productSlice";
import ordersReducer from "../features/orders/ordersSlice";
import orderReducer from "../features/order/orderSlice";
import usersReducer from "../features/users/usersSlice";
import appointmentsReducer from "../features/appointments/appointmentsSlice";
import couponsReducer from "../features/coupons/couponsSlice";
import accessReducer from "../features/access/accessSlice";
import messagesReducer from "../features/messages/messagesSlice";

import { useDispatch } from "react-redux";

export const store = configureStore({
  reducer: {
    user: userReducer,
    currentUser: currentUserReducer,
    products: productsReducer,
    product: productReducer,
    orders: ordersReducer,
    order: orderReducer,
    users: usersReducer,
    appointments: appointmentsReducer,
    coupons: couponsReducer,
    access: accessReducer,
    messages: messagesReducer,
  },
});

export const useAppDispatch = useDispatch; // Export
