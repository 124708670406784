/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import {
  Stack,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import storeAddress from "../../constants/storeAddress.json";
import { useAppDispatch } from "../../app/store";
import { fetchStitchOrder } from "../../features/order/orderSlice";
import { fetchUsers } from "../../features/users/usersSlice";
import Users from "../Users";
import {
  ShoppingCartStitch,
  DeliveryOption,
  AddressesContainer,
  CartSummaryStitch,
} from "../../components";
/*Local Library End*/

const CreateStitchOrder = () => {
  /*Standard Variables Start*/
  const dispatch = useAppDispatch();
  const {
    currentUser,
    users: { users },
    order: { stitchOrder },
  } = useSelector((state) => state);
  const [searchParams] = useSearchParams();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [activeStep, setActiveStep] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [order, setOrder] = useState({
    products: [
      {
        price: "",
        category: "",
        product_detail: "",
        quantity: 1,
        sub_total: 0,
      },
    ],
    discount: 0,
    totalCost: 0,
  });
  const [deliveryType, setDeliveryType] = useState("");
  const [addressEditMode, setAddressEditMode] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [addressIndex, setAddressIndex] = useState(-1);
  const [editMode, setEditMode] = useState(false);
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (currentUser) {
      dispatch(fetchUsers({}));
    }
  }, [currentUser]);

  useEffect(() => {
    const id = searchParams.get("id");
    if (currentUser?.currentUser?.access?.orders && id) {
      dispatch(
        fetchStitchOrder({
          orderId: id,
        })
      );
    }
  }, [currentUser?.currentUser?.access?.orders, searchParams.get("id")]);

  useEffect(() => {
    if (stitchOrder && users && searchParams.get("id")) {
      let oCloneOrder = cloneDeep(stitchOrder);

      let total = oCloneOrder.products.reduce(
        (previousValue, currentValue) =>
          previousValue +
          parseFloat(currentValue.price) * currentValue.quantity,
        0
      );
      oCloneOrder.totalCost = +total.toFixed(2);
      setOrder(oCloneOrder);
      if (oCloneOrder.mobile) {
        let sUser = users.find(
          (oUser) => oUser.mobile === oCloneOrder.mobile.substring(3)
        );
        setSelectedUser(sUser);
      } else {
        setSelectedUser(null);
      }

      if (!oCloneOrder.delivery_type) {
        setDeliveryType("get-delivered");
      } else {
        setDeliveryType(oCloneOrder.delivery_type);
      }
      if (oCloneOrder.delivery_type !== "in-store") {
        setSelectedAddress(oCloneOrder.user_address);
      }
    }
  }, [stitchOrder, users]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  /*Functions End*/

  return (
    <Stack
      sx={{
        marginTop: { xs: "20px", md: "20px" },
        marginBottom: "50px",
        paddingLeft: {
          xs: theme.padding?.pagePaddingXS + "px",
          lg: theme.padding?.pagePaddingLG + "px",
          xl: theme.padding?.pagePaddingXL + "px",
        },
        paddingRight: {
          xs: theme.padding?.pagePaddingXS + "px",
          lg: theme.padding?.pagePaddingLG + "px",
          xl: theme.padding?.pagePaddingXL + "px",
        },
      }}
      rowGap="20px"
    >
      <div>
        <Accordion expanded={activeStep === 0}>
          <AccordionSummary>
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <Stack>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: theme.fontWeight.semiBold,
                    }}
                  >
                    {searchParams.get("id")
                      ? `EDITING STITCH ORDER #${searchParams.get("id")}`
                      : "STITCH ONLY ORDER"}
                  </Typography>
                  {activeStep > 0 && (
                    <>
                      <Typography>
                        ITEMS: &nbsp;
                        <span style={{ fontWeight: "bold" }}>
                          {order.products.length}{" "}
                        </span>
                      </Typography>
                      <Typography>
                        CART VALUE: &nbsp;
                        <span style={{ fontWeight: "bold" }}>
                          &#8377;{order.totalCost.toFixed(2)}
                        </span>
                      </Typography>
                    </>
                  )}
                </Stack>
                {activeStep > 0 && (
                  <Button
                    onClick={() => {
                      setActiveStep(0);
                    }}
                    sx={{
                      borderRadius: "10px",
                      backgroundColor: "black",
                      color: "white",
                      fontSize: "16px",
                      fontWeight: theme.fontWeight.semiBold,
                      textAlign: "center",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                    }}
                  >
                    CHANGE
                  </Button>
                )}
              </Stack>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <ShoppingCartStitch
              setActiveStep={setActiveStep}
              order={order}
              setOrder={setOrder}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={activeStep === 1} disabled={activeStep < 1}>
          <AccordionSummary>
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Stack>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: theme.fontWeight.semiBold,
                  }}
                >
                  USER
                </Typography>
                {selectedUser && (
                  <>
                    <Typography>
                      NAME: &nbsp;
                      <span style={{ fontWeight: "bold" }}>
                        {selectedUser?.account_name}{" "}
                      </span>
                    </Typography>
                    <Typography>
                      MOBILE: &nbsp;
                      <span style={{ fontWeight: "bold" }}>
                        {selectedUser?.mobile}{" "}
                      </span>
                    </Typography>
                  </>
                )}
              </Stack>
              {activeStep > 1 && !order.order_id && (
                <Button
                  onClick={() => {
                    setActiveStep(1);
                  }}
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "black",
                    color: "white",
                    fontSize: "16px",
                    fontWeight: theme.fontWeight.semiBold,
                    textAlign: "center",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  }}
                >
                  CHANGE
                </Button>
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Users
              setActiveStep={setActiveStep}
              setSelectedUser={setSelectedUser}
              selectedUser={selectedUser}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={activeStep === 2} disabled={activeStep < 2}>
          <AccordionSummary>
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Stack>
                <Typography
                  sx={{
                    fontSize: { xs: "18px", md: "24px" },
                    fontWeight: theme.fontWeight.semiBold,
                  }}
                >
                  DELIVERY OPTION
                </Typography>
                {deliveryType && deliveryType === "in-store" && (
                  <>
                    <Typography>IN STORE PICKUP</Typography>
                  </>
                )}
                {deliveryType && deliveryType === "get-delivered" && (
                  <>
                    <Typography>GET DELIVERED</Typography>
                  </>
                )}
              </Stack>
              {activeStep > 2 && (
                <Button
                  onClick={() => {
                    setActiveStep(2);
                  }}
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "black",
                    color: "white",
                    fontSize: "16px",
                    fontWeight: theme.fontWeight.semiBold,
                    textAlign: "center",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  }}
                >
                  CHANGE
                </Button>
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <DeliveryOption
              setActiveStep={setActiveStep}
              deliveryType={deliveryType}
              setDeliveryType={setDeliveryType}
              setSelectedAddress={setSelectedAddress}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={activeStep === 3} disabled={activeStep < 3}>
          <AccordionSummary>
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Stack width={activeStep === "4" ? "70%" : "50%"}>
                <Typography
                  sx={{
                    fontSize: { xs: "18px", md: "24px" },
                    fontWeight: theme.fontWeight.semiBold,
                  }}
                >
                  ADDRESS
                </Typography>
                {selectedAddress && deliveryType === "get-delivered" && (
                  <>
                    <Typography>{`${selectedAddress.first_name} ${selectedAddress.last_name}`}</Typography>
                    <Typography>{`${selectedAddress.address}, ${selectedAddress.city}, ${selectedAddress.pin_code}`}</Typography>
                    <Typography>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>Mobile: </span>
                      {`${selectedAddress?.mobile?.substring(3)}`}
                    </Typography>
                    <Typography>
                      Preferred Time Slot: {`${selectedAddress?.time_slot}`}
                    </Typography>
                  </>
                )}
                {deliveryType === "in-store" && (
                  <>
                    <Typography>{`${storeAddress.first_name}`},</Typography>
                    <Typography>
                      {`${storeAddress.address}, ${storeAddress.city}, ${storeAddress.pin_code}`}
                    </Typography>
                    <Typography>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>Mobile: </span>
                      {`${storeAddress.mobile.substring(3)}`}
                    </Typography>
                  </>
                )}
              </Stack>
              {activeStep === 3 && !editMode && (
                <Button
                  onClick={() => {
                    setEditMode(true);
                    setAddressIndex(-1);
                    setSelectedAddress({
                      first_name: "",
                      last_name: "",
                      address: "",
                      city: "",
                      pin_code: "",
                      mobile: "",
                    });
                  }}
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "black",
                    color: "white",
                    fontSize: "18px",
                    fontWeight: theme.fontWeight.semiBold,
                    textAlign: "center",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  }}
                >
                  ADD ADDRESS
                </Button>
              )}
              {activeStep > 3 && deliveryType === "get-delivered" && (
                <Button
                  onClick={() => {
                    setSelectedAddress("");
                    setActiveStep(3);
                  }}
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "black",
                    color: "white",
                    fontSize: "16px",
                    fontWeight: theme.fontWeight.semiBold,
                    textAlign: "center",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  }}
                >
                  CHANGE
                </Button>
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <AddressesContainer
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              editMode={addressEditMode}
              setEditMode={setAddressEditMode}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              addressIndex={addressIndex}
              setAddressIndex={setAddressIndex}
              aAddress={
                selectedUser?.addresses?.length > 0
                  ? selectedUser.addresses
                  : []
              }
            />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={activeStep === 4} disabled={activeStep < 4}>
          <AccordionSummary>
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: theme.fontWeight.semiBold,
                }}
              >
                ORDER SUMMARY
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <CartSummaryStitch
              user={selectedUser}
              order={order}
              selectedAddress={selectedAddress}
              deliveryType={deliveryType}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </Stack>
  );
};

export default CreateStitchOrder;
