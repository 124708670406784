/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Stack, Paper, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
/*Standard Library End*/

/*Local Library Start*/
import { useAppDispatch } from "../../app/store";
import { fetchOrders } from "../../features/orders/ordersSlice";
import { fetchAppointments } from "../../features/appointments/appointmentsSlice";
import { fetchMessages } from "../../features/messages/messagesSlice";
import OrderDeliveryDate from "../../components/OrderDeliveryDate";
/*Local Library End*/

const Dashboard = () => {
  /*Standard Variables Start*/
  const {
    currentUser: { currentUser },
    orders: { orders },
    appointments: { appointments },
    messages: { messages },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [orderCount, setOrderCount] = useState(0);
  const [messagesCount, setMessagesCount] = useState(0);
  const [appointmentCount, setAppointmentCount] = useState(0);
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (currentUser?.access?.orders) {
      dispatch(fetchOrders({}));
    }
    if (currentUser?.access?.appointments) {
      dispatch(fetchAppointments({}));
    }

    if (currentUser?.access?.messages) {
      dispatch(fetchMessages({}));
    }
  }, [currentUser?.access?.orders]);

  useEffect(() => {
    if (orders) {
      let iCount = 0;
      orders.map((oOrder) => {
        if (oOrder.status === "Order Placed") {
          iCount++;
        }
      });
      setOrderCount(iCount);
    }
  }, [orders]);

  useEffect(() => {
    if (appointments) {
      let iCount = 0;
      appointments.map((oOrder) => {
        if (oOrder.status_text === "Scheduled") {
          iCount++;
        }
      });
      setAppointmentCount(iCount);
    }
  }, [appointments]);

  useEffect(() => {
    if (messages) {
      setMessagesCount(messages.length);
    }
  }, [messages]);
  /*OnLoad Functions End*/

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          marginLeft: "20px",
          width: "200px",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        <Grid sx={{ flexGrow: 1 }}>
          <Grid item xs={1} md={1}>
            <Grid container justifyContent="center" spacing={2}>
              {currentUser?.access?.orders && (
                <Grid item>
                  <Paper
                    onClick={() => {
                      navigate({ pathname: "/orders" });
                    }}
                    elevation={3}
                    sx={{
                      height: 120,
                      width: 200,
                      padding: "10px",
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "24px",
                        }}
                      >
                        ORDERS
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "32px",
                          color: "#a7a4a4",
                        }}
                      >
                        {orderCount}
                      </Typography>
                    </Stack>
                  </Paper>
                </Grid>
              )}
              {currentUser?.access?.products && (
                <Grid item>
                  <Paper
                    onClick={() => {
                      navigate({ pathname: "/products" });
                    }}
                    elevation={3}
                    sx={{
                      height: 120,
                      width: 200,
                      padding: "10px",
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "24px",
                      }}
                    >
                      PRODUCTS
                    </Typography>
                  </Paper>
                </Grid>
              )}

              {currentUser?.access?.users && (
                <Grid item>
                  <Paper
                    onClick={() => {
                      navigate({ pathname: "/users" });
                    }}
                    elevation={3}
                    sx={{
                      height: 120,
                      width: 200,
                      padding: "10px",
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "24px",
                      }}
                    >
                      USERS
                    </Typography>
                  </Paper>
                </Grid>
              )}

              {currentUser?.access?.appointments && (
                <Grid item>
                  <Paper
                    onClick={() => {
                      navigate({ pathname: "/appointments" });
                    }}
                    elevation={3}
                    sx={{
                      height: 120,
                      width: 200,
                      padding: "10px",
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "24px",
                      }}
                    >
                      APPOINTMENTS
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "32px",
                        color: "#a7a4a4",
                      }}
                    >
                      {appointmentCount}
                    </Typography>
                  </Paper>
                </Grid>
              )}
              {currentUser?.access?.coupons && (
                <Grid item>
                  <Paper
                    onClick={() => {
                      navigate({ pathname: "/coupons" });
                    }}
                    elevation={3}
                    sx={{
                      height: 120,
                      width: 200,
                      padding: "10px",
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "24px",
                      }}
                    >
                      COUPONS
                    </Typography>
                  </Paper>
                </Grid>
              )}
              {currentUser?.access?.messages && (
                <Grid item>
                  <Paper
                    onClick={() => {
                      navigate({ pathname: "/messages" });
                    }}
                    elevation={3}
                    sx={{
                      height: 120,
                      width: 200,
                      padding: "10px",
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "24px",
                      }}
                    >
                      MESSAGES
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "32px",
                        color: "#a7a4a4",
                      }}
                    >
                      {messagesCount}
                    </Typography>
                  </Paper>
                </Grid>
              )}
              {currentUser?.access?.access && (
                <Grid item>
                  <Paper
                    onClick={() => {
                      navigate({ pathname: "/access" });
                    }}
                    elevation={3}
                    sx={{
                      height: 120,
                      width: 200,
                      padding: "10px",
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "24px",
                      }}
                    >
                      ACCESS
                    </Typography>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          marginLeft: "20px",
          width: "900px",
        }}
      >
        <OrderDeliveryDate eventClickable={true} />
      </Box>
    </Box>
  );
};

export default Dashboard;
