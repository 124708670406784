/*Standard Library Start*/
import React, { useState } from "react";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { useSearchParams } from "react-router-dom";
import {
  Stack,
  Typography,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  TextField as TextMUI,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
/*Standard Library End*/

/*Local Library Start*/
import { db } from "../../firebase";
import { useAppDispatch } from "../../app/store";
import theme from "../../theme";
import { fetchOrder } from "../../features/order/orderSlice";
import { useSelector } from "react-redux";
import { MessageAlert } from "../../components";
import { setOrderLoading } from "../../features/order/orderSlice";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};
/*Constant Variables End*/

const OrderPayment = () => {
  /*Standard Variables Start*/
  const {
    order: { order },
    products: { products },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const { setAlertMessage, setAlertSeverity, setShowAlert } = MessageAlert();
  const [searchParams] = useSearchParams();
  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false);
  const [showPaymentHistoryDialog, setShowPaymentHistoryDialog] =
    useState(false);
  /*Standard Variables End*/

  /*Functions Start*/
  const onPaymentNotReceivedStatusUpdate = (oOrder) => {
    dispatch(setOrderLoading(true));
    const docRef = doc(db, "orders", oOrder.order_id);
    updateDoc(docRef, {
      payment_status: "Payment not received",
      last_modified: new Date().toString(),
    }).then(() => {
      dispatch(setOrderLoading(false));
      dispatch(
        fetchOrder({
          orderId: searchParams.get("id"),
          products,
        })
      );
    });
  };

  const handleClosePaymentHistoryDialog = () => {
    setShowPaymentHistoryDialog(false);
  };

  const handleCloseUpdatePaymentDialog = () => {
    setShowUpdatePaymentDialog(false);
  };

  const onUpdatePayment = (
    remainingAmount,
    paymentMode,
    setPaymentModeError,
    setAmountError
  ) => {
    let bError = false;
    if (remainingAmount === "") {
      bError = true;
      setAmountError("Please enter amount");
    }

    if (paymentMode === "") {
      bError = true;
      setPaymentModeError("Please select payment mode");
    }

    if (bError) {
      return;
    }
    handleCloseUpdatePaymentDialog();

    dispatch(setOrderLoading(true));
    setAmountError("");
    setPaymentModeError("");

    let sPaid = parseFloat(remainingAmount);

    if (order?.amount_paid) {
      sPaid = sPaid + parseFloat(order.amount_paid);
    }
    if (sPaid > parseFloat(order.total_amount)) {
      setAmountError("Amount is larger than total amount");
      return;
    }

    let sAmountPaid = 0;
    let sPaymentStatus = "";
    if (order?.amount_paid) {
      sAmountPaid = parseFloat(order.amount_paid) + parseFloat(remainingAmount);
      if (parseFloat(order.total_amount) === sAmountPaid) {
        sPaymentStatus = "Payment received";
      } else if (parseFloat(order.total_amount) > sAmountPaid) {
        sPaymentStatus = "Partial payment received";
      }
    }
    const docRef = doc(db, "orders", order.order_id);

    let aPaymentHistory = [];
    if (order?.payment_history?.length > 0) {
      aPaymentHistory = cloneDeep(order.payment_history);
    }

    aPaymentHistory.push({
      last_modified: new Date().toString(),
      payment_mode: paymentMode,
      amount_paid: remainingAmount,
    });
    updateDoc(docRef, {
      payment_status: sPaymentStatus,
      payment_history: aPaymentHistory,
      amount_paid: sAmountPaid,
    })
      .then(() => {
        dispatch(setOrderLoading(false));
        dispatch(
          fetchOrder({
            orderId: searchParams.get("id"),
            products,
          })
        );
      })
      .catch((error) => {
        dispatch(setOrderLoading(false));
        setAlertMessage(`Something bad happened: ${error}`);
        setAlertSeverity("error");
        setShowAlert(true);
      });
  };

  const UpdatePaymentControl = ({ oOrder, remainingAmount }) => {
    const [remaining, setRemaining] = useState(remainingAmount);
    const [paymentMode, setPaymentMode] = useState("");
    const [amountError, setAmountError] = useState("");
    const [paymentModeError, setPaymentModeError] = useState("");

    return (
      <>
        <Dialog
          open={showUpdatePaymentDialog}
          onClose={handleCloseUpdatePaymentDialog}
        >
          <DialogContent>
            <Typography fontSize="18px" fontWeight={theme.fontWeight.semiBold}>
              ORDER #: {oOrder.order_id}
            </Typography>
            <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
              TOTAL AMOUNT: &#8377; {parseFloat(oOrder.total_amount).toFixed(2)}{" "}
            </Typography>
            <Stack rowGap="5px">
              <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
                REMAINING AMOUNT:
              </Typography>
              <TextMUI
                type="number"
                value={remaining}
                onChange={(event) => {
                  setRemaining(event.target.value);
                  setAmountError("");
                }}
                sx={{
                  marginTop: "10px",
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${amountError && "#d32f2f"}`,
                  },
                  "& .MuiFormHelperText-root": {
                    color: "#d32f2f",
                  },
                }}
                helperText={amountError && amountError}
              />
              <Typography
                sx={{
                  display: "inline-flex",
                  textAlign: { xs: "center", sm768: "initial" },
                }}
              >
                PAYMENT MODE:
              </Typography>
              <FormControl error={paymentModeError}>
                <Select
                  onChange={(event) => {
                    setPaymentMode(event.target.value);
                    setPaymentModeError("");
                  }}
                  value={paymentMode}
                  sx={{
                    marginTop: "10px",
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${amountError && "#d32f2f"}`,
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#d32f2f",
                    },
                  }}
                >
                  <MenuItem key="cash" value="Cash">
                    Cash
                  </MenuItem>
                  <MenuItem key="UPI" value="UPI">
                    UPI
                  </MenuItem>
                  <MenuItem key="PG" value="Payment Gateway">
                    Payment Gateway
                  </MenuItem>
                </Select>
                <FormHelperText>{paymentModeError}</FormHelperText>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                ...stepperButtonStyles,
              }}
              onClick={() => {
                onUpdatePayment(
                  remaining,
                  paymentMode,
                  setPaymentModeError,
                  setAmountError
                );
              }}
            >
              SAVE
            </Button>
            <Button
              sx={{
                backgroundColor: "hite",
                borderRadius: "10px",
                color: "black",
                display: "flex",
                alignItems: "center",
                fontSize: "18px",
                fontWeight: theme.fontWeight.semiBold,
                maxWidth: { xs: "400px", sm: "448px" },
                textAlign: "center",
                textTransform: "none",
                width: "200px",
                height: "49px",
                border: "2px solid black",
              }}
              onClick={handleCloseUpdatePaymentDialog}
            >
              CANCEL
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const PaymentHistoryControl = ({ oOrder, remainingAmount }) => {
    let aPaymentHistory = [];
    if (oOrder?.payment_history?.length > 0) {
      aPaymentHistory = oOrder.payment_history.sort(function (a, b) {
        return new Date(b.last_modified) - new Date(a.last_modified);
      });
    }
    return (
      <Dialog
        open={showPaymentHistoryDialog}
        onClose={handleClosePaymentHistoryDialog}
      >
        <DialogContent>
          <>
            <Stack rowGap="5px">
              <Typography
                sx={{
                  fontSize: "18px",
                  display: "inline-flex",
                }}
              >
                TOTAL AMOUNT:&nbsp;
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: theme.fontWeight.semiBold,
                  }}
                >
                  &#8377;{parseFloat(oOrder.total_amount).toFixed(2)}{" "}
                  {oOrder?.discount > 0 && `(${oOrder.discount}% discount)`}
                </Typography>
              </Typography>
            </Stack>
            <Stack rowGap="5px">
              <Typography
                sx={{
                  fontSize: "18px",
                  display: "inline-flex",
                }}
              >
                AMOUNT PAID:&nbsp;
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: theme.fontWeight.semiBold,
                  }}
                >
                  &#8377;
                  {parseFloat(oOrder.amount_paid).toFixed(2)}{" "}
                </Typography>
              </Typography>
            </Stack>
            <Stack rowGap="5px">
              <Typography
                sx={{
                  fontSize: "18px",
                  display: "inline-flex",
                }}
              >
                REMAINING BALANCE:&nbsp;
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: theme.fontWeight.semiBold,
                  }}
                >
                  &#8377;{parseFloat(remainingAmount).toFixed(2)}{" "}
                </Typography>
              </Typography>
            </Stack>
          </>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "black",
                      fontSize: "16px",
                      fontWeight: theme.fontWeight.regular,
                    }}
                  >
                    Amount Paid
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "black",
                      fontSize: "16px",
                      fontWeight: theme.fontWeight.regular,
                    }}
                  >
                    Payment Mode
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontSize: "16px",
                      fontWeight: theme.fontWeight.regular,
                    }}
                  >
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {aPaymentHistory.map((oPayment, iIndex) => {
                  return (
                    <TableRow key={iIndex}>
                      <TableCell
                        sx={{
                          color: "black",
                          fontSize: "18px",
                          fontWeight: theme.fontWeight.semiBold,
                        }}
                      >
                        &#8377;{parseFloat(oPayment.amount_paid).toFixed(2)}{" "}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "black",
                          fontSize: "18px",
                          fontWeight: theme.fontWeight.semiBold,
                        }}
                      >
                        {oPayment.payment_mode}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "black",
                          fontSize: "18px",
                          fontWeight: theme.fontWeight.semiBold,
                        }}
                      >
                        {moment(oPayment.last_modified).format("LLL")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: "hite",
              borderRadius: "10px",
              color: "black",
              display: "flex",
              alignItems: "center",
              fontSize: "18px",
              fontWeight: theme.fontWeight.semiBold,
              maxWidth: { xs: "400px", sm: "448px" },
              textAlign: "center",
              textTransform: "none",
              width: "200px",
              height: "49px",
              border: "2px solid black",
            }}
            onClick={handleClosePaymentHistoryDialog}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  /*Functions End*/

  return {
    UpdatePaymentControl,
    setShowUpdatePaymentDialog,
    onPaymentNotReceivedStatusUpdate,
    setShowPaymentHistoryDialog,
    PaymentHistoryControl,
  };
};

export default OrderPayment;
