/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import { fetchSoonOutofStock } from "../../features/products/productsSlice";
import { useAppDispatch } from "../../app/store";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const columns = [
  { id: "product_code", label: "CODE", minWidth: 100 },
  { id: "product_name", label: "NAME", minWidth: 300 },
  { id: "category", label: "CATEGORY", minWidth: 200 },
  { id: "collection", label: "COLLECTION", minWidth: 200 },
  { id: "stock_quantity", label: "QUANTITY", minWidth: 200 },
];
/*Constant Variables End*/

const ProductsOption = () => {
  /*Standard Variables Start*/
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    currentUser,
    products: { productsOutOfStockByCategory },
  } = useSelector((state) => state);
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [rows, setRows] = useState([]);
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (currentUser) {
      dispatch(fetchSoonOutofStock());
    }
  }, [currentUser]);

  useEffect(() => {
    if (productsOutOfStockByCategory) {
      setRows(productsOutOfStockByCategory);
    }
  }, [productsOutOfStockByCategory]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  /*Functions End*/

  return (
    <>
      <Grid
        sx={{ flexGrow: 1, marginTop: "50px", padding: "20px" }}
        container
        spacing={2}
      >
        <Button
          sx={{ marginBottom: "20px", ...stepperButtonStyles }}
          onClick={() => {
            navigate("/");
          }}
          startIcon={<ArrowBackIcon />}
        >
          BACK
        </Button>
        {currentUser?.currentUser?.access?.products && (
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <Paper
                  onClick={() => {
                    navigate({ pathname: "/shirts" });
                  }}
                  elevation={3}
                  sx={{
                    height: 200,
                    width: 200,
                    padding: "10px",
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "24px",
                    }}
                  >
                    SHIRTS
                  </Typography>
                </Paper>
              </Grid>
              <Grid item>
                <Paper
                  onClick={() => {
                    navigate({ pathname: "/pants" });
                  }}
                  elevation={3}
                  sx={{
                    height: 200,
                    width: 200,
                    padding: "10px",
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "24px",
                    }}
                  >
                    SUITING
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const sSearch = `?id=${row.id}`;
                    return (
                      <TableRow
                        onClick={() => {
                          navigate({
                            pathname: `/${row.category.toLowerCase()}`,
                            search: sSearch,
                          });
                        }}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.id === "stock_quantity") {
                            return (
                              <TableCell
                                style={{
                                  fontSize: "18px",
                                  color: "red",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {parseFloat(value).toFixed(2)} METERS
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                style={{
                                  fontSize: "18px",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {" "}
                                {value}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={rows ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default ProductsOption;
