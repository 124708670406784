/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Stack,
  Typography,
  IconButton,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ShoppingCartOutlined, Close as CloseIcon } from "@mui/icons-material";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import SearchProduct from "../SearchProduct";
import { useAppDispatch } from "../../app/store";
import { fetchShirts, fetchPants } from "../../features/products/productsSlice";
import MessageAlert from "../MessageAlert";
/*Local Library End*/

const NewTrialRoom = ({
  closeTrial = null,
  oProduct = null,
  setOrder = null,
  getTotal = null,
}) => {
  /*Standard Variables Start*/
  const {
    products: { shirts, pants },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const { AlertControl, setAlertMessage, setAlertSeverity, setShowAlert } =
    MessageAlert();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [selectedProduct, setSelectedProduct] = useState(oProduct);
  const [category, setCategory] = useState(
    oProduct?.category ? oProduct.category : "shirt"
  );
  const [sleeves, setSleeves] = useState("Full Sleeve");
  const [pocket, setPocket] = useState("Pocket");
  const [collar, setCollar] = useState("Regular Collar");
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    dispatch(fetchShirts());
    dispatch(fetchPants());
  }, []);
  /*OnLoad Functions End*/

  const onAddPantToCart = (sType) => {
    let newElement = {
      product_id: selectedProduct.id,
      category: "pant",
      product_detail: {},
      ...selectedProduct,
    };

    let oFoundSuiting = null;
    selectedProduct.price.some((oSuiting) => {
      if (oSuiting.category === sType) {
        oFoundSuiting = oSuiting;
        return true;
      }
      return false;
    });
    newElement.product_detail.suiting_type = oFoundSuiting.category;
    newElement.price = oFoundSuiting.price;
    newElement.quantity = oFoundSuiting.quantity;

    setOrder((oOrder) => {
      if (oOrder?.products) {
        oOrder.products.push(newElement);
      } else {
        oOrder.products = [newElement];
      }

      if (oOrder?.products?.length > 0) {
        let oReturn = getTotal(oOrder.products);
        oOrder.totalCost = oReturn.totalCost;
        oOrder.discount = oReturn.discount;
      }
      return oOrder;
    });

    let sMessage = "";
    if (sType === "trouser") {
      sMessage = "Trouser added!";
    } else if (sType === "waist_coat") {
      sMessage = "Waist Coat added!";
    } else if (sType === "blazer") {
      sMessage = "Blazer added!";
    } else if (sType === "2_piece_suit") {
      sMessage = "2-Piece Suit added!";
    } else if (sType === "3_piece_suit") {
      sMessage = "3-Piece Suit added!";
    }
    setAlertMessage(sMessage);
    setAlertSeverity("success");
    setShowAlert(true);
  };

  const onAddShirtToCart = () => {
    const newElement = {
      product_id: selectedProduct.id,
      ...selectedProduct,
      category: "shirt",
      quantity: 1.6,
      product_detail: {
        collor: collar,
        pocket: pocket,
        sleeve: sleeves,
      },
    };
    setOrder((oOrder) => {
      if (oOrder?.products) {
        oOrder.products.push(newElement);
      } else {
        oOrder.products = [newElement];
      }

      if (oOrder?.products?.length > 0) {
        let oReturn = getTotal(oOrder.products);
        oOrder.totalCost = oReturn.totalCost;
        oOrder.discount = oReturn.discount;
      }
      return oOrder;
    });

    setAlertMessage("Shirt added!");
    setAlertSeverity("success");
    setShowAlert(true);
  };

  return (
    <>
      <Stack>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <Typography></Typography>
          <Box
            sx={{
              display: "flex",
            }}
          >
            {closeTrial && (
              <IconButton
                onClick={closeTrial}
                sx={{
                  backgroundColor: "black",
                  borderRadius: "56px",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "13px",
                  fontWeight: theme.fontWeight.semiBold,
                  textAlign: "center",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "black",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </div>

        <Stack
          sx={{ flexDirection: { xs: "column", lg: "row", sm: "row" } }}
          columnGap="10px"
        >
          <Stack
            sx={{
              padding: "10px",
            }}
          >
            <FormControl>
              <Typography
                fontSize="18px"
                fontWeight={theme.fontWeight.semiBold}
              >
                CATEGORY
              </Typography>
              <RadioGroup
                row
                value={category}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="shirt"
                  control={
                    <Radio
                      onChange={() => {
                        setSelectedProduct(null);
                        setCategory("shirt");
                      }}
                    />
                  }
                  label="SHIRT"
                />
                <FormControlLabel
                  value="pant"
                  control={
                    <Radio
                      onChange={() => {
                        setSelectedProduct(null);
                        setCategory("pant");
                      }}
                    />
                  }
                  label="SUITING"
                />
              </RadioGroup>
            </FormControl>

            {category === "shirt" && (
              <>
                <SearchProduct
                  showQuantity
                  showImage
                  setSelected={setSelectedProduct}
                  title="SHIRT"
                  products={shirts}
                  selected={selectedProduct}
                  readOnly={false}
                />
                {selectedProduct && (
                  <>
                    <Stack
                      width="300px"
                      gap="20px"
                      sx={{
                        marginTop: "20px",
                      }}
                    >
                      <Stack
                        sx={{
                          flexDirection: { md: "row" },
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          fontSize="16px"
                          fontWeight={theme.fontWeight.semiBold}
                          textAlign="center"
                        >
                          SHIRT CUSTOMISATION
                        </Typography>
                      </Stack>
                      <FormControl>
                        <InputLabel>SLEEVES</InputLabel>
                        <Select
                          value={sleeves}
                          label="SLEEVES"
                          onChange={(event) => {
                            setSleeves(event.target.value);
                          }}
                        >
                          <MenuItem value="Full Sleeve">Full Sleeve</MenuItem>
                          <MenuItem value="Half Sleeve">Half Sleeve</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl>
                        <InputLabel>POCKET</InputLabel>
                        <Select
                          value={pocket}
                          label="POCKET"
                          onChange={(event) => {
                            setPocket(event.target.value);
                          }}
                        >
                          <MenuItem value="Pocket">Pocket</MenuItem>
                          <MenuItem value="No Pocket">No Pocket</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl>
                        <InputLabel>COLLAR</InputLabel>
                        <Select
                          value={collar}
                          label="COLLAR"
                          onChange={(event) => {
                            setCollar(event.target.value);
                          }}
                        >
                          <MenuItem value="Regular Collar">
                            Regular Collar
                          </MenuItem>
                          <MenuItem value="Chinese Collar">
                            Chinese Collar
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                    <IconButton
                      onClick={onAddShirtToCart}
                      sx={{
                        backgroundColor: "black",
                        marginTop: "20px",
                        borderRadius: "10px",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "18px",
                        fontWeight: theme.fontWeight.semiBold,

                        maxWidth: { xs: "400px", sm: "448px" },
                        textAlign: "center",
                        textTransform: "none",
                        width: "189px",
                        "&:hover": {
                          backgroundColor: "black",
                        },
                        height: "56px",
                      }}
                    >
                      <ShoppingCartOutlined />
                      &nbsp;&nbsp;
                      <Typography
                        fontSize="18px"
                        fontWeight={theme.fontWeight.semiBold}
                      >
                        ADD TO CART
                      </Typography>
                    </IconButton>
                  </>
                )}
              </>
            )}
            {category === "pant" && (
              <>
                <SearchProduct
                  showQuantity
                  showImage
                  setSelected={setSelectedProduct}
                  title="SUITING"
                  products={pants}
                  selected={selectedProduct}
                  readOnly={false}
                />
                {selectedProduct && (
                  <IconButton
                    onClick={() => onAddPantToCart("trouser")}
                    disabled={selectedProduct.stock_quantity < 1.2}
                    sx={{
                      backgroundColor: "black",
                      borderRadius: "10px",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "18px",
                      fontWeight: theme.fontWeight.semiBold,
                      marginTop: "20px",
                      maxWidth: { xs: "400px", sm: "448px" },
                      textAlign: "center",
                      textTransform: "none",
                      width: "250px",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                      "&:disabled": {
                        border: "2px solid lightgrey",
                        backgroundColor: "white",
                      },
                      height: "56px",
                    }}
                  >
                    <ShoppingCartOutlined />
                    &nbsp;&nbsp;
                    <Typography
                      fontSize="18px"
                      fontWeight={theme.fontWeight.semiBold}
                    >
                      ADD TROUSER
                    </Typography>
                  </IconButton>
                )}
                {selectedProduct && (
                  <IconButton
                    onClick={() => onAddPantToCart("waist_coat")}
                    disabled={selectedProduct.stock_quantity < 1.2}
                    sx={{
                      backgroundColor: "black",
                      borderRadius: "10px",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "18px",
                      fontWeight: theme.fontWeight.semiBold,
                      marginTop: "20px",
                      maxWidth: { xs: "400px", sm: "448px" },
                      textAlign: "center",
                      textTransform: "none",
                      width: "250px",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                      "&:disabled": {
                        border: "2px solid lightgrey",
                        backgroundColor: "white",
                      },
                      height: "56px",
                    }}
                  >
                    <ShoppingCartOutlined />
                    &nbsp;&nbsp;
                    <Typography
                      fontSize="18px"
                      fontWeight={theme.fontWeight.semiBold}
                    >
                      ADD WAIST COAT
                    </Typography>
                  </IconButton>
                )}

                {selectedProduct && (
                  <IconButton
                    onClick={() => onAddPantToCart("blazer")}
                    disabled={selectedProduct.stock_quantity < 1.8}
                    sx={{
                      backgroundColor: "black",
                      borderRadius: "10px",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "18px",
                      fontWeight: theme.fontWeight.semiBold,
                      marginTop: "20px",
                      maxWidth: { xs: "400px", sm: "448px" },
                      textAlign: "center",
                      textTransform: "none",
                      width: "250px",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                      "&:disabled": {
                        border: "2px solid lightgrey",
                        backgroundColor: "white",
                      },
                      height: "56px",
                    }}
                  >
                    <ShoppingCartOutlined />
                    &nbsp;&nbsp;
                    <Typography
                      fontSize="18px"
                      fontWeight={theme.fontWeight.semiBold}
                    >
                      ADD BLAZER
                    </Typography>
                  </IconButton>
                )}

                {selectedProduct && (
                  <IconButton
                    onClick={() => onAddPantToCart("2_piece_suit")}
                    disabled={selectedProduct.stock_quantity < 3}
                    sx={{
                      backgroundColor: "black",
                      borderRadius: "10px",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "18px",
                      fontWeight: theme.fontWeight.semiBold,
                      marginTop: "20px",
                      maxWidth: { xs: "400px", sm: "448px" },
                      textAlign: "center",
                      textTransform: "none",
                      width: "250px",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                      "&:disabled": {
                        border: "2px solid lightgrey",
                        backgroundColor: "white",
                      },
                      height: "56px",
                    }}
                  >
                    <ShoppingCartOutlined />
                    &nbsp;&nbsp;
                    <Typography
                      fontSize="18px"
                      fontWeight={theme.fontWeight.semiBold}
                    >
                      ADD 2-PIECE SUIT
                    </Typography>
                  </IconButton>
                )}

                {selectedProduct && (
                  <IconButton
                    onClick={() => onAddPantToCart("3_piece_suit")}
                    disabled={selectedProduct.stock_quantity < 4.2}
                    sx={{
                      backgroundColor: "black",
                      borderRadius: "10px",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "18px",
                      fontWeight: theme.fontWeight.semiBold,
                      marginTop: "20px",
                      maxWidth: { xs: "400px", sm: "448px" },
                      textAlign: "center",
                      textTransform: "none",
                      width: "250px",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                      "&:disabled": {
                        border: "2px solid lightgrey",
                        backgroundColor: "white",
                      },
                      height: "56px",
                    }}
                  >
                    <ShoppingCartOutlined />
                    &nbsp;&nbsp;
                    <Typography
                      fontSize="18px"
                      fontWeight={theme.fontWeight.semiBold}
                    >
                      ADD 3-PIECE SUIT
                    </Typography>
                  </IconButton>
                )}
              </>
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              minWidth: "600px",
            }}
          >
            {selectedProduct && (
              <>
                {selectedProduct?.fabric_image_url?.includes("http") ? (
                  <LazyLoadImage
                    width="100%"
                    height="100%"
                    fetchpriority="high"
                    src={selectedProduct?.fabric_image_url}
                    alt={selectedProduct?.product_name}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      border: "1px solid lightgray",
                    }}
                  >
                    {" "}
                    <Typography
                      sx={{
                        fontSize: "24px",
                        position: "absolute",
                        right: "25%",
                        top: "50%",
                      }}
                    >
                      Image not available
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      <AlertControl />
    </>
  );
};

export default NewTrialRoom;
