/* eslint-disable no-empty-pattern */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const initialState = {
  access: null,
  loading: false,
};

export const accessSlice = createSlice({
  name: "access",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAccess.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAccess.fulfilled, (state, action) => {
        state.access = action.payload;
        state.loading = false;
      });
  },
});

export const fetchAccess = createAsyncThunk(
  "access/fetchAccess",
  async ({}, thunkAPI) => {
    const ordersRef = collection(db, "admins");
    const q = query(ordersRef);
    const querySnapshot = await getDocs(q);
    let userOrders = [];
    querySnapshot.forEach((doc) => {
      let oData = doc.data();
      oData.id = doc.id;
      userOrders.push(oData);
    });

    return userOrders;
  }
);

export default accessSlice.reducer;
