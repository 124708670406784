/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { doc, updateDoc, setDoc } from "firebase/firestore";
import {
  Button,
  InputLabel,
  Stack,
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { Field, Form, Formik, setNestedObjectValues } from "formik";
import { TextField } from "formik-mui";
import cloneDeep from "lodash/cloneDeep";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import { useAppDispatch } from "../../app/store";
import { fetchProduct } from "../../features/product/productSlice";
import { fetchProductsByCategories } from "../../features/products/productsSlice";
import colors from "../../constants/shirtcolors.json";
import patterns from "../../constants/shirtpatterns.json";
import { MessageDialog } from "../../components";
import { db } from "../../firebase";
/*Local Library End*/

/*Constant Variables Start*/
const inputContainerStyles = {
  height: "100px",
};

const inputLabelStyles = {
  color: "#000000",
  cursor: "pointer",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  marginBottom: "12px",
};

const validationSchema = yup.object({
  product_code: yup.string().required("Mandatory"),
  product_name: yup.string().required("Mandatory"),
  stock_quantity: yup.number().required().required("Mandatory"),
  price: yup.number().required().positive().integer().required("Mandatory"),
});
/*Constant Variables End*/

const AddShirt = () => {
  /*Standard Variables Start*/
  const {
    MessageDialogControl,
    setShowMessageDialog,
    setMessageForMessageDialog,
    setMessageDialogTitle,
    messageDialogTitle,
  } = MessageDialog();
  const {
    currentUser: { currentUser },
    user: { user },
    products: { productsByCategory },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [productId, setProductId] = useState("");
  const [initialFormValues, setInitialFormValues] = useState({
    product_code: "",
    product_name: "",
    product_description: "",
    material_care: "",
    stock_quantity: "",
    price: "",
    collection: "Classic",
    fabric_image_url: "",
    card_image_url: "",
    image_url: "",
    color: "black",
    pattern: "solid",
    regular_collor: "",
    single_cuff: "",
    double_cuff: "",
    pocket: "",
    full_sleeve: "",
    half_sleeve: "",
  });
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      dispatch(
        fetchProduct({
          category: "shirts",
          productId: id,
        })
      ).then((aProducts) => {
        const oNewValue = cloneDeep(aProducts.payload);
        oNewValue.regular_collor = oNewValue.collors[1].image_url;
        oNewValue.single_cuff = oNewValue.cuffs[0].image_url;
        oNewValue.double_cuff = oNewValue.cuffs[1].image_url;
        oNewValue.pocket = oNewValue.pockets[1].image_url;
        oNewValue.full_sleeve = oNewValue.sleeves[0].image_url;
        oNewValue.half_sleeve = oNewValue.sleeves[1].image_url;
        oNewValue.stock_quantity = parseFloat(oNewValue.stock_quantity).toFixed(
          2
        );
        setInitialFormValues(oNewValue);
        setProductId(id);
      });
    }
  }, [searchParams.get("id")]);

  useEffect(() => {
    if (user) {
      dispatch(
        fetchProductsByCategories({
          category: "shirts",
        })
      );
    }
  }, [user]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  const handleSubmitForm = async (product) => {
    if (currentUser) {
      const oProduct = {
        last_modfied: new Date().toString(),
        product_code: product.product_code,
        product_name: product.product_name,
        product_description: product.product_description,
        material_care: product.material_care,
        color: product.color,
        pattern: product.pattern,
        price: product.price.toString(),
        stock_quantity: parseFloat(product.stock_quantity).toFixed(2),
        collection: product.collection,
        fabric_image_url: product.fabric_image_url,
        card_image_url: product.card_image_url,
        image_url: product.image_url,
        collors: [
          {
            image_url: "",
            product_id: "c1",
            product_name: "No collar",
          },
          {
            image_url: product.regular_collor,
            product_id: "c2",
            product_name: "Regular collar",
          },
        ],
        cuffs: [
          {
            image_url: product.single_cuff,
            product_id: "c1",
            product_name: "Single button cuff",
          },
          {
            image_url: product.double_cuff,
            product_id: "c2",
            product_name: "Double button cuff",
          },
        ],
        pockets: [
          {
            image_url: "",
            product_id: "p1",
            product_name: "No pocket",
          },
          {
            image_url: product.pocket,
            product_id: "p2",
            product_name: "Pocket",
          },
        ],
        sleeves: [
          {
            image_url: product.full_sleeve,
            product_id: "s1",
            product_name: "Full sleeve",
          },
          {
            image_url: product.half_sleeve,
            product_id: "s2",
            product_name: "Half sleeve",
          },
        ],
      };
      if (productId === "") {
        let bFound = false;
        if (productsByCategory?.length > 0) {
          productsByCategory.some((oProduct) => {
            if (
              oProduct.product_code.toString() ===
              product.product_code.toString()
            ) {
              setMessageForMessageDialog(
                `Shirt code already exist: ${product.product_code}`
              );
              setMessageDialogTitle("Error");
              setShowMessageDialog(true);
              bFound = true;
              return false;
            } else if (
              oProduct.product_name.toLowerCase() ===
              product.product_name.toLowerCase()
            ) {
              setMessageForMessageDialog(
                `Shirt name already exist: ${product.product_name}`
              );
              setMessageDialogTitle("Error");
              setShowMessageDialog(true);
              bFound = true;
              return false;
            }
            return false;
          });
        }

        if (bFound) {
          return;
        }

        const productRef = doc(db, "shirts", product.product_code);
        setDoc(productRef, oProduct)
          .then(() => {
            setMessageForMessageDialog("Shirt added successfully!");
            setMessageDialogTitle("Success");
            setShowMessageDialog(true);
            setProductId(product.product_code.toString());
          })
          .catch((error) => {
            setMessageForMessageDialog(`Something bad happened: ${error}`);
            setMessageDialogTitle("Error");
            setShowMessageDialog(true);
          });
      } else {
        const productRef = doc(db, "shirts", productId);
        updateDoc(productRef, oProduct)
          .then((result) => {
            setMessageForMessageDialog("Shirt updated successfully!");
            setMessageDialogTitle("Success");
            setShowMessageDialog(true);
          })
          .catch((error) => {
            setMessageForMessageDialog(`Something bad happened: ${error}`);
            setMessageDialogTitle("Error");
            setShowMessageDialog(true);
          });
      }
    }
  };

  const onAfterMessageDialogClose = () => {
    if (messageDialogTitle === "Success" && productId) {
      navigate({
        pathname: `/shirt`,
        search: `?id=${productId}`,
      });
    }
  };
  /*Functions End*/

  return (
    <>
      <Stack
        sx={{
          marginTop: "150px",
          marginBottom: "50px",
          paddingLeft: {
            xs: theme.padding?.pagePaddingXS + "px",
            lg: theme.padding?.pagePaddingLG + "px",
            xl: theme.padding?.pagePaddingXL + "px",
          },
          paddingRight: {
            xs: theme.padding?.pagePaddingXS + "px",
            lg: theme.padding?.pagePaddingLG + "px",
            xl: theme.padding?.pagePaddingXL + "px",
          },
        }}
        rowGap="30px"
      >
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="flex-start"
          rowGap="60px"
          sx={{
            justifyContent: { xs: "center", lg1300: "center" },
            columnGap: { xs: "10px", lg: "25px", xl: "54px" },
          }}
        >
          <Formik
            initialValues={initialFormValues}
            onSubmit={() => {}}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ values, validateForm, setTouched }) => {
              return (
                <Form
                  id="ProductForm"
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    validateForm(values).then((error) => {
                      if (Object.keys(error).length > 0) {
                        setTouched(setNestedObjectValues(error, true));
                      } else {
                        handleSubmitForm(values);
                      }
                    });
                  }}
                >
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="start"
                    sx={{ gap: { xs: "10px 25px", xl: "15px 87px" } }}
                  >
                    {/* FIRST NAME */}
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="product_code"
                        sx={{ ...inputLabelStyles }}
                      >
                        PRODUCT CODE
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="product_code"
                        id="product_code"
                        disabled={productId !== ""}
                      />
                    </Box>
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="product_name"
                        sx={{ ...inputLabelStyles }}
                      >
                        PRODUCT NAME
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="product_name"
                        id="product_name"
                        disabled={false}
                      />
                    </Box>

                    {/* LAST NAME */}
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="product_description"
                        sx={{ ...inputLabelStyles }}
                      >
                        PRODUCT DESCRIPTION
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="product_description"
                        id="product_description"
                        disabled={false}
                      />
                    </Box>

                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="material_care"
                        sx={{ ...inputLabelStyles }}
                      >
                        MATERIAL & CARE
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="material_care"
                        id="material_care"
                        disabled={false}
                      />
                    </Box>

                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel htmlFor="price" sx={{ ...inputLabelStyles }}>
                        COLLECTION
                      </InputLabel>
                      <FormControl>
                        <Select
                          style={{ width: "230px" }}
                          value={initialFormValues.collection}
                          onChange={(event) => {
                            setInitialFormValues({
                              ...values,
                              collection: event.target.value,
                            });
                          }}
                        >
                          <MenuItem value="Classic">Classic</MenuItem>
                          <MenuItem value="Bold">Bold</MenuItem>
                          <MenuItem value="Linen">Linen</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel htmlFor="price" sx={{ ...inputLabelStyles }}>
                        PRICE
                      </InputLabel>
                      <FormControl>
                        <Field
                          component={TextField}
                          type="number"
                          name="price"
                          id="price"
                        />
                      </FormControl>
                    </Box>

                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel htmlFor="color" sx={{ ...inputLabelStyles }}>
                        COLOUR
                      </InputLabel>
                      <FormControl>
                        <Select
                          multiple
                          style={{ width: "230px" }}
                          renderValue={(selected) => {
                            let aColor = [];
                            selected.map((selectedColor) => {
                              colors.some((sColor) => {
                                if (selectedColor === sColor.id) {
                                  aColor.push(sColor.label);
                                  return true;
                                }
                                return false;
                              });
                            });
                            return aColor.join(", ");
                          }}
                          value={initialFormValues.color.split(",")}
                          onChange={(event) => {
                            setInitialFormValues({
                              ...values,
                              color: event.target.value.join(","),
                            });
                          }}
                        >
                          {colors &&
                            colors.map((oColor) => {
                              return (
                                <MenuItem key={oColor.id} value={oColor.id}>
                                  <Checkbox
                                    checked={
                                      initialFormValues.color
                                        .split(",")
                                        .indexOf(oColor.id) > -1
                                    }
                                  />
                                  <ListItemText primary={oColor.label} />
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="pattern"
                        sx={{ ...inputLabelStyles }}
                      >
                        PATTERN
                      </InputLabel>
                      <FormControl>
                        <Select
                          style={{ width: "230px" }}
                          value={initialFormValues.pattern}
                          onChange={(event) => {
                            setInitialFormValues({
                              ...values,
                              pattern: event.target.value,
                            });
                          }}
                        >
                          {patterns &&
                            patterns.map((oColor) => {
                              return (
                                <MenuItem key={oColor.id} value={oColor.id}>
                                  {oColor.label}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>

                    {/*  Email */}
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="stock_quantity"
                        sx={{ ...inputLabelStyles }}
                      >
                        METERS
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="number"
                        name="stock_quantity"
                        id="stock_quantity"
                        disabled={false}
                      />
                    </Box>
                  </Stack>

                  {/* <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="start"
                    sx={{
                      gap: { xs: "10px 25px", xl: "15px 87px" },
                      marginTop: "20PX",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <InputLabel
                        sx={{ ...inputLabelStyles, fontSize: "32px" }}
                      >
                        IMAGE'S URL
                      </InputLabel>
                    </Box>
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      justifyContent="start"
                      sx={{
                        gap: { xs: "10px 25px", xl: "15px 87px" },
                        marginTop: "20PX",
                      }}
                    >
                      <Box sx={{ ...inputContainerStyles }}>
                        <InputLabel
                          htmlFor="fabric_image_url"
                          sx={{ ...inputLabelStyles }}
                        >
                          FABRIC
                        </InputLabel>
                        <Field
                          component={TextField}
                          type="text"
                          name="fabric_image_url"
                          id="fabric_image_url"
                          disabled={false}
                        />
                      </Box>
                      <Box sx={{ ...inputContainerStyles }}>
                        <InputLabel
                          htmlFor="card_image_url"
                          sx={{ ...inputLabelStyles }}
                        >
                          CARD
                        </InputLabel>
                        <Field
                          component={TextField}
                          type="text"
                          name="card_image_url"
                          id="card_image_url"
                          disabled={false}
                        />
                      </Box>
                      <Box sx={{ ...inputContainerStyles }}>
                        <InputLabel
                          htmlFor="regular_collor"
                          sx={{ ...inputLabelStyles }}
                        >
                          REGULAR COLLAR
                        </InputLabel>
                        <Field
                          component={TextField}
                          type="text"
                          name="regular_collor"
                          id="regular_collor"
                          disabled={false}
                        />
                      </Box>
                      <Box sx={{ ...inputContainerStyles }}>
                        <InputLabel
                          htmlFor="single_cuff"
                          sx={{ ...inputLabelStyles }}
                        >
                          SINGLE BUTTON CUFF
                        </InputLabel>
                        <Field
                          component={TextField}
                          type="text"
                          name="single_cuff"
                          id="single_cuff"
                          disabled={false}
                        />
                      </Box>
                      <Box sx={{ ...inputContainerStyles }}>
                        <InputLabel
                          htmlFor="double_cuff"
                          sx={{ ...inputLabelStyles }}
                        >
                          DOUBLE BUTTON CUFF
                        </InputLabel>
                        <Field
                          component={TextField}
                          type="text"
                          name="double_cuff"
                          id="double_cuff"
                          disabled={false}
                        />
                      </Box>
                      <Box sx={{ ...inputContainerStyles }}>
                        <InputLabel
                          htmlFor="pocket"
                          sx={{ ...inputLabelStyles }}
                        >
                          POCKET
                        </InputLabel>
                        <Field
                          component={TextField}
                          type="text"
                          name="pocket"
                          id="pocket_image"
                          disabled={false}
                        />
                      </Box>
                      <Box sx={{ ...inputContainerStyles }}>
                        <InputLabel
                          htmlFor="full_sleeve"
                          sx={{ ...inputLabelStyles }}
                        >
                          FULL SLEEVE
                        </InputLabel>
                        <Field
                          component={TextField}
                          type="text"
                          name="full_sleeve"
                          id="full_sleeve"
                          disabled={false}
                        />
                      </Box>
                      <Box sx={{ ...inputContainerStyles }}>
                        <InputLabel
                          htmlFor="half_sleeve"
                          sx={{ ...inputLabelStyles }}
                        >
                          HALF SLEEVE
                        </InputLabel>
                        <Field
                          component={TextField}
                          type="text"
                          name="half_sleeve"
                          id="half_sleeve"
                          disabled={false}
                        />
                      </Box>
                    </Stack>
                  </Stack> */}
                </Form>
              );
            }}
          </Formik>
        </Stack>

        <Stack
          direction="row"
          width="max-content"
          maxWidth="100%"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          rowGap="20px"
          sx={{ columnGap: { xs: "10px", lg: "26px" } }}
        >
          {currentUser?.access?.product_edit_btn && (
            <Button
              type="submit"
              form="ProductForm"
              sx={{
                borderRadius: "10px",
                backgroundColor: "black",
                color: "white",
                fontSize: "18px",
                fontWeight: theme.fontWeight.semiBold,
                textAlign: "center",
                textTransform: "none",
                width: "203px",
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
            >
              {productId === "" ? "ADD SHIRT" : "UPDATE SHIRT"}
            </Button>
          )}
          <Button
            onClick={() => {
              if (productId) {
                navigate({
                  pathname: `/shirt`,
                  search: `?id=${productId}`,
                });
              } else {
                navigate({
                  pathname: `/shirts`,
                });
              }
            }}
            sx={{
              borderRadius: "10px",
              backgroundColor: "white",
              color: "black",
              fontSize: "18px",
              fontWeight: theme.fontWeight.semiBold,
              textAlign: "center",
              textTransform: "none",
              border: "2px solid black",
              width: "203px",
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            CANCEL
          </Button>
        </Stack>
      </Stack>
      <MessageDialogControl onAfterClose={onAfterMessageDialogClose} />
    </>
  );
};

export default AddShirt;
