/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import { writeBatch, doc } from "firebase/firestore";
/*Standard Library End*/

/*Local Library Start*/
import { useAppDispatch } from "../../app/store";
import { fetchProductsByCategories } from "../../features/products/productsSlice";
import SearchBar from "../../components/SearchBar";
import theme from "../../theme";
import { db } from "../../firebase";
import ProductCard from "../ProductCard";
import suiting from "../../constants/suiting.json";
import suitingClassic from "../../constants/suitingClassic.json";
import suitingBold from "../../constants/suitingBold.json";
/*Local Library End*/

const ProductList = () => {
  /*Standard Variables Start*/
  const { pathname } = useLocation();
  const {
    currentUser,
    products: { productsByCategory },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (currentUser?.currentUser?.access?.products) {
      dispatch(
        fetchProductsByCategories({
          category: pathname.substring(1),
        })
      );
    }
  }, [pathname, currentUser?.currentUser?.access?.products]);

  useEffect(() => {
    if (productsByCategory) {
      setRows(productsByCategory);
    }
  }, [productsByCategory]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  const requestSearch = (searchedVal) => {
    const filteredRows = productsByCategory?.filter((row) => {
      return (
        row?.product_description
          ?.toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        row?.price
          .toString()
          ?.toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        row?.pattern?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row?.material_care?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row?.collection?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row?.product_code?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row?.product_name?.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  const updateProducts = async () => {
    let aProductData = productsByCategory;
    if (aProductData.length > 0) {
      const batch = writeBatch(db);
      aProductData.map((oProduct) => {
        let sfRef = doc(db, pathname.substring(1), oProduct.id);

        let oNewUserObject = {
          ...oProduct,
        };

        if (pathname.substring(1) === "pants") {
          let aPrice = [];

          if (oNewUserObject.collection === "Classic") {
            suiting.forEach((oSuiting) => {
              let oFoundObj = suitingClassic.find(
                (o) => o.category === oSuiting.category
              );

              aPrice.push({
                ...oSuiting,
                price: oFoundObj.price,
              });
            });
          } else if (oNewUserObject.collection === "Bold") {
            suiting.forEach((oSuiting) => {
              let oFoundObj = suitingBold.find(
                (o) => o.category === oSuiting.category
              );

              aPrice.push({
                ...oSuiting,
                price: oFoundObj.price,
              });
            });
          } else {
            suiting.forEach((oSuiting) => {
              if (oSuiting.category === "trouser") {
                let oFoundObj = suitingClassic.find(
                  (o) => o.category === oSuiting.category
                );

                aPrice.push({
                  ...oSuiting,
                  price: oFoundObj.price,
                });
              }
            });
          }
          oNewUserObject.price = aPrice;
          batch.update(sfRef, oNewUserObject);
        }
      });

      await batch.commit();
      alert("Update complete!");
    }
  };
  /*Functions End*/

  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <Button
        sx={{
          borderRadius: "10px",
          backgroundColor: "black",
          color: "white",
          fontSize: "18px",
          fontWeight: theme.fontWeight.semiBold,
          textAlign: "center",
          height: "49px",
          width: "203px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "black",
          },
          marginBottom: "20px",
          display: "none",
        }}
        onClick={updateProducts}
      >
        UPDATE PRODUCT'S
      </Button>
      <SearchBar
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        setSearched={setSearched}
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: { xs: "center", sm: "flex-start" },
          columnGap: {
            xs: "13px",
            md: "25px",
          },
          rowGap: {
            xs: "35px",
            md: "70px",
          },
          minHeight: "500px",
        }}
      >
        <LazyLoadComponent>
          {rows?.length > 0 && <ProductCard products={rows} />}
        </LazyLoadComponent>
        {rows?.length === 0 && <>No products found!</>}
      </Box>
    </Box>
  );
};

export default ProductList;
