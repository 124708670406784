/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { doc, writeBatch } from "firebase/firestore";
import { CSVLink } from "react-csv";
/*Standard Library End*/

/*Local Library Start*/
import SearchBar from "../../components/SearchBar";
import theme from "../../theme";
import { db } from "../../firebase";
import { fetchOrders } from "../../features/orders/ordersSlice";
import { useAppDispatch } from "../../app/store";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const columns = [
  { id: "order_id", label: "ORDER #", minWidth: 100 },
  { id: "name", label: "NAME", minWidth: 170 },
  { id: "mobile", label: "MOBILE", minWidth: 100 },
  { id: "payment_status", label: "PAYMENT", minWidth: 200 },
  { id: "total_amount", label: "AMOUNT", minWidth: 100 },
  { id: "status", label: "STATUS", minWidth: 170 },
  {
    id: "created_on",
    label: "ORDER DATE",
    minWidth: 170,
  },
];
/*Constant Variables End*/

const aExcelHeaders = [
  { label: "Order #", key: "order_id" },
  { label: "Amount", key: "total_amount" },
  { label: "Date", key: "created_on" },
];

const Orders = () => {
  /*Standard Variables Start*/
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    currentUser,
    orders: { orders },
  } = useSelector((state) => state);
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (currentUser?.currentUser?.access?.orders) {
      dispatch(fetchOrders({}));
    }
  }, [currentUser?.currentUser?.access?.orders]);

  useEffect(() => {
    if (orders) {
      setRows(orders);
    }
  }, [orders]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = orders.filter((row) => {
      return (
        row?.order_id?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row?.name?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row?.mobile?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row?.total_amount?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row?.status?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row?.created_on?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row?.payment_status?.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  const getPaymentColor = (sId, sValue) => {
    let sColor = "";
    if ("Pending" === sValue) {
      sColor = "red";
    } else if ("Payment not received" === sValue) {
      sColor = "red";
    } else if ("Partial payment received" === sValue) {
      sColor = "orange";
    } else {
      sColor = "#2a982a";
    }
    return (
      <Typography
        sx={{
          color: sColor,
          fontSize: "18px",
        }}
      >
        {sValue}
      </Typography>
    );
  };

  const getStatusColor = (sId, sValue) => {
    let sColor = "";
    if ("Order Delivered" === sValue) {
      sColor = "#2a982a";
    } else if ("Order Shipped" === sValue) {
      sColor = "orange";
    } else {
      sColor = "red";
    }
    return (
      <Typography
        sx={{
          color: sColor,
          fontSize: "18px",
        }}
      >
        {sValue === "Order Shipped" ? "Order Ready" : sValue}
      </Typography>
    );
  };

  const getUnit = (id, value) => {
    if (id === "total_amount") {
      return "₹" + parseFloat(value).toFixed(2);
    }
    return value;
  };

  const updateOrders = async () => {
    let OrdersWithoutVersion = orders.filter((order) => {
      return !order.order_version;
    });

    let OrdersV2 = orders.filter((order) => {
      return order.order_version === "v2";
    });

    let updateOrders = [];

    OrdersWithoutVersion.map((oOrder) => {
      let oNewOrder = Object.assign({}, oOrder);
      oNewOrder.order_version = 1;
      oNewOrder.mobile = "+91" + oNewOrder.mobile;
      updateOrders.push(oNewOrder);
    });

    OrdersV2.map((oOrder) => {
      let oNewOrder = Object.assign({}, oOrder);
      oNewOrder.order_version = 2;
      oNewOrder.mobile = "+91" + oNewOrder.mobile;
      updateOrders.push(oNewOrder);
    });

    if (updateOrders.length === 0) {
      alert("No Updates!");
      return;
    }
    const batch = writeBatch(db);

    updateOrders.map((oUpdateOrder) => {
      const sfRef = doc(db, "orders", oUpdateOrder.order_id);

      batch.update(sfRef, oUpdateOrder);
    });

    await batch.commit();
    alert("Update complete!");
  };
  /*Functions End*/

  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Button
            sx={{ marginBottom: "20px", ...stepperButtonStyles }}
            onClick={() => {
              navigate("/");
            }}
            startIcon={<ArrowBackIcon />}
          >
            BACK
          </Button>
          {currentUser?.currentUser?.access?.orders_download && (
            <CSVLink
              filename={"Orders.csv"}
              data={rows}
              headers={aExcelHeaders}
            >
              {" "}
              <Button
                sx={{
                  marginLeft: "20px",
                  marginBottom: "20px",
                  ...stepperButtonStyles,
                }}
              >
                DOWNLOAD EXCEL
              </Button>
            </CSVLink>
          )}
        </Box>
        <Button
          sx={{
            marginBottom: "20px",
            ...stepperButtonStyles,
            display: "none",
          }}
          onClick={updateOrders}
        >
          UPDATE ORDERS
        </Button>
        <Box>
          <Button
            sx={{
              marginBottom: "20px",
              marginRight: "20px",
              ...stepperButtonStyles,
            }}
            onClick={() => {
              navigate({ pathname: "/create-new-order" });
            }}
          >
            FABRIC + STITCH
          </Button>
          <Button
            sx={{ marginBottom: "20px", ...stepperButtonStyles }}
            onClick={() => {
              navigate({ pathname: "/create-stitch-order" });
            }}
          >
            STITCH ONLY
          </Button>
        </Box>
      </Box>
      <SearchBar
        setSearched={setSearched}
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
      />
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const sSearch = `?id=${row.order_id}`;
                    return (
                      <TableRow
                        onClick={() => {
                          if (row.stitch_only) {
                            navigate({
                              pathname: "/order-stitch-detail",
                              search: sSearch,
                            });
                          } else {
                            navigate({
                              pathname: "/order-detail",
                              search: sSearch,
                            });
                          }
                        }}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.order_id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.id === "status") {
                            return (
                              <TableCell
                                style={{
                                  fontSize: "18px",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {getStatusColor(column.id, value)}
                              </TableCell>
                            );
                          } else if (column.id === "payment_status") {
                            return (
                              <TableCell
                                style={{
                                  fontSize: "18px",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {getPaymentColor(column.id, value)}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                style={{
                                  fontSize: "18px",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {getUnit(column.id, value)}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={orders ? orders.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default Orders;
