/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Box,
  TextField,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { getFunctions, httpsCallable } from "firebase/functions";
/*Standard Library End*/

/*Local Library Start*/
import { SearchBar } from "../../components";
import theme from "../../theme";
import {
  fetchMessages,
  setMessagesLoading,
} from "../../features/messages/messagesSlice";
import { useAppDispatch } from "../../app/store";
import { MessageAlert } from "../../components";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const columns = [
  { id: "mobile", label: "MOBILE", minWidth: 200 },
  { id: "order_id", label: "ORDER #", minWidth: 200 },
  {
    id: "message",
    label: "MESSAGE",
    minWidth: 500,
  },
  { id: "action", label: "", minWidth: 200 },
];
/*Constant Variables End*/

const WhatsappMessages = () => {
  /*Standard Variables Start*/
  const {
    currentUser,
    messages: { messages },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { AlertControl, setAlertMessage, setAlertSeverity, setShowAlert } =
    MessageAlert();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (messages) {
      setRows(messages);
    }
  }, [messages]);

  useEffect(() => {
    if (currentUser?.currentUser?.access?.messages) {
      dispatch(fetchMessages({}));
    }
  }, [currentUser?.currentUser?.access?.messages]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = messages.filter((row) => {
      return (
        row.mobile.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.order_id
          .toString()
          .toLowerCase()
          .includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  const getRowValue = (id, value) => {
    if (id === "message") {
      let sMessage = value.replaceAll("%0a%0a", "\n\n");
      sMessage = sMessage.replaceAll("%0a", "\n");
      sMessage = sMessage.replaceAll("*", "");
      return (
        <TextField
          disabled
          sx={{ width: "100%" }}
          multiline
          rows={5}
          value={sMessage}
          fontSize="18px"
          fontWeight={theme.fontWeight.regular}
        />
      );
    }
    return value;
  };

  const onSendMessages = (oRow) => {
    const functions = getFunctions();
    const onRetryWhatsappMessage = httpsCallable(
      functions,
      "onRetryWhatsappMessage"
    );
    dispatch(setMessagesLoading(true));
    onRetryWhatsappMessage(oRow)
      .then((result) => {
        dispatch(setMessagesLoading(false));
        if (result.data) {
          dispatch(fetchMessages({}));
          setAlertMessage("Message sent successfully!");
          setAlertSeverity("success");
          setShowAlert(true);
        } else {
          setAlertMessage(
            "Error while sending message. Please try again later."
          );
          setAlertSeverity("error");
          setShowAlert(true);
        }
      })
      .catch((oError) => {
        setAlertMessage("Error while sending message. Please try again later.");
        setAlertSeverity("error");
        setShowAlert(true);
        dispatch(setMessagesLoading(false));
        console.log(oError);
      });
  };
  /*Functions End*/

  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <Button
        sx={{ marginBottom: "20px", ...stepperButtonStyles }}
        onClick={() => {
          navigate("/");
        }}
        startIcon={<ArrowBackIcon />}
      >
        BACK
      </Button>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          setSearched={setSearched}
        />
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <>
                              {column.id === "action" &&
                              currentUser?.currentUser?.access?.messages &&
                              currentUser?.currentUser?.access
                                ?.coupon_edit_btn ? (
                                <TableCell key={column.id} align={column.align}>
                                  <Button
                                    onClick={() => onSendMessages(row)}
                                    style={{
                                      marginTop: "20px",
                                      ...stepperButtonStyles,
                                    }}
                                  >
                                    RE-SEND
                                  </Button>
                                </TableCell>
                              ) : (
                                <TableCell
                                  style={{
                                    fontSize: "18px",
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  {getRowValue(column.id, value)}
                                </TableCell>
                              )}
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={messages ? messages.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <AlertControl />
    </Box>
  );
};

export default WhatsappMessages;
