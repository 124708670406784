import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../firebase";
import { mapWithOrderProductIds } from "./helpers";
import { doc, getDoc } from "firebase/firestore";

const initialState = {
  stitchOrder: null,
  order: null,
  loading: false,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderLoading(state, actions) {
      state.loading = actions.payload;
    },
    setStoredOrder(state, actions) {
      state.order = actions.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.order = action.payload;
        state.loading = false;
      })
      .addCase(fetchStitchOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStitchOrder.fulfilled, (state, action) => {
        state.stitchOrder = action.payload;
        state.loading = false;
      });
  },
});

export const fetchOrder = createAsyncThunk(
  "order/fetchOrder",
  async ({ orderId, products }, thunkAPI) => {
    const ordersRef = doc(db, "orders", orderId);
    const order = await getDoc(ordersRef);

    return mapWithOrderProductIds(products, order.data());
  }
);

export const fetchStitchOrder = createAsyncThunk(
  "order/fetchStitchOrder",
  async ({ orderId }, thunkAPI) => {
    const ordersRef = doc(db, "orders", orderId);
    const order = await getDoc(ordersRef);

    let Orders = order.data()
    Orders.products = Orders.products.map((oProduct) => {
      oProduct.sub_total = oProduct.quantity * oProduct.price;
      return oProduct;
    });

    return Orders;
  }
);

export default orderSlice.reducer;
export const { setOrderLoading, setStoredOrder } = orderSlice.actions;
