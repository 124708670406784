/*Standard Library Start*/
import React, { useState } from "react";
import {
  Stack,
  Typography,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  TextField as TextMUI,
} from "@mui/material";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  width: "203px",
  height: "49px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};
/*Constant Variables End*/

const NewOrderPayment = () => {
  /*Standard Variables Start*/
  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false);
  /*Standard Variables End*/

  /*Functions Start*/

  const UpdatePaymentControl = ({
    oOrder,
    remainingAmount,
    onConfirmOrder,
  }) => {
    const [remaining, setRemaining] = useState(remainingAmount);
    const [paymentMode, setPaymentMode] = useState("");
    const [amountError, setAmountError] = useState("");
    const [paymentModeError, setPaymentModeError] = useState("");

    const onUpdatePayment = (
      remainingAmount,
      paymentMode,
      setPaymentModeError,
      setAmountError
    ) => {
      let bError = false;
      if (remainingAmount === "") {
        bError = true;
        setAmountError("Please enter amount");
      }
      
      if(parseInt(remainingAmount) === 0){
        onConfirmOrder();
        setShowUpdatePaymentDialog(false);
        return;
      }

      if (paymentMode === "") {
        bError = true;
        setPaymentModeError("Please select payment mode");
      }

      if (bError) {
        return;
      }

      setAmountError("");
      setPaymentModeError("");

      let sPaid = parseFloat(remainingAmount);

      if (sPaid > parseFloat(oOrder.totalCost)) {
        setAmountError("Amount is larger than total amount");
        return;
      }

      let sAmountPaid = 0;
      let sPaymentStatus = "";
      sAmountPaid = parseFloat(remainingAmount);
      if (parseFloat(oOrder.totalCost) === sAmountPaid) {
        sPaymentStatus = "Payment received";
      } else if (parseFloat(oOrder.totalCost) > sAmountPaid) {
        sPaymentStatus = "Partial payment received";
      }

      let aPaymentHistory = [{
        last_modified: new Date().toString(),
        payment_mode: paymentMode,
        amount_paid: remainingAmount,
      }];

      onConfirmOrder(aPaymentHistory, sPaymentStatus, sAmountPaid);
      setShowUpdatePaymentDialog(false);
    };

    return (
      <>
        <Dialog open={showUpdatePaymentDialog}>
          <DialogContent>
            <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
              TOTAL AMOUNT: &#8377; {parseFloat(remainingAmount).toFixed(2)}{" "}
            </Typography>
            <Stack rowGap="5px">
              <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
                REMAINING AMOUNT:
              </Typography>
              <TextMUI
                type="number"
                value={remaining}
                onChange={(event) => {
                  setRemaining(event.target.value);
                  setAmountError("");
                }}
                sx={{
                  marginTop: "10px",
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${amountError && "#d32f2f"}`,
                  },
                  "& .MuiFormHelperText-root": {
                    color: "#d32f2f",
                  },
                }}
                helperText={amountError && amountError}
              />
              <Typography
                sx={{
                  display: "inline-flex",
                  textAlign: { xs: "center", sm768: "initial" },
                }}
              >
                PAYMENT MODE:
              </Typography>
              <FormControl error={paymentModeError}>
                <Select
                  onChange={(event) => {
                    setPaymentMode(event.target.value);
                    setPaymentModeError("");
                  }}
                  value={paymentMode}
                  sx={{
                    marginTop: "10px",
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${amountError && "#d32f2f"}`,
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#d32f2f",
                    },
                  }}
                >
                  <MenuItem key="cash" value="Cash">
                    Cash
                  </MenuItem>
                  <MenuItem key="UPI" value="UPI">
                    UPI
                  </MenuItem>
                  <MenuItem key="PG" value="Payment Gateway">
                    Payment Gateway
                  </MenuItem>
                </Select>
                <FormHelperText>{paymentModeError}</FormHelperText>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                backgroundColor: "hite",
                borderRadius: "10px",
                color: "black",
                display: "flex",
                alignItems: "center",
                fontSize: "18px",
                fontWeight: theme.fontWeight.semiBold,
                maxWidth: { xs: "400px", sm: "448px" },
                textAlign: "center",
                textTransform: "none",
                width: "203px",
                height: "49px",
                border: "2px solid black",
              }}
              onClick={() => {
                onUpdatePayment(
                  remaining,
                  paymentMode,
                  setPaymentModeError,
                  setAmountError
                );
              }}
            >
              SAVE
            </Button>
            <Button
              sx={{
                ...stepperButtonStyles,
              }}
              onClick={() => {
                setShowUpdatePaymentDialog(false);
              }}
            >
              CANCEL
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  /*Functions End*/

  return {
    UpdatePaymentControl,
    setShowUpdatePaymentDialog,
  };
};

export default NewOrderPayment;
