/* eslint-disable array-callback-return */

/*Standard Library Start*/
import { Delete as DeleteIcon } from "@mui/icons-material";
import cloneDeep from "lodash/cloneDeep";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  TextField,
} from "@mui/material";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import { MessageAlert } from "../../components";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: "bold",
  textalign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};
/*Constant Variables End*/

const ShoppingCartStitch = ({ setActiveStep, order, setOrder }) => {
  const { AlertControl, setAlertMessage, setAlertSeverity, setShowAlert } =
    MessageAlert();

  /*Local Variables Start*/
  /*Local Variables End*/

  /*Functions Start*/
  const getTotal = (products) => {
    let total = 0;

    products.forEach((oProduct) => {
      let sPrice = 0;
      let sQuantity = 0;
      if (oProduct.price) {
        sPrice = oProduct.price;
      }

      if (oProduct.quantity) {
        sQuantity = oProduct.quantity;
      }

      let subTotal = sPrice * sQuantity;
      total = total + parseFloat(subTotal);
    });
    total = +total.toFixed(2);

    return {
      totalCost: total,
      discount: 0,
    };
  };

  const onFieldChange = (event, sField, productIndex) => {
    setShowAlert(false);
    let oOrder = {
      products: [],
      discount: 0,
      totalCost: 0,
    };
    if (order?.products) {
      order.products.map((oProduct, oProductIndex) => {
        let oNewProduct = cloneDeep(oProduct);
        if (oProductIndex === productIndex) {
          if (sField === "price") {
            oNewProduct[sField] = parseFloat(event.target.value);
          } else if (sField === "quantity") {
            oNewProduct[sField] = parseInt(event.target.value);
          } else {
            oNewProduct[sField] = event.target.value;
          }
        }

        let sPrice = 0;
        let sQuantity = 0;
        if (oNewProduct.price) {
          sPrice = oNewProduct.price;
        }

        if (oNewProduct.quantity) {
          sQuantity = oNewProduct.quantity;
        }

        oNewProduct.sub_total = sPrice * sQuantity;
        oOrder.products.push(oNewProduct);
      });

      let oReturn = getTotal(oOrder.products);
      oOrder.totalCost = oReturn.totalCost;
      oOrder.discount = oReturn.discount;
    }
    setOrder((prevState) => {
      const oClonedOrder = cloneDeep(prevState);
      oClonedOrder.products = oOrder.products;
      oClonedOrder.totalCost = oOrder.totalCost;
      oClonedOrder.discount = oOrder.discount;
      return oClonedOrder;
    });
  };

  const onAddItem = () => {
    let newElement = {
      price: "",
      category: "",
      product_detail: "",
      quantity: 1,
      sub_total: 0,
    };

    setOrder((prevState) => {
      const oOrder = cloneDeep(prevState);
      if (oOrder?.products) {
        oOrder.products.push(newElement);
      } else {
        oOrder.products = [newElement];
      }
      return oOrder;
    });
  };
  /*Functions End*/

  return (
    <>
      <>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box></Box>
          <Button
            sx={{ marginBottom: "20px", ...stepperButtonStyles }}
            onClick={onAddItem}
          >
            ADD ITEM
          </Button>
        </Box>
        <Box sx={{ minHeight: "200px" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      width: "200px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    ITEM
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    DETAILS
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "150px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    QUANTITY
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "200px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    PRICE
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    SUB-TOTAL
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order?.products?.map((product, productIndex) => {
                  return (
                    <TableRow
                      key={productIndex}
                      sx={{ "& td": { borderBottom: 0 } }}
                    >
                      <TableCell>
                        <TextField
                          sx={{
                            marginBottom: "43px",
                          }}
                          value={product.category}
                          fontSize="18px"
                          fontWeight={theme.fontWeight.regular}
                          onChange={(event) => {
                            onFieldChange(event, "category", productIndex);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          sx={{ width: "100%" }}
                          multiline
                          rows={3}
                          value={product.product_detail}
                          fontSize="18px"
                          fontWeight={theme.fontWeight.regular}
                          onChange={(event) => {
                            onFieldChange(
                              event,
                              "product_detail",
                              productIndex
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          sx={{
                            marginBottom: "43px",
                          }}
                          value={product.quantity}
                          fontSize="18px"
                          fontWeight={theme.fontWeight.regular}
                          type="number"
                          onChange={(event) => {
                            onFieldChange(event, "quantity", productIndex);
                          }}
                        />
                      </TableCell>

                      <TableCell>
                        <Typography
                          sx={{
                            marginBottom: "43px",
                          }}
                          fontSize="18px"
                          fontWeight={theme.fontWeight.regular}
                          textalign="center"
                        >
                          <TextField
                            value={product.price}
                            fontSize="18px"
                            fontWeight={theme.fontWeight.regular}
                            textalign="center"
                            type="number"
                            onChange={(event) => {
                              onFieldChange(event, "price", productIndex);
                            }}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            marginBottom: "43px",
                          }}
                          fontSize="18px"
                          fontWeight={theme.fontWeight.semiBold}
                        >
                          &#8377;{product.sub_total.toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <DeleteIcon
                          sx={{ color: "#000000", cursor: "pointer" }}
                          onClick={() => {
                            let oOrder = {
                              products: [],
                              discount: 0,
                              totalCost: 0,
                            };
                            if (order?.products) {
                              oOrder.products = order.products.filter(
                                (oProduct, oProductIndex) => {
                                  if (oProductIndex !== productIndex) {
                                    return oProduct;
                                  }
                                }
                              );

                              let oReturn = getTotal(oOrder.products);
                              oOrder.totalCost = oReturn.totalCost;
                              oOrder.discount = oReturn.discount;
                            }
                            setOrder((prevState) => {
                              const oClonedOrder = cloneDeep(prevState);
                              oClonedOrder.products = oOrder.products;
                              oClonedOrder.totalCost = oOrder.totalCost;
                              oClonedOrder.discount = oOrder.discount;
                              return oClonedOrder;
                            });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Stack
          rowGap="20px"
          columnGap="20px"
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{
            marginTop: "20px",
            justifyContent: {
              xs: "space-between",
              md: "space-between",
              lg: "space-between",
            },
          }}
        >
          <Stack
            direction="row"
            width="max-content"
            maxWidth="100%"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
            rowGap="20px"
            sx={{ columnGap: { xs: "10px", lg: "26px" } }}
          >
            {order?.products?.length > 0 && (
              <Button
                sx={{
                  ...stepperButtonStyles,
                }}
                onClick={(event) => {
                  let bError = false;

                  order?.products?.some((product) => {
                    if (
                      product.category === "" ||
                      product.quantity === 0 ||
                      product.quantity === "" ||
                      product.price === 0 ||
                      product.price === "" ||
                      isNaN(product.price)
                    ) {
                      bError = true;
                    }
                    return bError;
                  });

                  if (bError) {
                    setAlertMessage(
                      "Missing mandatory fields. Mandatory fields: Item, Quanity and Price"
                    );
                    setAlertSeverity("error");
                    setShowAlert(true);
                    return;
                  }
                  if (order.order_id) {
                    setActiveStep((previousState) => previousState + 2);
                  } else {
                    setActiveStep((previousState) => previousState + 1);
                  }
                }}
              >
                NEXT
              </Button>
            )}
          </Stack>

          <Stack
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
            rowGap="10px"
            sx={{
              columnGap: { xs: "10px", lg: "20px" },
              flexDirection: { xs: "row", md: "column" },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              columnGap="33px"
              width="300px"
              height="49px"
              sx={{ backgroundColor: "#F1F1F1", borderRadius: "10px" }}
            >
              <Typography fontWeight={theme.fontWeight.regular} fontSize="18px">
                CART VALUE:
              </Typography>
              <Typography fontWeight={"bold"} fontSize="18px">
                &#8377;{order.totalCost.toFixed(2)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </>

      <AlertControl />
    </>
  );
};

export default ShoppingCartStitch;
