import React, { useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import theme from "../../theme";
import { styled } from "@mui/material/styles";

/*Constant Variable Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const stepperButtonStyles2 = {
  border: "2px solid black",
  borderRadius: "10px",
  backgroundColor: "white",
  color: "black",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
/*Constant Variable End*/

const ConfirmationDialog = () => {
  /*Standard Variable Start*/
  /*Standard Variable End*/

  /*Local Variable Start*/
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [acceptButtonText, setAcceptButtonText] = useState("");
  const [rejectButtonText, setRejectButtonText] = useState("");
  const [confirmationDialogMessage, setConfirmationDialogMessage] =
    useState("");
  /*Variables End*/

  /*OnLoad Start*/
  /*OnLoad End*/

  /*Functions Start*/
  const handleConfirmationDialogClose = () => {
    setShowConfirmationDialog(false);
  };
  /*Functions End*/

  /*UI Start*/

  const ConfirmationDialogControl = ({ onAcceptButtonPress, onAfterClose }) => (
    <BootstrapDialog
      onClose={() => {
        handleConfirmationDialogClose();
        if (onAfterClose) {
          onAfterClose();
        }
      }}
      open={showConfirmationDialog}
    >
      <DialogTitle
        sx={{
          fontSize: "24px",
        }}
      >
        Cofirmation
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: theme.fontWeight.bold,
          }}
        >
          {confirmationDialogMessage}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            ...stepperButtonStyles2,
          }}
          onClick={() => {
            handleConfirmationDialogClose();
            if (onAfterClose) {
              onAfterClose();
            }
          }}
        >
          {rejectButtonText}
        </Button>
        <Button
          onClick={() => {
            handleConfirmationDialogClose();
            if (onAcceptButtonPress) {
              onAcceptButtonPress();
            }
          }}
          sx={{
            ...stepperButtonStyles,
          }}
        >
          {acceptButtonText}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
  /*UI End*/

  return {
    ConfirmationDialogControl,
    setShowConfirmationDialog,
    setConfirmationDialogMessage,
    setAcceptButtonText,
    setRejectButtonText,
  };
};

export default ConfirmationDialog;
