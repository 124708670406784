/*Standard Library Start*/
import { Box, TextField } from "@mui/material";
/*Standard Library End*/

const SearchBar = ({ value, onChange, setSearched }) => {
  return (
    <Box
      sx={{
        marginBottom: "20px",
      }}
    >
      <TextField
        value={value}
        onChange={(event) => {
          setSearched(event.target.value);
          onChange(event.target.value);
        }}
        sx={{
          width: "500px",
        }}
        id="outlined-basic"
        label="SEARCH"
        variant="outlined"
      />
    </Box>
  );
};

export default SearchBar;
