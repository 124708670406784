import { Box } from "@mui/material";

const pageStylesExceptHome = {
  marginTop: "80px",
};

const AppWrapper = ({ children }) => {
  return <Box sx={pageStylesExceptHome}>{children}</Box>;
};

export default AppWrapper;
