/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import { useEffect, useState } from "react";
import { Stack, Button, Typography } from "@mui/material";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import AddressForm from "../AddressForm";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};
/*Constant Variables End*/

const AddressesContainer = ({
  activeStep,
  setActiveStep,
  editMode,
  setEditMode,
  selectedAddress,
  setSelectedAddress,
  addressIndex,
  setAddressIndex,
  refreshUser,
  aAddress = [],
}) => {
  /*Local Variables Start*/
  const [addresses, setAddresses] = useState(aAddress);
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (aAddress?.length > 0) {
      setAddresses(aAddress);
    } else {
      setAddresses([]);
    }
  }, [aAddress]);
  /*OnLoad Functions End*/

  return (
    <>
      {editMode ? (
        <AddressForm
          setEditMode={setEditMode}
          selectedAddress={selectedAddress}
          addressIndex={addressIndex}
          refreshUser={refreshUser}
        />
      ) : (
        <>
          {addresses?.map((oAddress, iIndex) => (
            <Stack
              key={iIndex}
              rowGap="10px"
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              sx={{
                border: "2px dashed grey",
                padding: "10px",
                marginBottom: "10px",
              }}
            >
              <Stack>
                <Typography>{`${oAddress.first_name} ${oAddress.last_name}`}</Typography>

                <Typography>{`${oAddress.address}, ${oAddress.city}, ${oAddress.pin_code}`}</Typography>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>Mobile: </span>
                  {`${oAddress?.mobile.substring(3)}`}
                </Typography>
                <Typography>
                  Preferred Time Slot:&nbsp;
                  {`${oAddress?.time_slot}`}
                </Typography>
                {setActiveStep && (
                  <Button
                    sx={{
                      ...stepperButtonStyles,
                      marginTop: "20px",
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      setActiveStep((previousState) => previousState + 1);
                      setAddressIndex(iIndex);
                      setSelectedAddress(addresses[iIndex]);
                    }}
                  >
                    DELIVER HERE
                  </Button>
                )}
              </Stack>
              <Button
                onClick={() => {
                  setAddressIndex(iIndex);
                  setEditMode(true);
                  let oAddress = Object.assign({}, addresses[iIndex]);
                  oAddress.mobile = oAddress.mobile.substring(3);
                  setSelectedAddress(oAddress);
                }}
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "black",
                  color: "white",
                  fontSize: "18px",
                  fontWeight: theme.fontWeight.semiBold,
                  textAlign: "center",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "black",
                  },
                }}
              >
                EDIT
              </Button>
            </Stack>
          ))}
        </>
      )}
    </>
  );
};

export default AddressesContainer;
