/*Standard Library Start*/
import React, { useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useSelector } from "react-redux";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField as TextMUI,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
/*Standard Library End*/

/*Local Library Start*/
import { db } from "../../firebase";
import theme from "../../theme";
import { useAppDispatch } from "../../app/store";
import { fetchOrder } from "../../features/order/orderSlice";
import { setOrderLoading } from "../../features/order/orderSlice";
import { MessageAlert } from "../../components";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};
/*Constant Variables End*/

const OrderCancel = () => {
  /*Standard Variables Start*/
  const {
    products: { products },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const { AlertControl, setAlertMessage, setAlertSeverity, setShowAlert } =
    MessageAlert();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [showOrderCancelDialog, setShowOrderCancelDialog] = useState(false);
  /*Local Variables End*/

  /*Functions Start*/
  const onCancelOrder = async (oSelectedOrder, cancellationMessage) => {
    closeOrderCancelDialog();
    dispatch(setOrderLoading(true));
    const oOrder = cloneDeep(oSelectedOrder);
    oOrder.order_status.map((oStatus) => {
      if (oStatus.status === "Order Cancelled") {
        oStatus.created_on = new Date().toString();
        oStatus.message = cancellationMessage + " - Team Keimple";
      }
      return oStatus;
    });
    const docRef = doc(db, "orders", oOrder.order_id);
    updateDoc(docRef, {
      order_status: oOrder.order_status,
      last_modified: new Date().toString(),
    })
      .then(() => {
        dispatch(setOrderLoading(false));
        const id = oSelectedOrder.order_id;
        dispatch(
          fetchOrder({
            orderId: id,
            products,
          })
        );
      })
      .catch((error) => {
        dispatch(setOrderLoading(false));
        setAlertMessage(`Something bad happened: ${error}`);
        setAlertSeverity("error");
        setShowAlert(true);
      });
  };

  const closeOrderCancelDialog = () => {
    setShowOrderCancelDialog(false);
  };

  const OrderCancelDialog = ({ oOrder }) => {
    const [cancellationMessage, setCancellationMessage] = useState("");
    const [messageError, setMessageError] = useState("");

    return (
      <>
        <Dialog open={showOrderCancelDialog} onClose={closeOrderCancelDialog}>
          <DialogContent>
            <Typography fontSize="18px" fontWeight={theme.fontWeight.semiBold}>
              Are you sure you want to cancel order #{oOrder.order_id}?
            </Typography>
            <TextMUI
              id="message"
              onChange={(event) => {
                setCancellationMessage(event.target.value);
              }}
              label="CANCELLATION REASON"
              multiline
              rows={3}
              sx={{
                marginTop: "10px",
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${messageError && "#d32f2f"}`,
                },
                "& .MuiFormHelperText-root": {
                  color: "#d32f2f",
                },
              }}
              helperText={messageError && messageError}
            />
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                backgroundColor: "hite",
                borderRadius: "10px",
                color: "black",
                display: "flex",
                alignItems: "center",
                fontSize: "18px",
                fontWeight: theme.fontWeight.semiBold,
                maxWidth: { xs: "400px", sm: "448px" },
                textAlign: "center",
                textTransform: "none",
                width: "200px",
                height: "49px",
                border: "2px solid black",
              }}
              onClick={closeOrderCancelDialog}
            >
              CLOSE
            </Button>
            <Button
              sx={stepperButtonStyles}
              onClick={() => {
                if (cancellationMessage) {
                  onCancelOrder(oOrder, cancellationMessage);
                } else {
                  setMessageError("Cancellation reason is required");
                }
              }}
            >
              CANCEL ORDER
            </Button>
          </DialogActions>
        </Dialog>
        <AlertControl />
      </>
    );
  };
  /*Functions End*/

  return {
    OrderCancelDialog,
    setShowOrderCancelDialog,
  };
};

export default OrderCancel;
