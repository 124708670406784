/* eslint-disable array-callback-return */
/* eslint-disable no-empty-pattern */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, query, getDocs, where, and } from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";

const initialState = {
  orders: null,
  loading: false,
  userOrders: null,
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.orders = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserOrders.fulfilled, (state, action) => {
        state.userOrders = action.payload;
        state.loading = false;
      });
  },
});

export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async ({}, thunkAPI) => {
    const ordersRef = collection(db, "orders");
    const q = query(ordersRef);
    const querySnapshot = await getDocs(q);
    let userOrders = [];
    querySnapshot.forEach((doc) => {
      let oData = doc.data();

      if (oData?.user_address) {
        oData.name =
          oData.user_address.first_name + " " + oData.user_address.last_name;
      } else {
        oData.name = oData.account_name;
      }
      oData.mobile = oData.mobile.substring(3);
      userOrders.push(oData);
    });

    userOrders.map((oOrder) => {
      const aStatus = oOrder.order_status;

      if (aStatus && aStatus.length > 0) {
        const aFilters = aStatus.filter((oStatus) => oStatus.created_on !== "");
        aFilters.sort(
          (a, b) => new Date(a.created_on) - new Date(b.created_on)
        );

        oOrder.status = aFilters[aFilters.length - 1].status;
      }
      oOrder.created_on = moment(oOrder.created_on).format("LLL");
      return oOrder;
    });

    userOrders.map((oOrder) => {
      let bAlteration = false;
      oOrder.products.map((oProduct) => {
        if (oProduct?.alteration_history?.length > 0 && !bAlteration) {
          oProduct?.alteration_history.map((oAlteration) => {
            if (oAlteration?.status === "open") {
              oOrder.status = oOrder.status + " (Alteration)";
              bAlteration = true;
            }
          });
        }
      });

      return oOrder;
    });

    return userOrders.sort(function (a, b) {
      return new Date(b.created_on) - new Date(a.created_on);
    });
  }
);

export const fetchUserOrders = createAsyncThunk(
  "orders/fetchUserOrders",
  async ({ mobile }, thunkAPI) => {
    const ordersRef = collection(db, "orders");
    const q = query(ordersRef, and(where("mobile", "==", mobile)));

    const querySnapshot = await getDocs(q);

    let userOrders = [];
    querySnapshot.forEach((doc) => {
      let oData = doc.data();

      if (oData?.user_address) {
        oData.name =
          oData.user_address.first_name + " " + oData.user_address.last_name;
      } else {
        oData.name = oData.account_name;
      }
      oData.mobile = oData.mobile.substring(3);
      userOrders.push(oData);
    });

    userOrders.map((oOrder) => {
      const aStatus = oOrder.order_status;

      if (aStatus && aStatus.length > 0) {
        const aFilters = aStatus.filter((oStatus) => oStatus.created_on !== "");
        aFilters.sort(
          (a, b) => new Date(a.created_on) - new Date(b.created_on)
        );

        oOrder.status = aFilters[aFilters.length - 1].status;
      }
      oOrder.created_on = moment(oOrder.created_on).format("LLL");
      return oOrder;
    });

    userOrders.map((oOrder) => {
      oOrder.products.map((oProduct) => {
        if (oProduct?.alteration_history?.length > 0) {
          oProduct?.alteration_history.map((oAlteration) => {
            if (
              oAlteration?.status === "open" &&
              oAlteration.type === "alteration"
            ) {
              oOrder.status = oOrder.status + " (Alteration)";
            }
          });
        }
      });

      return oOrder;
    });

    return userOrders.sort(function (a, b) {
      return new Date(b.created_on) - new Date(a.created_on);
    });
  }
);

export default ordersSlice.reducer;
export const { setOrderLoading } = ordersSlice.actions;
