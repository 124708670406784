/*Standard Library Start*/
import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Stack, Box, Typography, Button, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import { auth } from "../../firebase";
import logo from "../../images/logo.png";
/*Local Library End*/

/*Constant Variables Start*/
const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const initialValues = { email: "", password: "", confirmPassword: "" };
/*Constant Variables End*/

const Login = () => {
  /*Local Variables Start*/
  const [loading, setLoading] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  /*Local Variables End*/

  /*Functions Start*/
  const handleClickPasswordIcon = () => {
    setIsPasswordShown((previousState) => !previousState);
  };

  const loginWithEmail = async (values) => {
    setLoading(true);

    signInWithEmailAndPassword(auth, values.email, values.password)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        let typedError = error;
        switch (typedError.code) {
          case "auth/user-not-found":
            setErrorMessage("User not found");
            break;
          case "auth/wrong-password":
            setErrorMessage("Wrong password");
            break;
          default:
            setErrorMessage(typedError.message);
            break;
        }
      });
  };

  const handleChangeInput = () => {
    setErrorMessage("");
  };
  /*Functions End*/

  return (
    <Stack
      alignItems="center"
      sx={{
        "& form": {
          display: "flex",
          flexDirection: "column",
          rowGap: "15px",
          width: "100%",
          maxWidth: "448px",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "10px",
          borderColor: "#D8D8D8",
        },
      }}
    >
      <Box
        sx={{
          cursor: "pointer",
        }}
      >
        <img
          style={{
            width: "200px",
            height: "100px",
            padding: "10px",
          }}
          alt=""
          src={logo}
        ></img>
      </Box>
      <Typography
        sx={{
          fontSize: "22px",
          fontWeight: theme.fontWeight.semiBold,
          marginBottom: "24px",
        }}
      >
        ADMIN LOGIN
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={loginWithEmail}
      >
        {({ errors }) => (
          <Form id="loginForm" noValidate onChange={handleChangeInput}>
            <Field
              disabled={loading}
              component={TextField}
              type="email"
              name="email"
              label="EMAIL"
            />
            <Field
              disabled={loading}
              component={TextField}
              type={isPasswordShown ? "text" : "password"}
              name="password"
              label="PASSWORD"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleClickPasswordIcon}
                    sx={{ cursor: "pointer" }}
                  >
                    {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${errorMessage && "#d32f2f"}`,
                },
                "& .MuiFormHelperText-root": {
                  color: "#d32f2f",
                },
              }}
              helperText={
                errors.password
                  ? errors.password
                  : errorMessage
                  ? errorMessage
                  : ""
              }
            />
          </Form>
        )}
      </Formik>
      <Button
        variant="contained"
        form="loginForm"
        type="submit"
        sx={{
          backgroundColor: "black",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          fontSize: "18px",
          fontWeight: theme.fontWeight.semiBold,
          marginTop: "20px",
          maxWidth: { xs: "400px", sm: "448px" },
          textAlign: "center",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "black",
          },
          height: "56px",
          width: "100%",
        }}
      >
        LOG IN
      </Button>
    </Stack>
  );
};

export default Login;
