import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  currentUser: null,
  loading: true
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser(state, actions) {
      state.currentUser = actions.payload
    },
    setCurrentUserLoading(state, actions) {
      state.loading = actions.payload
    }
  }
})

export default authSlice.reducer
export const { setCurrentUser, setCurrentUserLoading } = authSlice.actions
