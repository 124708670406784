/* eslint-disable array-callback-return */

/*Standard Library Start*/
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useSearchParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import { SearchProduct, OrderProductDetail } from "../../components";
import { useAppDispatch } from "../../app/store";
import { fetchOrder } from "../../features/order/orderSlice";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};
/*Constant Variables End*/

const OrderPair = () => {
  /*Standard Variables Start*/
  const {
    currentUser,
    products: { products },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { getPantDetail } = OrderProductDetail();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [orderForPair, setOrderForPair] = useState(false);
  const [showPairItDialog, setShowPairItDialog] = useState(false);
  const [pairFirstHalf, setPairFirstHalf] = useState(null);
  const [pairSecondHalf, setPairSecondHalf] = useState(null);
  const [availableProducts, setAvailableProducts] = useState([]);
  /*Local Variables End*/

  /*Functions Start*/
  const onClosePairItDialog = () => {
    setShowPairItDialog(false);
  };

  let onUpdateOrderPair = async () => {
    onClosePairItDialog();
    if (currentUser.currentUser) {
      const docRef = doc(db, "orders", orderForPair.order_id);
      let iPairCount = 0;
      let aSkipProducts = [];
      orderForPair.products.map((oProduct) => {
        if (oProduct.pair_number !== 0) {
          let bFound = aSkipProducts.find(
            (sSkip) => sSkip === oProduct.pair_number
          );

          if (!bFound) {
            aSkipProducts.push(oProduct.pair_number);
            iPairCount++;
          }
        }
      });

      const updatedProducts = orderForPair.products.map((oProduct, iIndex) => {
        if (
          oProduct.product_code === pairFirstHalf.product_code &&
          iIndex === pairFirstHalf.product_index
        ) {
          if (pairSecondHalf.pair_number !== 0) {
            oProduct.pair_number = pairSecondHalf.pair_number;
          } else {
            oProduct.pair_number = ++iPairCount;
            setPairFirstHalf(
              (previousState) =>
                (previousState.pair_number = oProduct.pair_number)
            );
          }
        }

        if (
          oProduct.product_code === pairSecondHalf.product_code &&
          iIndex === pairSecondHalf.product_index
        ) {
          if (pairFirstHalf.pair_number) {
            oProduct.pair_number = pairFirstHalf.pair_number;
          } else {
            oProduct.pair_number = ++iPairCount;
            setPairSecondHalf(
              (previousState) =>
                (previousState.pair_number = oProduct.pair_number)
            );
          }
        }
        return oProduct;
      });

      updatedProducts.sort((a, b) => {
        return a.category === "shirt" ? -1 : 1;
      });

      updatedProducts.sort((a, b) => {
        if (parseInt(a.pair_number) > parseInt(b.pair_number)) {
          return -1;
        }
        if (parseInt(a.pair_number) < parseInt(b.pair_number)) {
          return 1;
        }
        return 0;
      });

      await updateDoc(docRef, {
        products: updatedProducts,
        last_modified: new Date().toString(),
      });

      dispatch(
        fetchOrder({
          orderId: searchParams.get("id"),
          products,
        })
      );

      setPairFirstHalf(null);
      setPairSecondHalf(null);
    }
  };

  const PairItDialog = () => (
    <Dialog open={showPairItDialog} onClose={onClosePairItDialog}>
      <DialogContent>
        {pairFirstHalf && (
          <Box
            alignItems="center"
            sx={{
              cursor: "pointer",
              gap: "10px",
              display: {
                xs: "block",
                sm: "flex",
                md: "flex",
                lg: "flex",
              },
            }}
          >
            <Box
              sx={{
                width: "40%",
                height: "40%",
              }}
            >
              {pairFirstHalf?.card_image_url?.includes("http") ? (
                <LazyLoadImage
                  threshold={10}
                  visibleByDefault={true}
                  alt={pairFirstHalf.product_name}
                  src={pairFirstHalf?.card_image_url}
                  width="90%"
                  height="40%"
                />
              ) : (
                <Box
                  sx={{
                    width: "90%",
                    height: "40%",
                    border: "1px solid lightgray",
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Image not available
                  </Typography>
                </Box>
              )}
            </Box>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "18px" },
              }}
              fontWeight={theme.fontWeight.regular}
            >
              {pairFirstHalf.product_name} ({pairFirstHalf.product_code})
            </Typography>
          </Box>
        )}
        {pairFirstHalf && (
          <Typography
            sx={{
              fontSize: "24px",
              marginTop: "20px",
              marginBottom: "20px",
              textAlign: "center",
            }}
            fontWeight={theme.fontWeight.bold}
          >
            PAIR WITH SUITING
          </Typography>
        )}
        <SearchProduct
          showImage
          setSelected={setPairSecondHalf}
          products={availableProducts}
          selected={pairSecondHalf}
          readOnly={false}
        />
        {pairSecondHalf && (
          <Box
            alignItems="center"
            sx={{
              cursor: "pointer",
              gap: "10px",
              display: {
                xs: "block",
                sm: "flex",
                md: "flex",
                lg: "flex",
              },
              marginTop: "20px",
            }}
          >
            <Box
              sx={{
                width: "40%",
                height: "40%",
              }}
            >
              {pairSecondHalf?.card_image_url?.includes("http") ? (
                <LazyLoadImage
                  threshold={10}
                  visibleByDefault={true}
                  alt={pairSecondHalf.product_name}
                  src={pairSecondHalf?.card_image_url}
                  width="90%"
                  height="40%"
                />
              ) : (
                <Box
                  sx={{
                    width: "90%",
                    height: "40%",
                    border: "1px solid lightgray",
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Image not available
                  </Typography>
                </Box>
              )}
            </Box>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "18px" },
              }}
              fontWeight={theme.fontWeight.regular}
            >
              {pairSecondHalf.product_name} ({pairSecondHalf.product_code}) -{" "}
              {getPantDetail(pairSecondHalf, true)}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!pairSecondHalf}
          sx={{
            ...stepperButtonStyles,
          }}
          onClick={onUpdateOrderPair}
        >
          PAIR IT
        </Button>
        <Button
          sx={{
            backgroundColor: "white",
            borderRadius: "10px",
            color: "black",
            display: "flex",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: theme.fontWeight.semiBold,
            maxWidth: { xs: "400px", sm: "448px" },
            textAlign: "center",
            textTransform: "none",
            width: "200px",
            height: "49px",
            border: "2px solid black",
          }}
          onClick={onClosePairItDialog}
        >
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
  /*Functions End*/

  return {
    PairItDialog,
    setShowPairItDialog,
    setPairFirstHalf,
    setPairSecondHalf,
    setAvailableProducts,
    setOrderForPair,
  };
};

export default OrderPair;
