/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import {
  Box,
  Stack,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select as MUISelect,
  MenuItem,
  TextField as MUITextField,
  IconButton,
} from "@mui/material";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import theme from "../../theme";
import {
  Close as CloseIcon,
  Discount as DiscountIcon,
} from "@mui/icons-material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  collection,
  query,
  where,
  and,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  getDocFromServer,
} from "firebase/firestore";
/*Standard Library End*/

/*Local Library Start*/
import { db } from "../../firebase";
import { MessageDialog, NewOrderPayment } from "../../components";
import { setOrderLoading } from "../../features/order/orderSlice";
import { useAppDispatch } from "../../app/store";
import suiting from "../../constants/suiting.json";
/*Local Library End*/

const CartSummary = ({
  user,
  order,
  selectedAddress,
  deliveryType,
  setOrder,
  discount,
  setDiscount,
  discountOption,
  setDiscountOption,
  coupon,
  setCoupon,
  couponError,
  setCouponError,
  activeStep,
  createdOrder,
}) => {
  /*Standard Variables Start*/
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    MessageDialogControl,
    setShowMessageDialog,
    setMessageForMessageDialog,
    setMessageDialogTitle,
  } = MessageDialog();

  const { UpdatePaymentControl, setShowUpdatePaymentDialog } =
    NewOrderPayment();
  /*Standard Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (order?.products.length > 0) {
      let oReturn = getTotal(order.products);
      let oCloneOrder = cloneDeep(order);

      oCloneOrder.totalCost = oReturn.totalCost;
      oCloneOrder.discount = oReturn.discount;

      setOrder(oCloneOrder);
    }
  }, [discount]);

  useEffect(() => {
    if (activeStep === 4 && createdOrder?.order_id) {
      setDiscount(createdOrder.discount);
      if (createdOrder.coupon_code) {
        setDiscountOption("coupon");
        setCoupon(createdOrder.coupon_code);
      } else {
        setDiscountOption("manual");
        setCoupon("");
      }
    }
  }, [activeStep]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  const checkIfPendingOrder = async () => {
    const ordersRef = collection(db, "orders");
    const q = query(
      ordersRef,
      and(
        where("mobile", "==", "+91" + user.mobile),
        where("payment_status", "==", "Pending")
      )
    );

    const querySnapshot = await getDocs(q);

    const aPendingOrders = [];
    querySnapshot.forEach((doc) => {
      const oCreatedDate = new Date(doc.data().created_on);
      const oToday = new Date();

      if (
        oCreatedDate.getDate() !== oToday.getDate() ||
        oCreatedDate.getMonth() !== oToday.getMonth() ||
        oCreatedDate.getFullYear() !== oToday.getFullYear()
      ) {
        aPendingOrders.push(doc.data());
      }
    });

    return aPendingOrders.length > 0;
  };

  const onConfirmOrder = async (
    aPaymentHistory = [],
    sPaymentStatus = "Pending",
    sAmountPaid = "0"
  ) => {
    try {
      if (user) {
        dispatch(setOrderLoading(true));

        if (!order.order_id) {
          const bHasPendingOrders = await checkIfPendingOrder();
          if (bHasPendingOrders) {
            setMessageForMessageDialog(
              "The next order can be processed after all pending payments are received"
            );
            setMessageDialogTitle("Error");
            setShowMessageDialog(true);
            dispatch(setOrderLoading(false));
            return;
          }
        }
        let aProducts = [];

        order.products.forEach((oProduct) => {
          let oProductData = {
            product_id: oProduct.id,
            price: oProduct.price.toString(),
            category: oProduct.category,
            product_detail: oProduct.product_detail,
            pair_number: 0,
            quantity: 0,
          };

          if (!oProduct.quantity) {
            if (oProduct.category === "pant") {
              oProductData.quantity = 1.6;
            } else if (oProduct.category === "shirt") {
              oProductData.quantity = 1.2;
            }
          } else {
            oProductData.quantity = oProduct.quantity;
          }
          aProducts.push(oProductData);
        });

        let sOrderId = "";
        if (order.order_id) {
          sOrderId = order.order_id;
        } else {
          const orderCountRef = doc(db, "orderCount", "orderCount");
          let snapshot = (await getDocFromServer(orderCountRef)).data();
          sOrderId = snapshot.count + 1;

          await updateDoc(orderCountRef, {
            count: sOrderId,
          });
        }

        let oDeliveryDate = new Date();
        oDeliveryDate.setDate(oDeliveryDate.getDate() + 7);
        const oOrderData = {
          order_id: sOrderId.toString(),
          account_name: user?.account_name ? user?.account_name : "",
          mobile: "+91" + user.mobile,
          email: user.email,
          user_address:
            deliveryType === "get-delivered" ? selectedAddress : null,
          delivery_type: deliveryType,
          created_on:
            order?.order_id && order?.created_on
              ? order.created_on
              : new Date().toString(),
          last_modified: new Date().toString(),
          total_amount: order.totalCost.toString(),
          amount_paid: sAmountPaid,
          discount: order.discount,
          products: aProducts,
          payment_status: sPaymentStatus,
          coupon_code: coupon,
          order_version: 3,
          order_type: "offline",
          stitch_only: false,
          payment_history: aPaymentHistory,
          delivery_date: oDeliveryDate.toString(),
          order_status: [
            {
              status: "Order Placed",
              created_on: new Date().toString(),
            },
            {
              status: "Order Shipped",
              created_on: "",
            },
            {
              status: "Order Delivered",
              created_on: "",
            },
            {
              status: "Order Cancelled",
              created_on: "",
            },
          ],
        };

        if (order.order_id) {
          if (order?.old_payment_history?.length > 0) {
            if (order?.payment_history?.length > 0) {
              oOrderData.old_payment_history = [
                ...order.old_payment_history,
                ...order.payment_history,
              ];
            }
          } else {
            if (order?.payment_history?.length > 0) {
              oOrderData.old_payment_history = [...order.payment_history];
            }
          }
          const docRef = doc(db, "orders", order.order_id);
          await updateDoc(docRef, oOrderData);

          setMessageForMessageDialog(
            `Order updated successully.\nOrder #: ${sOrderId} `
          );
        } else {
          await setDoc(doc(db, "orders", sOrderId.toString()), oOrderData);

          setMessageForMessageDialog(
            `Order placed successully\nOrder #: ${sOrderId} `
          );
        }
        setShowMessageDialog(true);
        dispatch(setOrderLoading(false));
      }
    } catch (error) {
      dispatch(setOrderLoading(false));
      if (order.order_id) {
        setMessageForMessageDialog(
          "Error while updating order. Please try again later."
        );
      } else {
        setMessageForMessageDialog(
          "Error while placing order. Please try again later."
        );
      }
      setShowMessageDialog(true);
    }
  };

  const getShirtDetails = (product) => {
    if (!order?.order_id || order?.order_version > 1) {
      return (
        <>
          {product.quantity && (
            <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
              {product.quantity} meters
            </Typography>
          )}
          <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
            {product.product_detail.sleeve}
          </Typography>
          <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
            {product.product_detail.collor}
          </Typography>
          <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
            {product.product_detail.pocket}
          </Typography>
        </>
      );
    }

    let sCollor = "";

    product.collors.some((Collor) => {
      if (Collor.product_id === product.product_detail.collor) {
        sCollor = Collor.product_name;
        return true;
      }
      return false;
    });

    let sPocket = "";
    product.pockets.some((Pocket) => {
      if (Pocket.product_id === product.product_detail.pocket) {
        sPocket = Pocket.product_name;
        return true;
      }

      return false;
    });

    let sSleeve = "";
    product.sleeves.some((Sleeve) => {
      if (Sleeve.product_id === product.product_detail.sleeve) {
        sSleeve = Sleeve.product_name;
        return true;
      }

      return false;
    });

    let sCuff = "";
    product.cuffs.some((Cuff) => {
      if (Cuff.product_id === product.product_detail.cuff) {
        sCuff = Cuff.product_name;
        return true;
      }

      return false;
    });

    return (
      <>
        <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
          {product.quantity} meters
        </Typography>
        <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
          {sCollor}
        </Typography>
        <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
          {sPocket}
        </Typography>
        <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
          {sSleeve}
        </Typography>
        <Typography
          fontSize="16px"
          fontWeight={theme.fontWeight.regular}
          textAlign="center"
        >
          {sCuff}
        </Typography>
      </>
    );
  };

  const getPantDetails = (product) => {
    let oFoundSuiting = null;

    suiting.some((oSuiting) => {
      if (oSuiting.category === product.product_detail.suiting_type) {
        oFoundSuiting = oSuiting;
        return true;
      }
      return false;
    });

    return (
      <>
        <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
          {product.quantity} meters
        </Typography>
        {oFoundSuiting && (
          <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
            {oFoundSuiting.label}
          </Typography>
        )}
      </>
    );
  };

  const getTotal = (products) => {
    let total = 0;
    products.map((oProduct) => {
      total = parseFloat(total) + parseFloat(oProduct.price);
    });
    total = +total.toFixed(2);

    const fnPercentage = function (sTotal) {
      return (sTotal * parseInt(discount)) / 100;
    };

    if (parseInt(discount) > 0) {
      total = total - fnPercentage(total);
    }
    let totalCost = total;

    return {
      totalCost: totalCost,
      discount: discount,
    };
  };

  const applyCoupon = async (sCoupon) => {
    if (sCoupon) {
      dispatch(setOrderLoading(true));

      const couponsRef = collection(db, "coupons");
      const q = query(couponsRef, and(where("code", "==", sCoupon)));

      const querySnapshot = await getDocs(q);

      const aCoupons = [];
      querySnapshot.forEach((doc) => {
        aCoupons.push(doc.data());
      });

      if (aCoupons.length > 0) {
        const couponsRef = collection(db, "orders");
        const q = query(
          couponsRef,
          and(
            where("mobile", "==", "+91" + user.mobile),
            where("coupon_code", "==", sCoupon)
          )
        );

        const querySnapshot = await getDocs(q);

        const aOrders = [];
        querySnapshot.forEach((doc) => {
          if (order.order_id !== doc.data().order_id) {
            aOrders.push(doc.data());
          }
        });

        if (aOrders.length === 0) {
          if (aCoupons[0].count > 0) {
            if (order.totalCost > aCoupons[0].min_order) {
              const today = new Date();
              today.setHours(0, 0, 0, 0);

              const couponDate = new Date(aCoupons[0].expiry_date);
              couponDate.setHours(0, 0, 0, 0);
              if (today <= couponDate) {
                const oCloneOrder = cloneDeep(order);
                let oData = aCoupons[0];
                oCloneOrder.discount = oData.discount;
                const fnPercentage = function (sTotal) {
                  return (sTotal * oCloneOrder.discount) / 100;
                };
                oCloneOrder.totalCost =
                  oCloneOrder.totalCost - fnPercentage(oCloneOrder.totalCost);

                setOrder(oCloneOrder);
                setCoupon(sCoupon);
              } else {
                setCouponError("Coupon expired!");
              }
            } else {
              setCouponError(`Min. amount ₹${aCoupons[0].min_order} required`);
            }
          } else {
            setCouponError("Coupon expired!");
          }
          dispatch(setOrderLoading(false));
        } else {
          setCouponError("Coupon already used!");
          dispatch(setOrderLoading(false));
        }
      } else {
        setCouponError("Invalid coupon!");
        dispatch(setOrderLoading(false));
      }
    } else {
      const oCloneOrder = cloneDeep(order);
      setDiscount("0");
      let oReturn = getTotal(oCloneOrder.products);
      oCloneOrder.totalCost = oReturn.totalCost;
      oCloneOrder.discount = oReturn.discount;
      setOrder(oCloneOrder);
      setCoupon("");
    }
  };

  const onAfterMessageDialogClose = () => {
    navigate({ pathname: "/orders" });
  };

  /*Functions End*/

  return (
    <>
      {order.products && (
        <>
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      PRODUCT
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      PRODUCT DETAILS
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      PRICE
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order?.products?.map((product) => {
                    return (
                      <TableRow
                        key={product.product_id}
                        sx={{ "& td": { borderBottom: 0 } }}
                      >
                        <TableCell>
                          <Box
                            alignItems="center"
                            sx={{
                              gap: "10px",
                              display: {
                                xs: "block",
                                sm: "flex",
                                md: "flex",
                                lg: "flex",
                              },
                            }}
                          >
                            {product?.card_image_url?.includes("http") ? (
                              <LazyLoadImage
                                threshold={10}
                                visibleByDefault={true}
                                alt={product.product_name}
                                src={product?.card_image_url}
                                width="200px"
                              />
                            ) : (
                              <Box
                                sx={{
                                  width: "200px",
                                  border: "1px solid lightgray",
                                }}
                              >
                                {" "}
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                  }}
                                >
                                  Image not available
                                </Typography>
                              </Box>
                            )}
                            <Typography
                              sx={{
                                width: { xs: "150px", md: "300px" },
                              }}
                              fontSize="18px"
                              fontWeight={theme.fontWeight.regular}
                            >
                              {product.product_name} ({product.product_code})
                              &nbsp;
                              {parseInt(product.stock_quantity) === 0 && (
                                <>(Out of stock)</>
                              )}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {product.category === "shirt" && (
                            <>{getShirtDetails(product)}</>
                          )}
                          {product.category === "pant" && (
                            <>{getPantDetails(product)}</>
                          )}
                        </TableCell>

                        <TableCell>
                          <Typography
                            fontSize="18px"
                            fontWeight={theme.fontWeight.bold}
                            textAlign="center"
                          >
                            &#8377;
                            {parseFloat(product.price).toFixed(2)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{
              marginTop: "20px",
              justifyContent: { xs: "center", sm: "space-between" },
            }}
          >
            <Stack
              direction="row"
              columnGap="10px"
              sx={{
                alignItems: { xs: "center", lg: "flex-start" },
                width: { xs: "max-content", lg: "350px" },
                maxWidth: "100%",
                "& p": {
                  textAlign: { xs: "center", lg: "start" },
                  fontSize: "18px",
                  fontWeight: theme.fontWeight.regular,
                },
              }}
            >
              {order?.order_id ? (
                <Button
                  onClick={() => {
                    setShowUpdatePaymentDialog(true);
                  }}
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "black",
                    color: "white",
                    fontSize: "16px",
                    fontWeight: theme.fontWeight.semiBold,
                    textAlign: "center",
                    height: "49px",
                    width: "203px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  }}
                >
                  UPDATE ORDER
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setShowUpdatePaymentDialog(true);
                  }}
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "black",
                    color: "white",
                    fontSize: "16px",
                    fontWeight: theme.fontWeight.semiBold,
                    textAlign: "center",
                    height: "49px",
                    width: "203px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  }}
                >
                  PAYMENT
                </Button>
              )}
              <Button
                onClick={() => {
                  navigate({ pathname: "/orders" });
                }}
                sx={{
                  borderRadius: "10px",
                  border: "2px solid black",
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "16px",
                  fontWeight: theme.fontWeight.semiBold,
                  textAlign: "center",
                  height: "49px",
                  width: "203px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              >
                CANCEL
              </Button>
            </Stack>

            <Stack alignItems="end">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <FormControl fullWidth>
                  <InputLabel>DISCOUNT OPTIONS</InputLabel>
                  <MUISelect
                    label="Discount Options"
                    style={{ width: "230px" }}
                    value={discountOption}
                    onChange={(event) => {
                      setDiscountOption(event.target.value);
                      if (event.target.value === "manual") {
                        applyCoupon("");
                      }
                      setDiscount("0");
                    }}
                  >
                    <MenuItem value="coupon">Apply Coupon</MenuItem>
                    <MenuItem value="manual">Manual Discount</MenuItem>
                  </MUISelect>
                </FormControl>
              </Stack>

              {discountOption === "coupon" && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="end"
                  columnGap="10px"
                  sx={{
                    borderRadius: "10px",
                    marginTop: "20px",
                  }}
                >
                  {coupon === "" ? (
                    <>
                      <MUITextField
                        id="idCoupan"
                        label="COUPON CODE"
                        error={couponError !== ""}
                        helperText={couponError}
                        onChange={(event) => {
                          setCouponError("");
                        }}
                      />
                      <Button
                        onClick={() => {
                          const coupanCode =
                            document.getElementById("idCoupan").value;
                          applyCoupon(coupanCode);
                        }}
                        sx={{
                          borderRadius: "10px",
                          backgroundColor: "black",
                          color: "white",
                          fontSize: "18px",
                          fontWeight: theme.fontWeight.semiBold,
                          textAlign: "center",
                          height: "49px",
                          width: "100px",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "black",
                          },
                        }}
                      >
                        APPLY
                      </Button>
                    </>
                  ) : (
                    <>
                      <DiscountIcon />
                      <Typography
                        fontWeight={theme.fontWeight.light}
                        fontSize="16px"
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {order.discount}%
                        </span>{" "}
                        discount applied! ({coupon})
                        <IconButton
                          onClick={() => {
                            applyCoupon("");
                          }}
                          sx={{
                            marginLeft: "10px",
                            height: "20px",
                            width: "20px",
                            backgroundColor: "black",
                            borderRadius: "56px",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "black",
                            },
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Typography>
                    </>
                  )}
                </Stack>
              )}
              {discountOption === "manual" && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ marginTop: "20px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>DISCOUNT</InputLabel>
                    <MUISelect
                      label="Discount"
                      style={{ width: "230px" }}
                      value={discount}
                      onChange={(event) => {
                        setDiscount(event.target.value);
                      }}
                    >
                      <MenuItem value="0">0 %</MenuItem>
                      <MenuItem value="5">5 %</MenuItem>
                      <MenuItem value="10">10 %</MenuItem>
                      <MenuItem value="15">15 %</MenuItem>
                      <MenuItem value="20">20 %</MenuItem>
                      <MenuItem value="25">25 %</MenuItem>
                    </MUISelect>
                  </FormControl>
                </Stack>
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                columnGap="33px"
                width="300px"
                height="49px"
                sx={{
                  backgroundColor: "#F1F1F1",
                  borderRadius: "10px",
                  padding: "10px",
                  marginTop: "20px",
                }}
              >
                <Typography
                  fontWeight={theme.fontWeight.regular}
                  fontSize="18px"
                >
                  TOTAL AMOUNT :
                </Typography>
                <Typography
                  fontWeight={theme.fontWeight.semiBold}
                  fontSize="18px"
                >
                  &#8377;
                  {Number(parseFloat(order.totalCost)).toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
      <MessageDialogControl onAfterClose={onAfterMessageDialogClose} />
      <UpdatePaymentControl
        oOrder={order}
        remainingAmount={order.totalCost}
        onConfirmOrder={onConfirmOrder}
      />
    </>
  );
};

export default CartSummary;
