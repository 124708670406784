/*Standard Library Start*/
import { useSelector } from "react-redux";
import { getStorage, ref, uploadBytes, deleteObject } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useSearchParams } from "react-router-dom";
/*Standard Library End*/

/*Local Library Start*/
import { useAppDispatch } from "../../app/store";
import { setOrderLoading } from "../../features/order/orderSlice";
import { fetchOrder } from "../../features/order/orderSlice";
import { MessageAlert } from "../../components";
/*Local Library End*/

const Invoice = () => {
  /*Standard Variables Start*/
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const {
    products: { products },
  } = useSelector((state) => state);
  const { setAlertMessage, setAlertSeverity, setShowAlert } = MessageAlert();
  /*Standard Variables End*/

  /*Functions Start*/
  const onUploadInvoice = (oEvent) => {
    if (oEvent.target.files.length === 1) {
      let oFile = oEvent.target.files[0];
      const sOrderId = searchParams.get("id");

      if (oFile.type === "application/pdf") {
        dispatch(setOrderLoading(true));

        let invoice_url = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/orders%2F${sOrderId}%2F${sOrderId}.pdf?alt=media`;

        const docRef = doc(db, "orders", sOrderId);

        updateDoc(docRef, {
          invoice_url: invoice_url,
          last_modified: new Date().toString(),
        })
          .then((result) => {
            const storage = getStorage();
            const storageRef = ref(
              storage,
              `orders/${sOrderId}/${sOrderId}.pdf`
            );

            // 'file' comes from the Blob or File API
            uploadBytes(storageRef, oFile)
              .then((snapshot) => {
                dispatch(
                  fetchOrder({
                    orderId: searchParams.get("id"),
                    products,
                  })
                );

                dispatch(setOrderLoading(false));
                setAlertMessage("Invoice uploaded successfully!");
                setAlertSeverity("success");
                setShowAlert(true);
              })
              .catch((error) => {
                dispatch(setOrderLoading(false));
                setAlertMessage(`Something bad happened: ${error}`);
                setAlertSeverity("error");
                setShowAlert(true);
              });
          })
          .catch((error) => {
            dispatch(setOrderLoading(false));
            setAlertMessage(`Something bad happened: ${error}`);
            setAlertSeverity("error");
            setShowAlert(true);
          });
      } else {
        setAlertMessage("Only pdf file is supported!");
        setAlertSeverity("error");
        setShowAlert(true);
      }
    } else {
      setAlertMessage("Only 1 file is supported!");
      setAlertSeverity("error");
      setShowAlert(true);
    }
  };

  const onDeleteInvoice = (setConfirmationType) => {
    const sOrderId = searchParams.get("id");

    const storage = getStorage();
    const storageRef = ref(storage, `orders/${sOrderId}/${sOrderId}.pdf`);

    const updateOrderAfterDelete = () => {
      const docRef = doc(db, "orders", sOrderId);

      updateDoc(docRef, {
        invoice_url: "",
        last_modified: new Date().toString(),
      })
        .then(() => {
          dispatch(
            fetchOrder({
              orderId: searchParams.get("id"),
              products,
            })
          );
          setAlertMessage("Invoice deleted successfully!");
          setAlertSeverity("success");
          setShowAlert(true);
          setConfirmationType("");
        })
        .catch((error) => {
          setAlertMessage(`Something bad happened: ${error}`);
          setAlertSeverity("success");
          setShowAlert(true);
        });
    };
    deleteObject(storageRef)
      .then(() => {
        // File deleted successfully
        updateOrderAfterDelete();
      })
      .catch((error) => {
        if (error.code === "storage/object-not-found") {
          updateOrderAfterDelete();
        } else {
          setAlertMessage(`Something bad happened: ${error}`);
          setAlertSeverity("success");
          setShowAlert(true);
        }
        // Uh-oh, an error occurred!
      });
  };

  const onDownloadOrderInvoice = async (oOrder) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      const blob = xhr.response;
      var blob_url = URL.createObjectURL(blob);

      let alink = document.createElement("a");
      alink.href = blob_url;
      alink.download = `${oOrder.order_id}.pdf`;
      alink.click();
    };
    xhr.open("GET", oOrder.invoice_url);
    xhr.send();
  };
  /*Functions End*/

  return {
    onUploadInvoice,
    onDeleteInvoice,
    onDownloadOrderInvoice,
  };
};

export default Invoice;
