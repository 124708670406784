/*Standard Library Start*/
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import "./index.css";
/*Local Library End*/

const ProductCard = ({ products }) => {
  /*Standard Variables Start*/
  const navigate = useNavigate();
  const { pathname } = useLocation();
  /*Standard Variables End*/

  /*Functions Start*/
  const handleClickCard = (product) => {
    navigate({
      pathname: `/${pathname.substring(1, pathname.length - 1)}`,
      search: `?id=${product.id}`,
    });
  };
  /*Functions End*/

  return (
    <>
      {products?.length > 0 &&
        products.map((product, index) => (
          <Stack
            onClick={() => {
              handleClickCard(product);
            }}
            id={product.product_code}
            key={index}
            sx={{
              height: "350px",
              width: "350px",
              borderRadius: "8px",
              border: "1px solid #e6e6e6",
              justifyContent: "space-between",
              cursor: "pointer",
              boxShadow: "none",
              transition: "box-shadow 0.2s linear",
              "&:hover": {
                boxShadow: "1px 5px 7px 0px rgba(175,171,171,.7)",
                border: "1px solid #e6e6e6",
              },
            }}
          >
            <Stack>
              {/* <div className="image-card">
                <div className="image-container">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/keimple-dev.appspot.com/o/test%2FCard.avif?alt=media&token=1a2fc0c8-5ad5-4f4f-bb4a-11ad81e95f88" //{Card}
                    alt="ProductImage"
                    className="initial-image"
                  ></img>

                  <div className="carouselCard">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/keimple-dev.appspot.com/o/test%2FCard.avif?alt=media&token=1a2fc0c8-5ad5-4f4f-bb4a-11ad81e95f88" //{Card}
                      alt="Carousel Image1"
                      className="carouselCard-image"
                    ></img>
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/keimple-dev.appspot.com/o/test%2FCard1.avif?alt=media&token=fccef3cb-edeb-467e-8f83-9f61de69f6a1" //{Card1}
                      alt="Carousel Image1"
                      className="carouselCard-image"
                    ></img>
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/keimple-dev.appspot.com/o/test%2FCard2.avif?alt=media&token=7ecca166-b24a-45a1-996f-d7f07ec96534" //{Card2}
                      alt="CarouselImage2"
                      className="carouselCard-image"
                    ></img>
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/keimple-dev.appspot.com/o/test%2FCard3.avif?alt=media&token=70185d36-d271-49fe-becc-584dcf210c1d" //{Card3}
                      alt="Carousel Image3"
                      className="carouselCard-image"
                    ></img>
                  </div>
                </div>
              </div> */}
              <Box
                sx={{
                  position: "relative",
                  "& .product-image": {
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    height: "270px",
                    width: "350px",
                    objectFit: "cover",
                  },
                }}
              >
                {product?.card_image_url?.includes("http") ? (
                  <LazyLoadImage
                    threshold={10}
                    visibleByDefault={true}
                    alt={product.product_name}
                    height="270px"
                    src={product?.card_image_url} // use normal <img> attributes as props
                    width="350px"
                  />
                ) : (
                  <Box
                    sx={{
                      height: "270px",
                      width: "350px",
                    }}
                  >
                    {" "}
                    <Typography
                      sx={{
                        fontSize: "18px",
                        position: "absolute",
                        left: "18%",
                        top: "38%",
                      }}
                    >
                      Image not available
                    </Typography>
                  </Box>
                )}
              </Box>
              <Stack sx={{ paddingLeft: "5px", paddingRight: "5px" }}>
                <Typography
                  noWrap
                  sx={{
                    width: "290px",
                    textOverflow: "ellipsis",
                    fontSize: "16px",
                  }}
                >
                  {product.product_name}
                </Typography>
              </Stack>
            </Stack>
            <Box
              sx={{
                paddingLeft: "5px",
                paddingRight: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {pathname.substring(1) === "shirts" ? (
                <Typography
                  sx={{
                    fontSize: "16px",
                    alignSelf: "center",
                    fontWeight: theme.fontWeight.regular,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    color: "#7b7b7b",
                  }}
                >
                  &#8377;
                  {Number(parseFloat(product.price)).toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              ) : (
                <></>
              )}
              <Typography
                sx={{
                  fontSize: "16px",
                  alignSelf: "center",
                  fontWeight: theme.fontWeight.regular,
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  color:
                    parseFloat(product.stock_quantity) < 2 ? "red" : "black",
                }}
              >
                {parseFloat(product.stock_quantity).toFixed(1)} meters
              </Typography>
            </Box>
          </Stack>
        ))}
    </>
  );
};

export default trackWindowScroll(ProductCard);
