/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  Typography,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TablePagination,
} from "@mui/material";
import moment from "moment";
import { doc, updateDoc, arrayRemove } from "firebase/firestore";
import { db } from "../../firebase";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import cloneDeep from "lodash/cloneDeep";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
/*Standard Library End*/

/*Local Library Start*/
import { fetchUserOrders } from "../../features/orders/ordersSlice";
import { useAppDispatch } from "../../app/store";
import { fetchUser } from "../../features/user/userSlice";
import theme from "../../theme";
import {
  AddressesContainer,
  UserMeasurement,
  ConfirmationDialog,
} from "../../components";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const columns = [
  { id: "NAME", label: "NAME" },
  {
    id: "action",
    label: "",
  },
];

const orderColumns = [
  { id: "order_id", label: "ORDER #", minWidth: 100 },
  { id: "name", label: "NAME", minWidth: 200 },
  { id: "payment_status", label: "PAYMENT", minWidth: 170 },
  { id: "total_amount", label: "AMOUNT", minWidth: 100 },
  { id: "status", label: "STATUS", minWidth: 170 },
  {
    id: "created_on",
    label: "ORDER DATE",
    minWidth: 170,
  },
];

/*Constant Variables End*/

const UserDetail = () => {
  /*Standard Variables Start*/
  const navigate = useNavigate();
  const {
    currentUser,
    orders: { userOrders },
    user: { user },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const {
    MeasurementControl,
    setMeasurementValues,
    setShowMeasurementDialog,
    setMeasurementEditMode,
    setNewMeasurement,
    setSelectUserMeasurement,
    selectUserMeasurement,
  } = UserMeasurement();

  const {
    ConfirmationDialogControl,
    setShowConfirmationDialog,
    setConfirmationDialogMessage,
    setAcceptButtonText,
    setRejectButtonText,
  } = ConfirmationDialog();

  const [searchParams] = useSearchParams();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [addressEditMode, setAddressEditMode] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [addressIndex, setAddressIndex] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    const id = searchParams.get("id");
    if (currentUser?.currentUser?.access?.users && id) {
      dispatch(
        fetchUser({
          mobile: "+" + id.trim(),
        })
      );

      dispatch(
        fetchUserOrders({
          mobile: "+" + id.trim(),
        })
      );
    }
  }, [currentUser?.currentUser?.access?.users, searchParams.get("id")]);
  /*OnLoad Functions End*/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const refreshUser = () => {
    const id = searchParams.get("id");
    dispatch(
      fetchUser({
        mobile: "+" + id.trim(),
      })
    );
  };

  const deleteMeasurements = async () => {
    if (currentUser.currentUser) {
      const docRef = doc(db, "users", user.mobile);
      delete selectUserMeasurement.index;
      await updateDoc(docRef, {
        measurements: arrayRemove(selectUserMeasurement),
      });
      const id = searchParams.get("id");
      dispatch(
        fetchUser({
          mobile: "+" + id.trim(),
        })
      );
    }
  };

  const getStatusColor = (sId, sValue) => {
    let sColor = "";
    if ("Order Delivered" === sValue) {
      sColor = "#2a982a";
    } else if ("Order Shipped" === sValue) {
      sColor = "orange";
    } else {
      sColor = "red";
    }
    return (
      <Typography
        sx={{
          color: sColor,
          fontSize: "18px",
        }}
      >
        {sValue === "Order Shipped" ? "Order Ready" : sValue}
      </Typography>
    );
  };

  const getUnit = (id, value) => {
    if (id === "total_amount") {
      return "₹" + parseFloat(value).toFixed(2);
    }
    return value;
  };

  const getPaymentColor = (sId, sValue) => {
    let sColor = "";
    if ("Pending" === sValue) {
      sColor = "red";
    } else if ("Payment not received" === sValue) {
      sColor = "red";
    } else if ("Partial payment received" === sValue) {
      sColor = "orange";
    } else {
      sColor = "#2a982a";
    }
    return (
      <Typography
        sx={{
          color: sColor,
          fontSize: "18px",
        }}
      >
        {sValue}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <Button
        sx={{ marginBottom: "20px", ...stepperButtonStyles }}
        onClick={() => {
          navigate("/users");
        }}
        startIcon={<ArrowBackIcon />}
      >
        BACK
      </Button>
      {user && (
        <div
          style={{
            width: { xs: "100%", md: "100%", lg: "100%" },
          }}
        >
          <Box
            sx={{
              border: "1px solid grey",
              paddingLeft: {
                xs: theme.padding?.pagePaddingXS + "px",
                lg: theme.padding?.pagePaddingLG + "px",
                xl: theme.padding?.pagePaddingXL + "px",
              },
              paddingRight: {
                xs: theme.padding?.pagePaddingXS + "px",
                lg: theme.padding?.pagePaddingLG + "px",
                xl: theme.padding?.pagePaddingXL + "px",
              },
            }}
          >
            <Grid
              style={{ paddingTop: "10px" }}
              container
              spacing={{ xs: 1, md: 3 }}
              columns={{ xs: 1, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={4} key={1000}>
                <Typography
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: { xs: "center", sm768: "initial" },
                    textAlign: { xs: "center", sm768: "initial" },
                  }}
                >
                  MOBILE: &nbsp;
                </Typography>
                <Typography
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: { xs: "center", sm768: "initial" },
                    fontWeight: theme.fontWeight.semiBold,
                    textAlign: { xs: "center", sm768: "initial" },
                  }}
                >
                  {user.mobile.substring(3)} &nbsp; &nbsp;
                </Typography>
                <Stack rowGap="5px">
                  <Typography
                    sx={{
                      display: "inline-flex",
                      textAlign: { xs: "center", sm768: "initial" },
                    }}
                  >
                    CREATION DATE: &nbsp;{" "}
                    <Typography
                      component={"span"}
                      sx={{
                        display: "inline-flex",
                        fontWeight: theme.fontWeight.semiBold,
                        textAlign: { xs: "center", sm768: "initial" },
                      }}
                    >
                      {moment(user.created_on).format("LLL")}
                    </Typography>
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            {currentUser?.currentUser?.access?.user_edit_btn && (
              <Button
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  ...stepperButtonStyles,
                }}
                onClick={(event) => {
                  setMeasurementValues({
                    name: "",
                    shirt_length: "",
                    shirt_chest: "",
                    shirt_shoulders: "",
                    shirt_sleeves: "",
                    shirt_muscles: "",
                    shirt_stomach: "",
                    shirt_sit: "",
                    shirt_collor: "",
                    shirt_cuff: "",
                    shirt_cuff_button: "",
                    shirt_bottom: "",
                    pant_length: "",
                    pant_waist: "",
                    pant_sit: "",
                    pant_thighs: "",
                    pant_knees: "",
                    pant_bottom: "",
                    pant_chain: "",
                    pant_back_pocket: "",
                    pant_plated_pant: "",
                    pant_watch_pocket: "",
                    message: "",
                  });
                  setShowMeasurementDialog(true);
                  setMeasurementEditMode(true);
                  setNewMeasurement(true);
                }}
              >
                ADD MEASUREMENT
              </Button>
            )}
            <Paper
              sx={{ width: "100%", overflow: "hidden", marginBottom: "40px" }}
            >
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user?.measurements?.map((row, iIndex) => {
                      return (
                        <TableRow key={iIndex}>
                          {columns.map((column) => {
                            if (column.id === "action") {
                              return (
                                <TableCell key={column.id}>
                                  <Stack direction="row">
                                    <Button
                                      sx={{
                                        marginLeft: "50px",
                                        ...stepperButtonStyles,
                                      }}
                                      onClick={() => {
                                        const selectedData = cloneDeep(row);
                                        selectedData.index = iIndex;
                                        setSelectUserMeasurement(selectedData);
                                        setMeasurementValues(row);
                                        setMeasurementEditMode(false);
                                        setShowMeasurementDialog(true);
                                      }}
                                    >
                                      VIEW
                                    </Button>

                                    {currentUser?.currentUser?.access
                                      ?.user_edit_btn && (
                                      <Button
                                        sx={{
                                          marginLeft: "20px",
                                          ...stepperButtonStyles,
                                        }}
                                        onClick={() => {
                                          const selectedData = cloneDeep(row);
                                          selectedData.index = iIndex;
                                          setSelectUserMeasurement(
                                            selectedData
                                          );
                                          setMeasurementValues(row);
                                          setMeasurementEditMode(true);
                                          setShowMeasurementDialog(true);
                                        }}
                                      >
                                        EDIT
                                      </Button>
                                    )}

                                    {currentUser?.currentUser?.access
                                      ?.user_edit_btn && (
                                      <Button
                                        sx={{
                                          marginLeft: "20px",
                                          ...stepperButtonStyles,
                                        }}
                                        onClick={() => {
                                          const selectedData = cloneDeep(row);
                                          selectedData.index = iIndex;
                                          setSelectUserMeasurement(
                                            selectedData
                                          );
                                          setShowConfirmationDialog(true);
                                          setAcceptButtonText("DELETE");
                                          setRejectButtonText("CANCEL");
                                          setConfirmationDialogMessage(
                                            `Delete ${selectedData.name} measurements?`
                                          );
                                        }}
                                      >
                                        DELETE
                                      </Button>
                                    )}
                                  </Stack>
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell
                                  style={{
                                    fontSize: "18px",
                                  }}
                                  key={column.id}
                                >
                                  {row.name}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
          <Accordion expanded>
            <AccordionSummary>
              <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <Stack>
                  <Typography
                    sx={{
                      fontSize: { xs: "18px", md: "24px" },
                      fontWeight: theme.fontWeight.semiBold,
                    }}
                  >
                    MANAGE ADDRESS
                  </Typography>
                </Stack>
                {!addressEditMode && (
                  <Button
                    onClick={() => {
                      setAddressEditMode(true);
                      setAddressIndex(-1);
                      setSelectedAddress({
                        first_name: "",
                        last_name: "",
                        address: "",
                        city: "",
                        pin_code: "",
                        mobile: "",
                      });
                    }}
                    sx={{
                      borderRadius: "10px",
                      backgroundColor: "black",
                      color: "white",
                      fontSize: "18px",
                      fontWeight: theme.fontWeight.semiBold,
                      textAlign: "center",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                    }}
                  >
                    ADD ADDRESS
                  </Button>
                )}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <AddressesContainer
                editMode={addressEditMode}
                setEditMode={setAddressEditMode}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                addressIndex={addressIndex}
                setAddressIndex={setAddressIndex}
                refreshUser={refreshUser}
                aAddress={user.addresses}
              />
            </AccordionDetails>
          </Accordion>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {orderColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userOrders &&
                    userOrders
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const sSearch = `?id=${row.order_id}`;
                        return (
                          <TableRow
                            onClick={() => {
                              if (row.stitch_only) {
                                navigate({
                                  pathname: "/order-stitch-detail",
                                  search: sSearch,
                                });
                              } else {
                                navigate({
                                  pathname: "/order-detail",
                                  search: sSearch,
                                });
                              }
                            }}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.order_id}
                          >
                            {orderColumns.map((column) => {
                              const value = row[column.id];
                              if (column.id === "status") {
                                return (
                                  <TableCell
                                    style={{
                                      fontSize: "18px",
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {getStatusColor(column.id, value)}
                                  </TableCell>
                                );
                              } else if (column.id === "payment_status") {
                                return (
                                  <TableCell
                                    style={{
                                      fontSize: "18px",
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {getPaymentColor(column.id, value)}
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell
                                    style={{
                                      fontSize: "18px",
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {getUnit(column.id, value)}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={userOrders ? userOrders.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}
      <MeasurementControl />
      <ConfirmationDialogControl onAcceptButtonPress={deleteMeasurements} />
    </Box>
  );
};

export default UserDetail;
