/*Standard Library Start*/
import { Backdrop } from "@mui/material";
import { useSelector } from "react-redux";
import { infinity } from "ldrs";
/*Standard Library End*/

infinity.register();

const Loading = () => {
  /*Standard Variables Start*/
  const {
    user: { loading: userLoading },
    currentUser: { loading: currentUserLoading },
    product: { loading: productLoading },
    products: { loading: productsLoading },
    order: { loading: orderloading },
    messages: { loading: messagesloading },
  } = useSelector((state) => state);
  /*Standard Variables End*/

  return (
    <Backdrop
      sx={{ color: "black", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={
        userLoading ||
        currentUserLoading ||
        productLoading ||
        productsLoading ||
        orderloading ||
        messagesloading
      }
    >
      <l-infinity
        size="60"
        stroke="7"
        stroke-length="0.25"
        bg-opacity="0.1"
        speed="1.2"
        color="white"
      ></l-infinity>
    </Backdrop>
  );
};

export default Loading;
