/* eslint-disable no-empty-pattern */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";

const initialState = {
  coupons: null,
  loading: false,
};

export const couponsSlice = createSlice({
  name: "coupons",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCoupons.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCoupons.fulfilled, (state, action) => {
        state.coupons = action.payload;
        state.loading = false;
      })
  },
});

export const fetchCoupons = createAsyncThunk(
  "coupons/fetchCoupons",
  async ({}, thunkAPI) => {
    const ordersRef = collection(db, "coupons");
    const q = query(ordersRef);
    const querySnapshot = await getDocs(q);

    let userOrders = [];
    querySnapshot.forEach((doc) => {
      let oData = doc.data();
      oData.id = doc.id;
      oData.expiry_date = moment(oData.expiry_date).format("DD MMMM YYYY");
      oData.last_modified = moment(oData.last_modified).format("LLL");
      oData.name = "";
      if (oData?.user_address?.first_name) {
        oData.name = oData.user_address.first_name;
      }
      if (oData?.user_address?.last_name) {
        oData.name = oData.name + " " + oData.user_address.last_name;
      }

      userOrders.push(oData);
    });

    return userOrders.sort(function (a, b) {
      return new Date(b.created_on) - new Date(a.created_on);
    });
  }
);

export default couponsSlice.reducer;
