/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import * as yup from "yup";
import { Formik, Form, Field, setNestedObjectValues } from "formik";
import { TextField, Select } from "formik-mui";
import {
  ArrowBack as ArrowBackIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
/*Standard Library End*/

/*Local Library Start*/
import { SearchBar, MessageAlert, ConfirmationDialog } from "../../components";
import { setUserLoading } from "../../features/user/userSlice";
import theme from "../../theme";
import { fetchAccess } from "../../features/access/accessSlice";
import { useAppDispatch } from "../../app/store";
/*Local Library End*/

/*Constant Variables Start*/
const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const stepperButtonStyles2 = {
  border: "2px solid black",
  borderRadius: "10px",
  backgroundColor: "white",
  color: "black",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
};

const validationSchema = yup.object({
  id: yup.string().required("Mandatory"),
  email: yup.string().required("Mandatory"),
  admin: yup.boolean().required("Mandatory"),
  appointments: yup.boolean().required("Mandatory"),
  coupon_edit_btn: yup.boolean().required("Mandatory"),
  coupons: yup.boolean().required("Mandatory"),
  order_cancel_btn: yup.boolean().required("Mandatory"),
  order_measurement_btn: yup.boolean().required("Mandatory"),
  order_edit_btn: yup.boolean().required("Mandatory"),
  order_delivery_date_btn: yup.boolean().required("Mandatory"),
  orders: yup.boolean().required("Mandatory"),
  payment_btn: yup.boolean().required("Mandatory"),
  product_edit_btn: yup.boolean().required("Mandatory"),
  products: yup.boolean().required("Mandatory"),
  user_edit_btn: yup.boolean().required("Mandatory"),
  users: yup.boolean().required("Mandatory"),
  access: yup.boolean().required("Mandatory"),
  orders_download: yup.boolean().required("Mandatory"),
  messages: yup.boolean().required("Mandatory"),
});

const columns = [
  { id: "id", label: "ID", minWidth: 300 },
  { id: "email", label: "EMAIL", minWidth: 300 },
  { id: "admin", label: "ADMIIN", minWidth: 100 },
  {
    id: "appointments",
    label: "APPOINTMENTS",
    minWidth: 170,
  },
  { id: "coupon_edit_btn", label: "COUPON EDIT BUTTON", minWidth: 100 },
  { id: "coupons", label: "COUPONS", minWidth: 100 },
  { id: "order_cancel_btn", label: "ORDER CANCEL BUTTON", minWidth: 100 },
  {
    id: "order_measurement_btn",
    label: "ORDER MEASUREMENT BUTTON",
    minWidth: 100,
  },
  {
    id: "order_edit_btn",
    label: "ORDER EDIT BUTTON",
    minWidth: 100,
  },
  {
    id: "order_delivery_date_btn",
    label: "ORDER CHANGE DELIVERY DATE",
    minWidth: 100,
  },
  { id: "orders", label: "ORDERS", minWidth: 100 },
  { id: "payment_btn", label: "PAYMENT BUTTON", minWidth: 100 },
  { id: "product_edit_btn", label: "PRODUCT EDIT BUTTON", minWidth: 100 },
  { id: "products", label: "PRODUCTS", minWidth: 100 },
  { id: "users", label: "USERS", minWidth: 100 },
  { id: "user_edit_btn", label: "USER EDIT BUTTON", minWidth: 100 },
  { id: "access", label: "ACCESS", minWidth: 100 },
  { id: "orders_download", label: "ORDERS DOWNLOAD", minWidth: 100 },
  { id: "messages", label: "MESSAGES", minWidth: 100 },
  { id: "action", label: "", minWidth: 150 },
];
/*Constant Variables End*/

const Access = () => {
  /*Standard Variables Start*/
  const {
    currentUser,
    access: { access },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { AlertControl, setAlertMessage, setAlertSeverity, setShowAlert } =
    MessageAlert();
  const {
    ConfirmationDialogControl,
    setShowConfirmationDialog,
    setConfirmationDialogMessage,
    setAcceptButtonText,
    setRejectButtonText,
  } = ConfirmationDialog();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [rows, setRows] = useState([]);
  const [accessDialog, setAccessDialog] = useState(false);
  const [searched, setSearched] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({
    id: "",
    email: "",
    admin: false,
    appointments: false,
    coupon_edit_btn: false,
    coupons: false,
    order_cancel_btn: false,
    order_measurement_btn: false,
    order_edit_btn: false,
    order_delivery_date_btn: false,
    orders: false,
    payment_btn: false,
    product_edit_btn: false,
    products: false,
    user_edit_btn: false,
    users: false,
    access: false,
    orders_download: false,
    messages: false,
  });
  /*Local Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    if (access) {
      setRows(access);
    }
  }, [access]);

  useEffect(() => {
    if (currentUser?.currentUser?.access?.access) {
      dispatch(fetchAccess({}));
    }
  }, [currentUser?.currentUser?.access?.access]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  const onAfterConfirmationDialogClose = () => {
    setInitialFormValues({
      id: "",
      email: "",
      admin: false,
      appointments: false,
      coupon_edit_btn: false,
      coupons: false,
      order_cancel_btn: false,
      order_measurement_btn: false,
      order_edit_btn: false,
      order_delivery_date_btn: false,
      orders: false,
      payment_btn: false,
      product_edit_btn: false,
      products: false,
      user_edit_btn: false,
      users: false,
      access: false,
      orders_download: false,
      messages: false,
    });
  };

  const handleAccessDialogClose = () => {
    setAccessDialog(false);
    setInitialFormValues({
      id: "",
      email: "",
      admin: false,
      appointments: false,
      coupon_edit_btn: false,
      coupons: false,
      order_cancel_btn: false,
      order_measurement_btn: false,
      order_edit_btn: false,
      order_delivery_date_btn: false,
      orders: false,
      payment_btn: false,
      product_edit_btn: false,
      products: false,
      user_edit_btn: false,
      users: false,
      access: false,
      orders_download: false,
      messages: false,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = access.filter((row) => {
      return row.id.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const checkIdExists = async (id, email) => {
    const docRef = doc(db, "admins", id);
    const user = await getDoc(docRef);
    if (user.exists()) {
      return "Id";
    }

    const ordersRef = collection(db, "admins");
    const q = query(ordersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    let bFound = "";
    querySnapshot.forEach(() => {
      bFound = "email";
    });
    return bFound;
  };

  const handleSubmitForm = async (values) => {
    if (currentUser.currentUser) {
      dispatch(setUserLoading(true));

      if (editMode) {
        const docRef = doc(db, "admins", values.id);
        updateDoc(docRef, {
          last_modified: new Date().toString(),
          email: values.email,
          admin: values.admin,
          appointments:
            typeof values.appointments === "boolean"
              ? values.appointments
              : values.appointments === "true",
          coupon_edit_btn:
            typeof values.coupon_edit_btn === "boolean"
              ? values.coupon_edit_btn
              : values.coupon_edit_btn === "true",
          coupons:
            typeof values.coupons === "boolean"
              ? values.coupons
              : values.coupons === "true",
          order_cancel_btn:
            typeof values.order_cancel_btn === "boolean"
              ? values.order_cancel_btn
              : values.order_cancel_btn === "true",
          order_measurement_btn:
            typeof values.order_measurement_btn === "boolean"
              ? values.order_measurement_btn
              : values.order_measurement_btn === "true",
          order_edit_btn:
            typeof values.order_edit_btn === "boolean"
              ? values.order_edit_btn
              : values.order_edit_btn === "true",
          order_delivery_date_btn:
            typeof values.order_delivery_date_btn === "boolean"
              ? values.order_delivery_date_btn
              : values.order_delivery_date_btn === "true",
          orders:
            typeof values.orders === "boolean"
              ? values.orders
              : values.orders === "true",
          payment_btn:
            typeof values.payment_btn === "boolean"
              ? values.payment_btn
              : values.payment_btn === "true",
          product_edit_btn:
            typeof values.product_edit_btn === "boolean"
              ? values.product_edit_btn
              : values.product_edit_btn === "true",
          products:
            typeof values.products === "boolean"
              ? values.products
              : values.products === "true",
          user_edit_btn:
            typeof values.user_edit_btn === "boolean"
              ? values.user_edit_btn
              : values.user_edit_btn === "true",
          users:
            typeof values.users === "boolean"
              ? values.users
              : values.users === "true",
          access:
            typeof values.access === "boolean"
              ? values.access
              : values.access === "true",
          orders_download:
            typeof values.orders_download === "boolean"
              ? values.orders_download
              : values.orders_download === "true",
          messages:
            typeof values.messages === "boolean"
              ? values.messages
              : values.messages === "true",
        })
          .then((result) => {
            setAlertMessage("Admin details updated successfully");
            setAlertSeverity("success");
            setShowAlert(true);
            handleAccessDialogClose();
            dispatch(fetchAccess({}));
            dispatch(setUserLoading(false));
          })
          .catch((error) => {
            setAlertMessage(
              "Error while updaing admin details. Please try again later."
            );
            setAlertSeverity("error");
            setShowAlert(true);
            dispatch(setUserLoading(false));
          });
      } else {
        const codeExist = await checkIdExists(values.id, values.email);

        if (codeExist) {
          setAlertMessage(`Admin ${codeExist} already exist`);
          setAlertSeverity("error");
          setShowAlert(true);
          dispatch(setUserLoading(false));
          return;
        }
        setDoc(doc(db, "admins", values.id), {
          last_modified: new Date().toString(),
          email: values.email,
          admin: values.admin,
          appointments:
            typeof values.appointments === "boolean"
              ? values.appointments
              : values.appointments === "true",
          coupon_edit_btn:
            typeof values.coupon_edit_btn === "boolean"
              ? values.coupon_edit_btn
              : values.coupon_edit_btn === "true",
          coupons:
            typeof values.coupons === "boolean"
              ? values.coupons
              : values.coupons === "true",
          order_cancel_btn:
            typeof values.order_cancel_btn === "boolean"
              ? values.order_cancel_btn
              : values.order_cancel_btn === "true",
          order_measurement_btn:
            typeof values.order_measurement_btn === "boolean"
              ? values.order_measurement_btn
              : values.order_measurement_btn === "true",
          order_edit_btn:
            typeof values.order_edit_btn === "boolean"
              ? values.order_edit_btn
              : values.order_edit_btn === "true",
          order_delivery_date_btn:
            typeof values.order_delivery_date_btn === "boolean"
              ? values.order_delivery_date_btn
              : values.order_delivery_date_btn === "true",
          orders:
            typeof values.orders === "boolean"
              ? values.orders
              : values.orders === "true",
          payment_btn:
            typeof values.payment_btn === "boolean"
              ? values.payment_btn
              : values.payment_btn === "true",
          product_edit_btn:
            typeof values.product_edit_btn === "boolean"
              ? values.product_edit_btn
              : values.product_edit_btn === "true",
          products:
            typeof values.products === "boolean"
              ? values.products
              : values.products === "true",
          user_edit_btn:
            typeof values.user_edit_btn === "boolean"
              ? values.user_edit_btn
              : values.user_edit_btn === "true",
          users:
            typeof values.users === "boolean"
              ? values.users
              : values.users === "true",
          access:
            typeof values.access === "boolean"
              ? values.access
              : values.access === "true",
          orders_download:
            typeof values.orders_download === "boolean"
              ? values.orders_download
              : values.orders_download === "true",
          messages:
            typeof values.messages === "boolean"
              ? values.messages
              : values.messages === "true",
        })
          .then((result) => {
            setAlertMessage("Admin details added successfully");
            setAlertSeverity("success");
            setShowAlert(true);
            handleAccessDialogClose();
            dispatch(fetchAccess({}));
            dispatch(setUserLoading(false));
          })
          .catch((error) => {
            setAlertMessage(
              "Error while adding admin details. Please try again later."
            );
            setAlertSeverity("error");
            setShowAlert(true);
            dispatch(setUserLoading(false));
          });
      }
    }
  };

  const getUnit = (id, value) => {
    let sColor = "black";
    if (typeof value === "boolean") {
      if (value) {
        sColor = "#2a982a";
      } else {
        sColor = "red";
      }
    }

    return (
      <Typography
        sx={{
          color: sColor,
          fontSize: "18px",
        }}
      >
        {typeof value === "boolean" ? value.toString() : value}
      </Typography>
    );
  };

  const onDeleteAdmin = () => {
    if (currentUser.currentUser && initialFormValues.id) {
      deleteDoc(doc(db, "admins", initialFormValues.id))
        .then(() => {
          setAlertMessage("Admin deleted successfully");
          setAlertSeverity("success");
          setShowAlert(true);
          dispatch(fetchAccess({}));
          dispatch(setUserLoading(false));
        })
        .catch((error) => {
          setAlertMessage(
            "Error while deleting admin details. Please try again later."
          );
          setAlertSeverity("error");
          setShowAlert(true);
          dispatch(setUserLoading(false));
        });
    }
  };
  /*Functions End*/

  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <Button
        sx={{ marginBottom: "20px", ...stepperButtonStyles }}
        onClick={() => {
          navigate("/");
        }}
        startIcon={<ArrowBackIcon />}
      >
        BACK
      </Button>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          setSearched={setSearched}
        />
        {currentUser?.currentUser?.access?.access &&
          currentUser?.currentUser?.access?.access && (
            <Button
              sx={{
                width: "10%",
                marginBottom: "20px",
                marginLeft: "20px",
                ...stepperButtonStyles,
              }}
              onClick={() => {
                setAccessDialog(true);
                setEditMode(false);
              }}
            >
              ADD ADMIN
            </Button>
          )}
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <>
                              {column.id === "action" &&
                              currentUser?.currentUser?.access?.access &&
                              !row.super_admin ? (
                                <TableCell key={column.id} align={column.align}>
                                  <EditIcon
                                    sx={{ color: "#000000", cursor: "pointer" }}
                                    onClick={() => {
                                      setAccessDialog(true);
                                      setInitialFormValues(row);
                                      setEditMode(true);
                                    }}
                                  />
                                  <DeleteIcon
                                    sx={{
                                      marginLeft: "30px",
                                      color: "#000000",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setShowConfirmationDialog(true);
                                      setConfirmationDialogMessage(
                                        `Are you sure you want to delete admin '${row.email}'?`
                                      );
                                      setAcceptButtonText("DELETE");
                                      setRejectButtonText("CANCEL");
                                      setInitialFormValues(row);
                                    }}
                                  />
                                </TableCell>
                              ) : (
                                <TableCell
                                  style={{
                                    fontSize: "18px",
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  {getUnit(column.id, value)}
                                </TableCell>
                              )}
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={access ? access.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog fullScreen open={accessDialog} onClose={handleAccessDialogClose}>
        <DialogTitle>
          {editMode ? `${initialFormValues.email}'s ACCESS` : `NEW ADMIN`}
        </DialogTitle>
        <DialogContent style={{ paddingTop: "10px" }}>
          <DialogContentText>
            <Formik
              initialValues={initialFormValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {({ values, validateForm, setTouched }) => {
                return (
                  <Form
                    id="userForm"
                    noValidate
                    onSubmit={(e) => {
                      e.preventDefault();
                      validateForm(values).then((error) => {
                        if (Object.keys(error).length > 0) {
                          // show all errors
                          setTouched(setNestedObjectValues(error, true));
                        } else {
                          handleSubmitForm(values);
                        }
                      });
                    }}
                  >
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      justifyContent="start"
                      sx={{ gap: { xs: "10px 25px", xl: "15px 87px" } }}
                    >
                      {!editMode && (
                        <>
                          <Field
                            className="formLabel"
                            component={TextField}
                            name="id"
                            id="id"
                            label="ID"
                            sx={{
                              width: "225px",
                            }}
                          />
                          <Field
                            className="formLabel"
                            component={TextField}
                            name="email"
                            id="email"
                            label="EMAIL"
                            sx={{
                              width: "225px",
                            }}
                          />
                        </>
                      )}
                      <Field
                        className="formLabel"
                        component={Select}
                        name="admin"
                        id="admin"
                        label="ADMIN"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>
                      <Field
                        className="formLabel"
                        component={Select}
                        name="appointments"
                        id="appointments"
                        label="APPOINTMENTS"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>
                      <Field
                        className="formLabel"
                        component={Select}
                        name="coupon_edit_btn"
                        id="coupon_edit_btn"
                        label="COUPON EDIT BUTTON"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>
                      <Field
                        className="formLabel"
                        component={Select}
                        name="coupons"
                        id="coupons"
                        label="COUPONS"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>
                      <Field
                        className="formLabel"
                        component={Select}
                        name="order_cancel_btn"
                        id="order_cancel_btn"
                        label="ORDER CANCEL BUTTON"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>
                      <Field
                        className="formLabel"
                        component={Select}
                        name="order_measurement_btn"
                        id="order_measurement_btn"
                        label="ORDER MEASUREMENT BUTTON"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>
                      <Field
                        className="formLabel"
                        component={Select}
                        name="order_edit_btn"
                        id="order_edit_btn"
                        label="ORDER EDIT BUTTON"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>
                      <Field
                        className="formLabel"
                        component={Select}
                        name="order_delivery_date_btn"
                        id="order_delivery_date_btn"
                        label="ORDER CHANGE DELIVERY DATE"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>
                      <Field
                        className="formLabel"
                        component={Select}
                        name="orders_download"
                        id="orders_download"
                        label="ORDERS DOWNLOAD"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>
                      <Field
                        className="formLabel"
                        component={Select}
                        name="orders"
                        id="orders"
                        label="ORDERS"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>
                      <Field
                        className="formLabel"
                        component={Select}
                        name="payment_btn"
                        id="payment_btn"
                        label="PAYMENT BUTTON"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>

                      <Field
                        className="formLabel"
                        component={Select}
                        name="product_edit_btn"
                        id="product_edit_btn"
                        label="PRODUCT EDIT BUTTON"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>
                      <Field
                        className="formLabel"
                        component={Select}
                        name="products"
                        id="products"
                        label="PRODUCTS"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>
                      <Field
                        className="formLabel"
                        component={Select}
                        name="user_edit_btn"
                        id="user_edit_btn"
                        label="USER EDIT BUTTON"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>
                      <Field
                        className="formLabel"
                        component={Select}
                        name="users"
                        id="users"
                        label="USERS"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>
                      <Field
                        className="formLabel"
                        component={Select}
                        name="messages"
                        id="messages"
                        label="MESSAGES"
                        sx={{
                          width: "225px",
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Field>

                      {currentUser?.currentUser?.access?.super_admin && (
                        <Field
                          className="formLabel"
                          component={Select}
                          name="access"
                          id="access"
                          label="ACCESS"
                          sx={{
                            width: "225px",
                          }}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </Field>
                      )}
                    </Stack>
                    <Button
                      type="submit"
                      style={{
                        marginTop: "20px",
                        ...stepperButtonStyles,
                      }}
                    >
                      SAVE
                    </Button>
                    <Button
                      style={{
                        marginLeft: "20px",
                        marginTop: "20px",
                        ...stepperButtonStyles2,
                      }}
                      onClick={handleAccessDialogClose}
                    >
                      CANCEL
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <ConfirmationDialogControl
        onAcceptButtonPress={onDeleteAdmin}
        onAfterClose={onAfterConfirmationDialogClose}
      />
      <AlertControl />
    </Box>
  );
};

export default Access;
