/*Standard Library Start*/
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Box } from "@mui/material";
/*Standard Library End*/

/*Local Library Start*/
import {
  Dashboard,
  AppWrapper,
  Loading,
  Subscribe,
  Navbar,
} from "./components";
import {
  Auth,
  Orders,
  OrderDetail,
  OrderStitchDetail,
  OrderMeasurement,
  CreateNewOrder,
  CreateStitchOrder,
  Products,
  ProductsOption,
  ProductDetail,
  AddShirt,
  AddPant,
  Users,
  Appointments,
  Coupons,
  Access,
  AppointmentDetail,
  UserDetail,
  WhatsappMessages
} from "./pages";
/*Local Library End*/

function App() {
  return (
    <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
      <BrowserRouter>
        <Loading />
        <Subscribe />
        <Navbar />
        <AppWrapper>
          <Routes>
            <Route path="/login" element={<Auth />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/order-detail" element={<OrderDetail />} />
            <Route path="/order-stitch-detail" element={<OrderStitchDetail />} />
            <Route path="/order-measurment" element={<OrderMeasurement />} />
            <Route path="/create-new-order" element={<CreateNewOrder />} />
            <Route path="/create-stitch-order" element={<CreateStitchOrder />} />
            <Route path="/edit-order" element={<CreateNewOrder />} />
            <Route path="/edit-stitch-order" element={<CreateStitchOrder />} />
            <Route path="/products" element={<ProductsOption />} />
            <Route path="/shirts" element={<Products />} />
            <Route path="/pants" element={<Products />} />
            <Route path="/shirt" element={<ProductDetail />} />
            <Route path="/pant" element={<ProductDetail />} />
            <Route path="/add-shirt" element={<AddShirt />} />
            <Route path="/add-pant" element={<AddPant />} />
            <Route path="/users" element={<Users />} />
            <Route path="/user-detail" element={<UserDetail />} />
            <Route path="/appointments" element={<Appointments />} />
            <Route path="/coupons" element={<Coupons />} />
            <Route path="/messages" element={<WhatsappMessages />} />
            <Route path="/access" element={<Access />} />
            <Route path="/appointment-detail" element={<AppointmentDetail />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
        </AppWrapper>
      </BrowserRouter>
    </Box>
  );
}

export default App;
