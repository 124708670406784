/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { doc, onSnapshot } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
/*Standard Library End*/

/*Local Library Start*/
import { useAppDispatch } from "../../app/store";
import { auth as firebaseAuth, db } from "../../firebase";
import { setUser, setUserLoading } from "../../features/user/userSlice";
import {
  setCurrentUser,
  setCurrentUserLoading,
} from "../../features/currentUser/currentUserSlice";
/*Local Library End*/

const Subscribe = () => {
  /*Standard Variables Start*/
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useSelector((state) => state.currentUser.currentUser);
  /*Standard Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    firebaseAuth.onAuthStateChanged((current) => {
      dispatch(setCurrentUserLoading(true));
      if (current?.email) {
        onSnapshot(doc(db, "admins", current.uid), (doc) => {
          if (!doc.data() && firebaseAuth.currentUser) {
            alert("You do not have authorization to view this content!");
            firebaseAuth.signOut();
            dispatch(setCurrentUser(null));
          } else {
            const currentUserData = {
              uid: current.uid,
              displayName: current.displayName,
              email: current.email,
              access: doc.data(),
            };
            dispatch(setCurrentUser(currentUserData));
            if (location.pathname === "/login") {
              navigate({ pathname: "/" });
            }
          }
        });
      } else {
        dispatch(setCurrentUser(null));
        navigate({ pathname: "/login" });
      }
      dispatch(setCurrentUserLoading(false));
    });
  }, []);

  useEffect(() => {
    if (currentUser) {
      onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        dispatch(setUserLoading(true));
        let user = doc.data();
        dispatch(setUser(user));
        dispatch(setUserLoading(false));
      });
    } else {
      dispatch(setUserLoading(true));
      dispatch(setUser(null));
      dispatch(setUserLoading(false));
    }
  }, [currentUser]);
  /*OnLoad Functions End*/

  return null;
};

export default Subscribe;
