/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

/*Standard Library Start*/
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import * as yup from "yup";
import { TextField } from "formik-mui";
import { useSelector } from "react-redux";
import { doc, updateDoc, setDoc } from "firebase/firestore";
import {
  Button,
  InputLabel,
  Stack,
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { Field, Form, Formik, setNestedObjectValues } from "formik";
/*Standard Library End*/

/*Local Library Start*/
import theme from "../../theme";
import { useAppDispatch } from "../../app/store";
import { fetchProduct } from "../../features/product/productSlice";
import { fetchProductsByCategories } from "../../features/products/productsSlice";
import colors from "../../constants/pantcolors.json";
import patterns from "../../constants/pantpatterns.json";
import { MessageDialog } from "../../components";
import { db } from "../../firebase";
import suiting from "../../constants/suiting.json";
/*Local Library End*/

/*Constant Variables Start*/
const inputContainerStyles = {
  height: "100px",
};

const inputLabelStyles = {
  color: "#000000",
  cursor: "pointer",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  marginBottom: "12px",
};

const linenValidationSchema = yup.object({
  product_code: yup.string().required("Mandatory"),
  product_name: yup.string().required("Mandatory"),
  stock_quantity: yup.number().required().required("Mandatory"),
  trouser_price: yup
    .number()
    .required()
    .positive()
    .integer()
    .required("Mandatory"),
});

const validationSchema = yup.object({
  product_code: yup.string().required("Mandatory"),
  product_name: yup.string().required("Mandatory"),
  stock_quantity: yup.number().required().required("Mandatory"),
  trouser_price: yup
    .number()
    .required()
    .positive()
    .integer()
    .required("Mandatory"),
  blazer_price: yup
    .number()
    .required()
    .positive()
    .integer()
    .required("Mandatory"),
  two_piece_suit_price: yup
    .number()
    .required()
    .positive()
    .integer()
    .required("Mandatory"),
  three_piece_suit_price: yup
    .number()
    .required()
    .positive()
    .integer()
    .required("Mandatory"),
});
/*Constant Variables End*/

const AddPant = () => {
  /*Standard Variables Start*/
  const {
    currentUser: { currentUser },
    user: { user },
    products: { productsByCategory },
  } = useSelector((state) => state);
  const {
    MessageDialogControl,
    setShowMessageDialog,
    setMessageForMessageDialog,
    setMessageDialogTitle,
    messageDialogTitle,
  } = MessageDialog();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  /*Standard Variables End*/

  /*Local Variables Start*/
  const [productId, setProductId] = useState("");
  const [initialFormValues, setInitialFormValues] = useState({
    product_code: "",
    product_name: "",
    product_description: "",
    material_care: "",
    stock_quantity: "",
    trouser_price: "",
    blazer_price: "",
    two_piece_suit_price: "",
    three_piece_suit_price: "",
    collection: "Classic",
    fabric_image_url: "",
    card_image_url: "",
    image_url: "",
    color: "black",
    pattern: "solid",
  });
  /*Standard Variables End*/

  /*OnLoad Functions Start*/
  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      dispatch(
        fetchProduct({
          category: "pants",
          productId: id,
        })
      ).then((aProducts) => {
        const oNewValue = cloneDeep(aProducts.payload);
        oNewValue.stock_quantity = parseFloat(oNewValue.stock_quantity).toFixed(
          2
        );
        oNewValue.price.forEach((oSuiting) => {
          if (
            oSuiting.category === "trouser" ||
            oSuiting.category === "waist_coat"
          ) {
            oNewValue.trouser_price = oSuiting.price;
          } else if (oSuiting.category === "blazer") {
            oNewValue.blazer_price = oSuiting.price;
          } else if (oSuiting.category === "2_piece_suit") {
            oNewValue.two_piece_suit_price = oSuiting.price;
          } else if (oSuiting.category === "3_piece_suit") {
            oNewValue.three_piece_suit_price = oSuiting.price;
          }
        });
        setInitialFormValues(oNewValue);
        setProductId(id);
      });
    }
  }, [searchParams.get("id")]);

  useEffect(() => {
    if (user) {
      dispatch(
        fetchProductsByCategories({
          category: "pants",
        })
      );
    }
  }, [user]);
  /*OnLoad Functions End*/

  /*Functions Start*/
  const onAfterMessageDialogClose = () => {
    if (messageDialogTitle === "Success" && productId) {
      navigate({
        pathname: `/pant`,
        search: `?id=${productId}`,
      });
    }
  };

  const handleSubmitForm = async (product) => {
    if (currentUser) {
      let aPrice = [];

      suiting.forEach((oSuiting) => {
        if (
          oSuiting.category === "trouser" ||
          oSuiting.category === "waist_coat"
        ) {
          aPrice.push({
            ...oSuiting,
            price: product.trouser_price,
          });
        }
        if (product.collection === "Linen") {
          return;
        }
        if (oSuiting.category === "blazer") {
          aPrice.push({
            ...oSuiting,
            price: product.blazer_price,
          });
        } else if (oSuiting.category === "2_piece_suit") {
          aPrice.push({
            ...oSuiting,
            price: product.two_piece_suit_price,
          });
        } else if (oSuiting.category === "3_piece_suit") {
          aPrice.push({
            ...oSuiting,
            price: product.three_piece_suit_price,
          });
        }
      });

      const oProduct = {
        last_modfied: new Date().toString(),
        product_code: product.product_code,
        product_name: product.product_name,
        product_description: product.product_description,
        material_care: product.material_care,
        color: product.color,
        pattern: product.pattern,
        price: aPrice,
        stock_quantity: parseFloat(product.stock_quantity).toFixed(2),
        fabric_image_url: product.fabric_image_url,
        card_image_url: product.card_image_url,
        // card_image_url: `https://firebasestorage.googleapis.com/v0/b/keimple-india.appspot.com/o/shirts%2F${product.product_code}%2FCard.webp?alt=media`,
        // card1_image_url: `https://firebasestorage.googleapis.com/v0/b/keimple-india.appspot.com/o/shirts%2F${product.product_code}%2FCard1.webp?alt=media`,
        // card2_image_url: `https://firebasestorage.googleapis.com/v0/b/keimple-india.appspot.com/o/shirts%2F${product.product_code}%2FCard2.webp?alt=media`,
        // card3_image_url: `https://firebasestorage.googleapis.com/v0/b/keimple-india.appspot.com/o/shirts%2F${product.product_code}%2FCard3.webp?alt=media`,
        collection: product.collection,
      };
      if (productId === "") {
        let bFound = false;
        if (productsByCategory?.length > 0) {
          productsByCategory.some((oProduct) => {
            if (
              oProduct.product_code.toString() ===
              product.product_code.toString()
            ) {
              setMessageForMessageDialog(
                `Pant code already exist: ${product.product_code}`
              );
              setMessageDialogTitle("Error");
              setShowMessageDialog(true);
              bFound = true;
              return false;
            } else if (
              oProduct.product_name.toLowerCase() ===
              product.product_name.toLowerCase()
            ) {
              setMessageForMessageDialog(
                `Pant name already exist: ${product.product_name}`
              );
              setMessageDialogTitle("Error");
              setShowMessageDialog(true);
              bFound = true;
              return false;
            }
            return false;
          });
        }

        if (bFound) {
          return;
        }

        const productRef = doc(db, "pants", product.product_code);
        setDoc(productRef, oProduct)
          .then(() => {
            setMessageForMessageDialog("Suiting added successfully!");
            setMessageDialogTitle("Success");
            setShowMessageDialog(true);
            setProductId(product.product_code.toString());
          })
          .catch((error) => {
            setMessageForMessageDialog(`Something bad happened: ${error}`);
            setMessageDialogTitle("Error");
            setShowMessageDialog(true);
          });
      } else {
        const productRef = doc(db, "pants", productId);
        updateDoc(productRef, oProduct)
          .then((result) => {
            setMessageForMessageDialog("Suiting updated successfully!");
            setMessageDialogTitle("Success");
            setShowMessageDialog(true);
          })
          .catch((error) => {
            setMessageForMessageDialog(`Something bad happened: ${error}`);
            setMessageDialogTitle("Error");
            setShowMessageDialog(true);
          });
      }
    }
  };
  /*Functions End*/

  return (
    <>
      <Stack
        sx={{
          marginTop: "150px",
          marginBottom: "50px",
          paddingLeft: {
            xs: theme.padding?.pagePaddingXS + "px",
            lg: theme.padding?.pagePaddingLG + "px",
            xl: theme.padding?.pagePaddingXL + "px",
          },
          paddingRight: {
            xs: theme.padding?.pagePaddingXS + "px",
            lg: theme.padding?.pagePaddingLG + "px",
            xl: theme.padding?.pagePaddingXL + "px",
          },
        }}
        rowGap="30px"
      >
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="flex-start"
          rowGap="60px"
          sx={{
            justifyContent: { xs: "center", lg1300: "center" },
            columnGap: { xs: "10px", lg: "25px", xl: "54px" },
          }}
        >
          <Formik
            initialValues={initialFormValues}
            onSubmit={() => {}}
            validationSchema={
              initialFormValues.collection === "Linen"
                ? linenValidationSchema
                : validationSchema
            }
            enableReinitialize={true}
          >
            {({ values, validateForm, setTouched }) => {
              return (
                <Form
                  id="PantForm"
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    validateForm(values).then((error) => {
                      if (Object.keys(error).length > 0) {
                        setTouched(setNestedObjectValues(error, true));
                      } else {
                        handleSubmitForm(values);
                      }
                    });
                  }}
                >
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="start"
                    sx={{ gap: { xs: "10px 25px", xl: "15px 87px" } }}
                  >
                    {/* FIRST NAME */}
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="product_code"
                        sx={{ ...inputLabelStyles }}
                      >
                        PRODUCT CODE
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="product_code"
                        id="product_code"
                        disabled={productId !== ""}
                      />
                    </Box>
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="product_name"
                        sx={{ ...inputLabelStyles }}
                      >
                        PRODUCT NAME
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="product_name"
                        id="product_name"
                        disabled={false}
                      />
                    </Box>

                    {/* LAST NAME */}
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="product_description"
                        sx={{ ...inputLabelStyles }}
                      >
                        PRODUCT DESCRIPTION
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="product_description"
                        id="product_description"
                        disabled={false}
                      />
                    </Box>

                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="material_care"
                        sx={{ ...inputLabelStyles }}
                      >
                        MATERIAL & CARE
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="material_care"
                        id="material_care"
                        disabled={false}
                      />
                    </Box>

                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel htmlFor="price" sx={{ ...inputLabelStyles }}>
                        COLLECTION
                      </InputLabel>
                      <FormControl>
                        <Select
                          style={{ width: "215px" }}
                          value={initialFormValues.collection}
                          onChange={(event) => {
                            setInitialFormValues({
                              ...values,
                              collection: event.target.value,
                            });
                          }}
                        >
                          <MenuItem value="Classic">Classic</MenuItem>
                          <MenuItem value="Bold">Bold</MenuItem>
                          <MenuItem value="Linen">Linen</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel htmlFor="color" sx={{ ...inputLabelStyles }}>
                        COLOUR
                      </InputLabel>
                      <FormControl>
                        <Select
                          multiple
                          style={{ width: "215px" }}
                          renderValue={(selected) => {
                            let aColor = [];
                            selected.map((selectedColor) => {
                              colors.some((sColor) => {
                                if (selectedColor === sColor.id) {
                                  aColor.push(sColor.label);
                                  return true;
                                }
                                return false;
                              });
                            });
                            return aColor.join(", ");
                          }}
                          value={initialFormValues.color.split(",")}
                          onChange={(event) => {
                            setInitialFormValues({
                              ...values,
                              color: event.target.value.join(","),
                            });
                          }}
                        >
                          {colors &&
                            colors.map((oColor) => {
                              return (
                                <MenuItem key={oColor.id} value={oColor.id}>
                                  <Checkbox
                                    checked={
                                      initialFormValues.color
                                        .split(",")
                                        .indexOf(oColor.id) > -1
                                    }
                                  />
                                  <ListItemText primary={oColor.label} />
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="pattern"
                        sx={{ ...inputLabelStyles }}
                      >
                        PATTERN
                      </InputLabel>
                      <FormControl>
                        <Select
                          style={{ width: "215px" }}
                          value={initialFormValues.pattern}
                          onChange={(event) => {
                            setInitialFormValues({
                              ...values,
                              pattern: event.target.value,
                            });
                          }}
                        >
                          {patterns &&
                            patterns.map((oColor) => {
                              return (
                                <MenuItem key={oColor.id} value={oColor.id}>
                                  {oColor.label}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>

                    {/*  Email */}
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="stock_quantity"
                        sx={{ ...inputLabelStyles }}
                      >
                        METERS
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="number"
                        name="stock_quantity"
                        id="stock_quantity"
                        disabled={false}
                      />
                    </Box>
                    <Box
                      sx={{
                        ...inputContainerStyles,
                        display: "block",
                      }}
                    >
                      <InputLabel htmlFor="price" sx={{ ...inputLabelStyles }}>
                        TROUSER/WAIST COAT PRICE
                      </InputLabel>
                      <FormControl>
                        <Field
                          component={TextField}
                          type="number"
                          name="trouser_price"
                          id="trouser_price"
                        />
                      </FormControl>
                    </Box>
                    {initialFormValues.collection !== "Linen" && (
                      <>
                        <Box
                          sx={{
                            ...inputContainerStyles,
                            display: "block",
                          }}
                        >
                          <InputLabel
                            htmlFor="price"
                            sx={{ ...inputLabelStyles }}
                          >
                            BLAZER PRICE
                          </InputLabel>
                          <FormControl>
                            <Field
                              component={TextField}
                              type="number"
                              name="blazer_price"
                              id="blazer_price"
                            />
                          </FormControl>
                        </Box>
                        <Box
                          sx={{
                            ...inputContainerStyles,
                            display: "block",
                          }}
                        >
                          <InputLabel
                            htmlFor="price"
                            sx={{ ...inputLabelStyles }}
                          >
                            2-PEICE SUIT PRICE
                          </InputLabel>
                          <FormControl>
                            <Field
                              component={TextField}
                              type="number"
                              name="two_piece_suit_price"
                              id="two_piece_suit_price"
                            />
                          </FormControl>
                        </Box>
                        <Box
                          sx={{
                            ...inputContainerStyles,
                            display: "block",
                          }}
                        >
                          <InputLabel
                            htmlFor="price"
                            sx={{ ...inputLabelStyles }}
                          >
                            3-PEICE SUIT PRICE
                          </InputLabel>
                          <FormControl>
                            <Field
                              component={TextField}
                              type="number"
                              name="three_piece_suit_price"
                              id="three_piece_suit_price"
                            />
                          </FormControl>
                        </Box>
                      </>
                    )}
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Stack>

        <Stack
          direction="row"
          width="max-content"
          maxWidth="100%"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          rowGap="20px"
          sx={{ columnGap: { xs: "10px", lg: "26px" } }}
        >
          {currentUser?.access?.product_edit_btn && (
            <Button
              type="submit"
              form="PantForm"
              sx={{
                borderRadius: "10px",
                backgroundColor: "black",
                color: "white",
                fontSize: "18px",
                fontWeight: theme.fontWeight.semiBold,
                textAlign: "center",
                textTransform: "none",
                width: "203px",
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
            >
              {productId === "" ? "ADD SUITING" : "UPDATE SUITING"}
            </Button>
          )}
          <Button
            onClick={() => {
              if (productId) {
                navigate({
                  pathname: `/pant`,
                  search: `?id=${productId}`,
                });
              } else {
                navigate({
                  pathname: `/pants`,
                });
              }
            }}
            sx={{
              borderRadius: "10px",
              backgroundColor: "white",
              color: "black",
              fontSize: "18px",
              fontWeight: theme.fontWeight.semiBold,
              textAlign: "center",
              textTransform: "none",
              border: "2px solid black",
              width: "203px",
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            CANCEL
          </Button>
        </Stack>
      </Stack>
      <MessageDialogControl onAfterClose={onAfterMessageDialogClose} />
    </>
  );
};

export default AddPant;
