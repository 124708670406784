/* eslint-disable no-empty-pattern */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import moment from "moment";

const initialState = {
  appointments: null,
  appointment: null,
  loading: false,
};

export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAppointments.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload;
        state.loading = false;
      })
      .addCase(fetchAppointment.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAppointment.fulfilled, (state, action) => {
        state.appointment = action.payload;
        state.loading = false;
      });
  },
});

export const fetchAppointments = createAsyncThunk(
  "appointments/fetchAppointments",
  async ({}, thunkAPI) => {
    const ordersRef = collection(db, "appointments");
    const q = query(ordersRef);
    const querySnapshot = await getDocs(q);

    let userOrders = [];
    querySnapshot.forEach((doc) => {
      let oData = doc.data();
      oData.id = doc.id;
      oData.created_on = moment(oData.created_on).format("LLL");
      oData.name = "";
      if (oData?.user_address?.first_name) {
        oData.name = oData.user_address.first_name;
      }
      if (oData?.user_address?.last_name) {
        oData.name = oData.name + " " + oData.user_address.last_name;
      }

      oData.mobile = oData.mobile.substring(3);

      userOrders.push(oData);
    });

    return userOrders.sort(function (a, b) {
      return new Date(b.created_on) - new Date(a.created_on);
    });
  }
);

export const fetchAppointment = createAsyncThunk(
  "appointments/fetchAppointment",
  async ({ id }, thunkAPI) => {
    const userRef = doc(db, "appointments", id);
    const user = await getDoc(userRef);
    return user.data();
  }
);

export default appointmentsSlice.reducer;
