/*Standard Library Start*/
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Container,
  Typography,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
/*Standard Library End*/

/*Local Library Start*/
import { auth } from "../../firebase";
import logo from "../../images/logo.png";
/*Local Library End*/

function Navbar() {
  /*Standard Variables Start*/
  const {
    currentUser: { currentUser },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  /*Standard Variables End*/

  /*Functions Start*/
  const handleNavigation = (category) => {
    navigate({
      pathname: `/${category}`,
    });
  };

  const handleLogout = () => {
    auth.signOut();
    navigate("/login");
  };
  /*Functions End*/

  return (
    <>
      <AppBar
        id="AppBar"
        position="fixed"
        sx={{ boxShadow: "none", backgroundColor: "white" }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
              <Box
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleNavigation("");
                }}
              >
                <img
                  style={{
                    width: "150px",
                    height: "80px",
                    padding: "10px",
                  }}
                  alt=""
                  src={logo}
                ></img>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: "24px",
                marginRight: "20px",
              }}
            >
              <span style={{ fontWeight: "bold", color: "black" }}>
                LIVE SITE
              </span>
            </Typography>
            <Box sx={{ flexGrow: 0 }}>
              {currentUser && (
                <IconButton onClick={handleLogout} sx={{ p: 0 }}>
                  <LogoutIcon />
                </IconButton>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
export default Navbar;
