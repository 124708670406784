import React, { useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import theme from "../../theme";
import { styled } from "@mui/material/styles";

/*Constant Variable Start*/
const stepperButtonStyles = {
  border: "2px solid black",
  borderRadius: "10px",
  backgroundColor: "white",
  color: "black",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
/*Constant Variable End*/

const MessageDialog = () => {
  /*Standard Variable Start*/
  /*Standard Variable End*/

  /*Local Variable Start*/
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [messageDialogTitle, setMessageDialogTitle] = useState("");
  const [messageForMessageDialog, setMessageForMessageDialog] = useState("");
  /*Variables End*/

  /*OnLoad Start*/
  /*OnLoad End*/

  /*Functions Start*/
  const handleMessageDialogClose = () => {
    setShowMessageDialog(false);
  };
  /*Functions End*/

  /*UI Start*/

  const MessageDialogControl = ({ onAfterClose }) => (
    <BootstrapDialog
      onClose={handleMessageDialogClose}
      open={showMessageDialog}
    >
      <DialogTitle
        sx={{
          fontSize: "24px",
        }}
      >
        {messageDialogTitle}
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.fontWeight.bold,
          }}
        >
          {messageForMessageDialog}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            ...stepperButtonStyles,
          }}
          onClick={() => {
            handleMessageDialogClose();
            if (onAfterClose) {
              onAfterClose();
            }
          }}
        >
          CLOSE
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
  /*UI End*/

  return {
    MessageDialogControl,
    setShowMessageDialog,
    setMessageForMessageDialog,
    setMessageDialogTitle,
    messageDialogTitle,
  };
};

export default MessageDialog;
